import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
}

const baseApiUrl = '';
@Injectable({
  providedIn: 'root'
})
export class CommonListServicesService {
  constructor( private http: HttpClient) { 
  }
public getCommonListForYN(){
  const get_url=baseApiUrl+"commonList/generic/commonYN";
  return this.http.get<any>(get_url);
}
  public getCommonListcaseFor(): Observable<any> {
    const get_url = baseApiUrl + 'commonList/caseFor';
    return this.http.get<any>(get_url);
  }  
  public getCommonListcaseType(): Observable<any> {
    const get_url = baseApiUrl + 'commonList/caseType';
    return this.http.get<any>(get_url);
  }
  public getCommonListcaseStatus(): Observable<any> {
    const get_url = baseApiUrl + 'commonList/caseStatus';
    return this.http.get<any>(get_url);
  }
  public getCommonListbucketType(): Observable<any> {
    const get_url = baseApiUrl + 'commonList/generic/bucketType';
    return this.http.get<any>(get_url);
  }

  public getCommonListtypeOfProperty(type): Observable<any> {
    const get_url = baseApiUrl + 'commonList/' + type ;
    return this.http.get<any>(get_url);
  }
  
  public getTeamTypeList(id:any): Observable<any> {
   const get_url = baseApiUrl + 'teams/getTeamTypeByServiceAreaNew/'+id;
    return this.http.get<any>(get_url);
  }

  public allocationType(): Observable<any> {
    const get_url = baseApiUrl + 'commonList/generic/allocationType';
    return this.http.get<any>(get_url);
  }

  public chargableType(): Observable<any> {
    const get_url = baseApiUrl + 'commonList/chargableType';
    return this.http.get<any>(get_url);
  }

  public algorithmType(): Observable<any> {
    const get_url = baseApiUrl + 'commonList/generic/algorithmType';
    return this.http.get<any>(get_url);
  }

  public teamListByManual(data): Observable<any> {
    const post_url = baseApiUrl + 'teams/getTeamsByTeamTypeAndServiceAreaAndGroup';
    return this.http.post<any>(post_url, data, { headers: httpOptions.headers })
 
  }

  public groupListByManual(data): Observable<any> {
    const post_url = baseApiUrl + 'group/getGroupByTeamTypeAndServiceArea';
    return this.http.post<any>(post_url, data, { headers: httpOptions.headers })
 
  }
  


  
  public getServiceAreabyTeamList(): Observable<any> {
    const get_url = baseApiUrl + 'serviceArea/getByTeams';
    return this.http.get<any>(get_url);
  }

  public getStaffUserList(): Observable<any> {
    const get_url = baseApiUrl + 'staffuser/list';
    return this.http.get<any>(get_url);
  }

  public getCommonListcaseOrigin(): Observable<any> {
    const get_url = baseApiUrl + 'commonList/origin';
    return this.http.get<any>(get_url);
  }

  public getCommonListconnTypeFree(): Observable<any> {
    const get_url = baseApiUrl + 'commonList/connTypeFree';
    return this.http.get<any>(get_url);
  }
  public getCommonListpriority(): Observable<any> {
    const get_url = baseApiUrl + 'commonList/priority';
    return this.http.get<any>(get_url);
  }
  public getGenericRemarkType(type): Observable<any> {
    const get_url = baseApiUrl + 'commonList/generic/' + type;
    return this.http.get<any>(get_url);
  }
  public getGenericCommonList(type): Observable<any> {
    const get_url = baseApiUrl + 'commonList/generic/' + type;
    return this.http.get<any>(get_url);
  }
  public getCommonListCommonStatus(): Observable<any> {
    const get_url = baseApiUrl + 'commonList/commonStatus';
    return this.http.get<any>(get_url);
  }
  public getCommonListAddressType(): Observable<any> {
    const get_url = baseApiUrl + 'commonList/addressType';
    return this.http.get<any>(get_url);
  }
  public getCommonListCommissionType(): Observable<any> {
    const get_url = baseApiUrl + 'commonList/partnerCommType';
    return this.http.get<any>(get_url);
  }

  public getCommonListTaxCalculation(): Observable<any> {
    const get_url = baseApiUrl + 'commonList/generic/taxCalculation';
    return this.http.get<any>(get_url);
  }
  public getDepartmentList() : Observable<any>{
    const get_url = baseApiUrl + 'commonList/generic/commentDepartment';
    return this.http.get<any>(get_url);
  }
  public getinquirysourceList() : Observable<any>{
    const get_url = baseApiUrl + 'commonList/generic/commentInquirySource';
    return this.http.get<any>(get_url);
  }
  public getreportForList(): Observable<any>{
    const get_url = baseApiUrl + 'commonList/generic/UpdatesReportType';
    return this.http.get<any>(get_url);
  }
  public getOperationList(){ 
    const get_url = baseApiUrl + 'subscriberUpdate/download/SubscriberOperationConstants';
    return this.http.get<any>(get_url);
  }
  public getOperationleaselineList(){ 
    const get_url = baseApiUrl + 'leaseLineUpdate/download/leaseLineOperationConstants';
    return this.http.get<any>(get_url);
  }
}
