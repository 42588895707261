import { Component, HostListener, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatAccordion, MatTableDataSource } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModalRef, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { AppConfig } from '../../../app.config';
import { TokenStorageService } from '../../../auth/token-storage-service';
import { AbstractBaseAddUpdateComponent } from '../../../components/base/abstract-base-add-update-component';
import { CustomModalService } from '../../../components/custom-modal';
import { SearchControlTypeEnum, SearchDataTypeEnum } from '../../../core/enum/searchType.enum';
import { TableKeyEnum } from '../../../core/enum/table-key.enum';
import { PaginationData } from '../../../core/modal/pagination-data';
import { CommonListServicesService } from '../../../services/common_list_service/common-list-services.service';
import { CommonStatusService } from '../../../services/common_status/common-status.service';
import { IBaseService } from '../../../services/ibase-service';
import { PlanServices } from '../../../services/plan_service/plan_service.service';
import { AclClassConstants } from '../../aclClassConstants';
import { AclConstants } from '../../aclOperationConstants';
import { VasProviderServiceService } from '../../configuration/vas-provider/service/vas-provider.service';
import { DataSharingServiceService } from '../../data-sharing-service.service';
import { NetworkDevicesService } from '../../network-management/network-device/services/network-devices.service';
import { FranchiesService } from '../franchies/franchies.service';
import { NewSusbcriberModel } from './new-subscriber-model';
import { NewSubscriberService } from './new-subscriber.service';
import _ from 'underscore';
import { debug } from 'console';
import { InventoryService } from '../../inventory/inventory/service/inventory.service';
import { IntercomGroup } from '../../../services/intercom_group/intercom_group.service';
import { VoiceServiceType } from '../../../services/voice_service_type/voice_service_type.service';
import { ChargeTypeService } from '../../../services/charge_type/charge_type.service';
import { ChangePlanService } from '../subscriber-details/change-plan/change-plan.service';
import { ApplyChargeService } from '../subscriber-details/apply-charge/apply-charge.service';
import { NetworkDeviceServices } from '../../../services/network_device/network_device.service';
import { IppoolServices } from '../../../services/ippool/ippool.service';
import { AllocationIpService } from '../subscriber-details/allocation-ip/allocation-ip.service';
import { ServiceAreaServices } from '../../../services/service_area/service_area.service';
import * as alertify from 'alertifyjs';

@Component({
  selector: 'app-new-subscriber-add-update',
  templateUrl: './new-subscriber-add-update.component.html',
  styleUrls: ['./new-subscriber-add-update.component.scss']
})
export class NewSubscriberAddUpdateComponent extends AbstractBaseAddUpdateComponent<NewSusbcriberModel> implements OnInit {
  @ViewChild('actionBtn', { static: false }) actionBtnRef: TemplateRef<any>;
  @ViewChild(MatAccordion, { static: true }) accordion: MatAccordion;
  @ViewChild ('associatedVAS',{static:false}) associatedVAS:TemplateRef<any>;
  @ViewChild ('price',{static:false}) priceRef:TemplateRef<any>;

  selectedVoiceStr: string;
  selectedOTTStr: string;
  selectedIPTVStr: string;
  associatedVasDetails = [];
  public entity: NewSusbcriberModel = new NewSusbcriberModel();
  public entity_id: any = null;
  form: FormGroup;
  form2: FormGroup;
  form3: FormGroup;
  form4: FormGroup;
  form5: FormGroup;
  form6: FormGroup;
  PlanDetailForm: FormGroup;
  url: string;
  commonStatus: any;
  commonYN
  loginService
  typeList: any;
  allColumns: any[] = [];
  allVasColumns: any[] = [];
  neoGridSettings: any = {};
  neoGridSettingsForVAS: any = {};
  // For advance search
  searchSettings = [];
  subscriptions: Subscription[] = [];
  tableKeyEnum = TableKeyEnum;
  dataSource = new MatTableDataSource([]);
  dataSourceForVAS = new MatTableDataSource([]);
  public paginationData = new PaginationData();
  private ngbRef: NgbModalRef;
  vasSelectionList: any[] = [];
  AclClassConstants = AclClassConstants;
  AclConstants = AclConstants;
  mobile: any;
  dynamicrequiredArray: any;
  firstname:any;
  middlename:any;
  lastname:any;
  cafno: any;
  email: any;
  backendImageUrl:string;
  public titleList: any[] = [
    {
      text: '',
      value: '',
    },
  ];
  franchiesList: any;
  partnerId: number;
  aadhaar_OTP:number
  client_id :string
  isAadhaarbtn:boolean= true;
  isVerifybtn:boolean = true;
  isImage:boolean=false;
  addressMapArray:any;
  addressMapArray2:any;
  addressMapArray3:any;
  parentpin:any;
  searchPincodeLink: string = '';
  req_message = "This field is required.";
  planTypeList : [];
  public planDetailsList: any[] = [{
    name: '',
    id: ''
  }];
  planGroup:any;
  planDtl:any;
  vasPrice:number = 0;
  presentAddressStateChange: any;
  commoissiontype:any;
  isSellPrice:boolean = false;
  isIncludeTax:boolean=true;
  public planServiceList: any[] = [
    {
      name: '',
      id: '',
    },
  ];
  taxDetailsByPlanData: any;
  taxAmount:any;
  public addPlanList: any[] = [];
  rowData:any;
  rowDataForVAS:any
  default_pincode_value:string;
  aadharverified:boolean = false;
  mobileverified:boolean = false;
  imagePath:string;
  mobileOtp: any = null;
  buildingList: [];
  parentId: number;
  public areaAllList: any[] = [
    {
      name: '',
      id: '',
    },
  ];
  public areaAllList2: any[] = [
    {
      name: '',
      id: '',
    },
  ];
  public areaAllList3: any[] = [
    {
      name: '',
      id: '',
    },
  ];

  entityPayloadData: any = {
    custtype : 'prepaid',
    failcount : 0,
    billday : 0,
    addressList : [],
    planMappingList: [],
    overChargeList:[]
  };
  invalidUsernameAsPerApi: boolean = true;
  invalidUsernameAsPerApiMsg: string = '';
  invalidEmailAsPerApi: boolean = false;
  invalidEmailAsPerApiMsg: string = '';
  invalidCafNoAsPerApi: boolean = false;
  invalidCafNoAsPerApiMsg: string = '';
  invalidMobileAsPerApi: boolean = false;
  invalidMobileAsPerApiMsg: string = '';
  onChange: any = () => { };
  onTouched: any = () => { };
  enablePermanent: boolean = true;
  enablePayment: boolean = true;
  showPermanentAddressSection: boolean = true;
  showPaymentAddressSection: boolean = true;
  isScrollFromTop: boolean;
  saveBtn: boolean = false;
  showVerticalStrepper = false;
  propertyTypeList
  buildingList2:[];
  buildingList3:[];
  
  public voiceServiceTypeList: any[] = [
    {
      text: '',
      value: '',
    },
  ];
  selected_change_voice_service_type: any;
  checkOnlineRenewal: boolean = false;
  shipTrunkDidNoValid: boolean;
  shipTrunkChildDidNoValid: boolean;
  intercomeNoValid: boolean;
  intercomGroupValid: boolean;
  checkSmsNotification: boolean = false;
  checkVOIPServices: boolean = false;
  checkEmailNotification: boolean = false;
  public intercomGroupList: any[] = [
    {
      text: '',
      value: '',
    },
  ];
  public chargeForCustomerList: any[] = [
    {
      text: '',
      id: '',
    },
  ];
  chargeDetails:any;
  maxChargeAmount:any;
  minChargeAmount:any;
  isPriceOverride:boolean=false;
  isPriceOverrideReadOnly:boolean=true;
  isIncludeTaxflag:boolean=false;
  selectedChargetaxid:any;
  finalAmount:any;
  chargeAmount:any;
  selectedchargeTaxId:any;
  overridePriceTaxWithTax:any;
  hideShowField:boolean=false;
  hideLabelFlag:boolean=false;
  isPriceOverrideCharge:boolean=false;
  taxDetailsByChargeData: any;
  public addChargeDetailsList: any[] = [];
  public networkTypeList: any[] = [
    {
      text: '',
      value: '',
    },
  ];
  serviceType:any;
  ConnectionType:any;
  RouterType:any;
  public ippoolList: any[] = [
    {
      poolName: '',
      poolId: '',
    },
  ];
  showIpcontrol:boolean=false;
  openAllocationIpSelectPopup: boolean = false;
  ApicallChange:string="other-details"
  allocationIpSelectedData: any = "";
  public serviceAreaList: any[] = [
    {
      name: '',
      id: '',
    },
  ];
  partnerIdTemp:Number;
  public networkDeviceList: any[] = [
    {
      name: '',
      id: '',
    },
  ];
  snmpEnabled;
  oltSlotListAllData: any;
  public oltSlotList: any[] = [
    {
      name: '',
      id: '',
    },
  ];

  public oltPortList: any[] = [
    {
      name: '',
      id: '',
    },
  ];
  public serviceTypeList :any;
  connectionTypeList:any;
  routerTypeList:any;
  basicDetail : boolean = false;
  addressDetail : boolean = false;
  planDetail : boolean = false;
  additionalService : boolean = false;
  chargeDetail : boolean = false;
  subConNetDetails : boolean = false;
  IPTVDetails: boolean = false;
  boxTypeList = [];
  NEXG_VAS = [];
  VAS_OTT = [];
  VAS_IPTV = [];
  selectedVoice: string;
  selectedOtt: string;
  selectedIptv: string;
  voiceSelected:boolean = false;
  ottSelected:boolean = false;
  iptvSelected:boolean = false;
  selectedVASIds = [];
  selectedVasDetails = [];
  vasprice: number = 0
  isAllowVAS: boolean = false;
  oltobj: any;

  constructor(
    private ngbModalService: NgbModal,
    private networkDevicesService: NetworkDevicesService,
    private vasProviderService: VasProviderServiceService,
    public CommonStatus: CommonStatusService,
    private activeRoute: ActivatedRoute,
    private router: Router,
    public toastrService: ToastrService,
    private newSubscriberService: NewSubscriberService,
    private appConfig: AppConfig,
    private franchiesService: FranchiesService,
    private customModalService: CustomModalService,
    private tokenservice: TokenStorageService,
    private commonlistservice:CommonListServicesService,
    private planService: PlanServices,
    private sharingdataService:DataSharingServiceService,
    private inventoryService:InventoryService,
    private intercomGroup: IntercomGroup,
    private voiceServiceType: VoiceServiceType,
    private chargeTypeService: ChargeTypeService,
    private changePlanService:ChangePlanService,
    private applyChargeService:ApplyChargeService,
    private networkDeviceServices: NetworkDeviceServices,
    private ippoolServices: IppoolServices,
    private ngbmodalservice: NgbModal,
    private allocationIpService: AllocationIpService,
    private serviceAreaServices: ServiceAreaServices,

    ) {
    super(toastrService, router);
    this.getRequiredAllField();
    this.backendImageUrl=this.appConfig.config.serverUrl;
    if(window.innerWidth < 1000){
      this.showVerticalStrepper = true;
    }
  }
  ngOnInit() {
    this.init();
    this.getTableColumn();
    this.getTitle();
    this.getFranchiesList();
    this.getAddressMapping();
    this.getPincode();
    this.getPlanTypeList();
    this.getcommonList();
    this.getIntercomGroup();
    this.getVoiceServiceType();
    this.getChargeForCustomer();
    this.getNetworkTypeList();
    this.getIpPoolList();
    this.getServiceTypeList();
    this.getConnectionTypeList();
    this.getRouterTypeList();
    this.getIPTVTypeList();
    if(this.partnerIdTemp && this.partnerIdTemp != this.partnerId){
      this.serviceAreaList = [];
      this.form.controls.serviceareaid.setValue('');
    }
    if(this.parentId != null){
      this.getServiceAreaList();
    }
    this.showPermanentAddressSection = true;
    this.showPaymentAddressSection = true;  
    this.accordion.openAll()
    let id = this.activeRoute.snapshot.paramMap.get('id');
    if (id) {
      this.isUpdateComponent = true;
      this.getById(Number(id));
    }
    this.CommonStatus.getCommonList().subscribe((res) => {
      this.commonStatus = res.dataList
    })
  }

  getIPTVTypeList(){
    this.commonlistservice.getGenericCommonList('IptvType').subscribe(res=>{
      this.boxTypeList = res.dataList;
    })
  }

  getPlanTypeList(){
    this.commonlistservice.getGenericCommonList('planTypeForNew').subscribe((res)=>{
      this.planTypeList = res.dataList
      .filter((ls) => {
        if (ls.value === 'All Plan' || ls.value === 'Registration') {
          return ls;
        }
      });
    })
  }

  getIntercomGroup() {
    this.intercomGroup.getIntercomGroupList().subscribe((result) => {
      this.intercomGroupList = result.dataList;
    });
  }

  getNetworkTypeList() {
    this.networkDeviceServices.getNetworkTypeList().subscribe((result) => {
      this.networkTypeList = result.dataList;
    });
  }
  
  getVoiceServiceType() {
    this.voiceServiceType.getVoiceServicesList().subscribe((result) => {
      this.voiceServiceTypeList = result.dataList;
    });
  }

  getChargeForCustomer() {
    this.chargeTypeService.getChargeForCustomerList().subscribe((result) => {
      if (result.chargelist) {
        result.chargelist.forEach(function (item) {
          let text = item.name ;
          item.text = text;
        });
        this.chargeForCustomerList = result.chargelist;
      }
    });
  }

  getServiceAreaList(){
    if(this.partnerId){
      this.partnerIdTemp = this.partnerId
      this.serviceAreaServices.getPlanServiceArea(this.partnerId).subscribe((res)=>{
      this.serviceAreaList = res.dataList;
    });
      
    }else{
      this.toastrService.error("Please Select Partner.");
    }
    
  }

  getPincode() {
    this.searchPincodeLink = 'address/searchNew?id=' + this.parentpin + '&s=';
  }

  
  getTitle() {
    this.newSubscriberService.getTitleList().subscribe((result) => {
      this.titleList = result.dataList;
    });
  }

  getIpPoolList() {
    this.ippoolServices.getIppoolList().subscribe((result) => {
      this.ippoolList = result.dataList;
      this.ippoolList.forEach(data => {
        if (data.defaultPoolFlag === true) {
          this.form.patchValue({ defaultpoolid: data.poolId });
        }
      });
    });
  }

  getServiceTypeList(){  
    this.commonlistservice.getGenericRemarkType('serviceType').subscribe((res)=>{
      this.serviceTypeList = res.dataList;
    });
  }

  getConnectionTypeList(){
    this.commonlistservice.getGenericRemarkType('strconntype').subscribe((res)=>{
      this.connectionTypeList = res.dataList;
    });
  }
  
  getRouterTypeList(){
    this.commonlistservice.getGenericRemarkType('routerType').subscribe((res)=>{
      this.routerTypeList = res.dataList;
    });
  }

  getFranchiesList() {
    let partnerId = localStorage.getItem('partnerId')
    let isPartner = localStorage.getItem('partnerFlag')
    if(isPartner == 'false'){
      this.franchiesService.getFranchiesList().subscribe(result => {
        this.franchiesList = Object.assign([],result.content);
      });
    }
    else{
      this.franchiesService.getManagablePartnerlistService().subscribe(result => {
        this.franchiesList = Object.assign([],result.content);
        this.partnerId = Number(partnerId);
      })
    }

    setTimeout(() => {
      // console.log('aaaa',this.franchiesList)
      if(this.franchiesList){
        if(this.franchiesList.length == 1){
          this.form.get('partnerid').setValue(Number(partnerId))
          //this.form.get('partnerId').disable()
        }
        //this.tempPartnerId.emit(this.partnerId);
      }
    }, 250);
    
    //this.getSaleRespresentativedata(localStorage.partnerId,6)
  }

  setValidation() {

    this.form = new FormGroup({
    address1: new FormControl('', [Validators.required]),
    landmark: new FormControl(null),
    areaId: new FormControl(null),
    propertyType:new FormControl(null),
    pincodeId: new FormControl('', [Validators.required]),
    blockNo: new FormControl(null),
    buildingId: new FormControl(null),
    permanentAddress: new FormControl(null),
    paymentAddress: new FormControl(null),
    District: new FormControl(null),
    Taluka: new FormControl(null),
    Constituency: new FormControl(null),
    Country: new FormControl(null, [Validators.required]),
    State: new FormControl(null, [Validators.required]),
    City: new FormControl(null,[Validators.required]),
    temppincode: new FormControl(''),
    countryId: new FormControl(null),
    stateId: new FormControl(null),
    cityId: new FormControl(null),
    districtId: new FormControl(null),
    talukaId: new FormControl(null),
    constituencyId: new FormControl(null),

      //-----------------------------

      blockNo2: new FormControl(null),
      address2: new FormControl(null),
      landmark2: new FormControl(null),
      pincodeId2: new FormControl(null),
      Country2: new FormControl(null),
      countryId2: new FormControl(null),
      State2: new FormControl(null),
      stateId2: new FormControl(null),
      District2: new FormControl(null),
      districtId2: new FormControl(null),
      Taluka2: new FormControl(null),
      talukaId2: new FormControl(null),
      Constituency2: new FormControl(null),
      constituencyId2: new FormControl(null),
      City2: new FormControl(null),
      cityId2: new FormControl(null),
      areaId2: new FormControl(null),
      buildingId2: new FormControl(null),
      temppincode2: new FormControl(''),


      //-----------------------------

      blockNo3: new FormControl(null),
      address3: new FormControl(null),
      landmark3: new FormControl(null),
      pincodeId3: new FormControl(null),
      Country3: new FormControl(null),
      countryId3: new FormControl(null),
      State3: new FormControl(null),
      stateId3: new FormControl(null),
      District3: new FormControl(null),
      districtId3: new FormControl(null),
      Taluka3: new FormControl(null),
      talukaId3: new FormControl(null),
      Constituency3: new FormControl(null),
      constituencyId3: new FormControl(null),
      City3: new FormControl(null),
      cityId3: new FormControl(null),
      areaId3: new FormControl(null),
      buildingId3: new FormControl(null),
      temppincode3: new FormControl(''),

    });

    this.form2 = new FormGroup({
      title: new FormControl(null, [Validators.required]),
      firstname:new FormControl('', [Validators.required]),
      middlename:new FormControl('', [Validators.required]),
      lastname:new FormControl('', [Validators.required]),
      aadhar:new FormControl(''),
      mobile:new FormControl(null),
      email:new FormControl(null),
      username:new FormControl('', [Validators.required,Validators.pattern('^[a-zA-Z0-9_]+$')]),
      cafno:new FormControl('', [Validators.required]),
      partnerid:new FormControl(null, [Validators.required]),
      aadhaarImagePath: new FormControl(null),
      aadhaarImageName: new FormControl(null),
    });

    this.form3 = new FormGroup({
      onlinerenewalflag: new FormControl(false),
      voicesrvtype: new FormControl(null),
      didno: new FormControl(null),
      childdidno: new FormControl(null),
      intercomno: new FormControl(null),
      intercomgrp: new FormControl(null),
      voipenableflag: new FormControl(false),
      emailenable:new FormControl(true),
      smsenable:new FormControl(true),
      voipanble:new FormControl(true),
      //allocateInventory: new FormControl(false),
    });

    this.form4 = new FormGroup({
      charge_id: new FormControl(null),
      charge_date: new FormControl(null),
      overridePrice:new FormControl(null),
      isPriceOverride: new FormControl(false),
      isIncludeTax: new FormControl(false),
    });

    this.form5 = new FormGroup({
      networktype: new FormControl(null),
      defaultpoolid: new FormControl(null),
      serviceareaid: new FormControl(null),
      networkdevicesid: new FormControl(null),
      oltslotid: new FormControl(null),
      oltportid: new FormControl(null),
      strconntype: new FormControl(null),
      service_area: new FormControl(null),
      onuid: new FormControl(null),
      servicetype:new FormControl(null),
      routerType:new FormControl(null),
      approvedBy: new FormControl(null),
      reason: new FormControl(null),
      select_ip:new FormControl(null),
      poolId:new FormControl(null),
      ipPoolDtlsId:new FormControl(null),
      olt: new FormControl(null),
    });

    this.form6 = new FormGroup({
      box_type: new FormControl(null),
      iptvId: new FormControl(null),
      iptvMacAddress: new FormControl(null),
      iptvEthMacAddress: new FormControl(null),
      iptvSerialNo: new FormControl(null)
    })

    this.PlanDetailForm = new FormGroup({
      planType: new FormControl(null),
      planId: new FormControl(null),
      isPriceOverride: new FormControl(null),
      isIncludeTax: new FormControl(null),
      sellPrice: new FormControl(null),
      //selectedVASIds: new FormControl(null)
    });
  }

  getService(): IBaseService<any> {
    return this.vasProviderService;
  }
  
  setData(data: NewSusbcriberModel) {
    this.entity = data;
  }
  
  getData(): NewSusbcriberModel {
    return this.entity;
  }

  public getModuleHomeUrl(): string {
    this.url = '/subscriber'
    return this.url;
  }

  public getActiveRoute(): ActivatedRoute {
    return this.activeRoute;
  }
  public getNewModal(): NewSusbcriberModel {
    return new NewSusbcriberModel();
  }

  public getFormGroup(): FormGroup {
    return this.form;
  }

  public getId(): number {
    return this.entity.id;
  }

  get f() {
    return this.form.controls;
  }

  get f2() {
    return this.form2.controls;
  }

  get f3() {
    return this.form3.controls;
  }

  get f4() {
    return this.form4.controls;
  }

  get f5() {
    return this.form5.controls;
  }

  get f6() {
    return this.form6.controls;
  }

  getGenericDataService(){
    return this.vasProviderService
  }

  backToViewList() {
    this.router.navigate(['/configuration/vas']);
  }

  getRequiredAllField() {
    this.mobile = null;
    this.newSubscriberService.getRequiredAllField().subscribe(result => {
      
      if (result.responseCode === 200) {

        this.dynamicrequiredArray = result.data;
        this.firstname = this.dynamicrequiredArray.find((e => e.fieldname === 'firstname'));
        this.middlename = this.dynamicrequiredArray.find((e => e.fieldname === 'middlename'));
        this.lastname = this.dynamicrequiredArray.find((e => e.fieldname === 'lastname'));
        
        this.mobile = this.dynamicrequiredArray.find((e => e.fieldname === 'mobile_no'));
        this.cafno = this.dynamicrequiredArray.find((e => e.fieldname === 'caf_no'));
        this.email = this.dynamicrequiredArray.find((e => e.fieldname === 'email'));

        this.serviceType = this.dynamicrequiredArray.find((e => e.fieldname === 'serviceType'));
        this.ConnectionType = this.dynamicrequiredArray.find((e => e.fieldname === 'connectionType'));
        this.RouterType = this.dynamicrequiredArray.find((e => e.fieldname === 'routerType'));
        this.oltobj = this.dynamicrequiredArray.find(e => e.fieldname == 'OLT');


        if ((this.firstname !== null && this.firstname !== undefined) && this.firstname.isMandatory === true) {
          this.form2.get('firstname').setValidators(Validators.required)
          this.form2.get('firstname').updateValueAndValidity();
        }
        else {
          this.form2.get('firstname').clearValidators()
          this.form2.get('firstname').updateValueAndValidity();
        }

        if ((this.middlename !== null && this.middlename !== undefined) && this.middlename.isMandatory === true) {
          this.form2.get('middlename').setValidators(Validators.required)
          this.form2.get('middlename').updateValueAndValidity();
        }
        else {
          this.form2.get('middlename').clearValidators()
          this.form2.get('middlename').updateValueAndValidity();
        }

        if ((this.lastname !== null && this.lastname !== undefined) && this.lastname.isMandatory === true) {
          this.form2.get('lastname').setValidators(Validators.required)
          this.form2.get('lastname').updateValueAndValidity();
        }
        else {
          this.form2.get('lastname').clearValidators()
          this.form2.get('lastname').updateValueAndValidity();
        }

        if ((this.mobile !== null && this.mobile !== undefined) && this.mobile.isMandatory === true) {
          this.form2.get('mobile').setValidators([Validators.required, Validators.pattern('^[0-9]{10}$')])
          this.form2.get('mobile').updateValueAndValidity();
        }
        else {
          this.form2.get('mobile').clearValidators();
          this.form2.get('mobile').setValidators(Validators.pattern('^[0-9]{10}$'))
          this.form2.get('mobile').updateValueAndValidity();
        }

        if ((this.cafno !== null && this.cafno !== undefined) && this.cafno.isMandatory === true) {
          this.form2.get('cafno').setValidators(Validators.required)
          this.form2.get('cafno').updateValueAndValidity();
        }
        else {
          this.form2.get('cafno').clearValidators()
          this.form2.get('cafno').updateValueAndValidity();
        }

        if ((this.email !== null && this.email !== undefined) && this.email.isMandatory === true) {
          this.form2.get('email').setValidators([Validators.required, Validators.email]);
          this.form2.get('email').updateValueAndValidity();
        }
        else {
          this.form2.get('email').clearValidators();
          this.form2.get('email').setValidators(Validators.email);
          this.form2.get('email').updateValueAndValidity();
        }

        if ((this.serviceType !== null && this.serviceType !== undefined) && this.serviceType.isMandatory === true) {
          this.form5.get('servicetype').setValidators(Validators.required)
          this.form5.get('servicetype').updateValueAndValidity();
        }
        else {
          this.form5.get('servicetype').clearValidators()
          this.form5.get('servicetype').updateValueAndValidity();
        }

        if ((this.ConnectionType !== null && this.ConnectionType !== undefined) && this.ConnectionType.isMandatory === true) {
          this.form5.get('strconntype').setValidators(Validators.required)
          this.form5.get('strconntype').updateValueAndValidity();
        }
        else {
          this.form5.get('strconntype').clearValidators()
          this.form5.get('strconntype').updateValueAndValidity();
        }

        if (this.oltobj && this.oltobj !== null && this.oltobj.isMandatory === true) {
          this.form5.get('olt').setValidators(Validators.required)
          this.form5.get('olt').updateValueAndValidity();
        }
        else {
          this.form5.get('olt').clearValidators()
          this.form5.get('olt').updateValueAndValidity();
        }
      }
    })
  }

  
@HostListener('window:scroll', ['$event'])
onWindowScroll($event) {
    if(window.pageYOffset < 160){
      this.isScrollFromTop = false;
    }else{
      this.isScrollFromTop = true;
    }
}

@HostListener('window:resize', ['$event'])
onResize(event) {
  if(window.innerWidth < 1000){
    this.showVerticalStrepper = true;
  }else{
    this.showVerticalStrepper = false;
  }
}

  checkUniqueCustomerByMobile(el) { 
    if (this.form2.get('mobile').value !== null && 
    this.form2.get('mobile').value !==undefined
    && this.mobile.allowDuplicate === true){
      ////console.log("Mobile Uni"+this.insertPrimaryInformationForm.get('mobile_no').value)
      let value = el.target.value;
      if (value && value.length) {
        this.invalidMobileAsPerApi = false;
        if(value.length !== 10){
          this.isVerifybtn = true;
        }
        this.newSubscriberService.checkUniqueCustomerByMobile(value,null).subscribe(result => {
          if (result.responseCode !== 200) {
            this.invalidMobileAsPerApi = true;
            this.isVerifybtn = true;
            this.invalidMobileAsPerApiMsg = result.responseMessage;
            this.onTouchedandChange(); 
          } else {
            this.invalidMobileAsPerApi = false;
            if(value.length === 10){
              this.isVerifybtn = false;
            }
            this.invalidMobileAsPerApiMsg = '';
            this.onTouchedandChange();
          }
        });
      } else {
        this.isVerifybtn = true;
        this.invalidMobileAsPerApi = false;
        this.invalidMobileAsPerApiMsg = '';
      }

    }
    else{
      //3 Line New code
      this.invalidMobileAsPerApi = false;
            this.invalidMobileAsPerApiMsg = '';
            this.onTouchedandChange();
    }
    
  }

  checkUniqueCustomerByUsername(el) {
    let value = el.target.value;
    if (value && value.length) {
      if(!this.spaceCheck(value)){
        this.invalidUsernameAsPerApi = false;
        this.newSubscriberService.checkUniqueCustomerByUsername(value).subscribe(result => {
          if (result.responseCode !== 200) {
            this.invalidUsernameAsPerApi = true;
            this.invalidUsernameAsPerApiMsg = result.responseMessage;
            this.onTouchedandChange();
          } else {
            this.invalidUsernameAsPerApi = false;
            this.invalidUsernameAsPerApiMsg = '';
            this.onTouchedandChange();
          }
        });

      }
     
    } else {
      this.invalidUsernameAsPerApi = false;
      this.invalidUsernameAsPerApiMsg = '';
    }
    console.log('data =>', this.form2.get('username'));
  }

  numberOnlyInput(event)
  {
    if(event && event.length >= 10){
      this.isVerifybtn = false
     }
     else
     {
      this.isVerifybtn =  true
     }
  }

  numberOnlyInputAadhar(event)
  {
    if(event && event.length >= 12){
      this.isAadhaarbtn = false
     }
     else
     {
      this.isAadhaarbtn =  true
     }
  }

  
  onAadhaarInput(event){
    if(event && event.length >= 12){
     this.isAadhaarbtn = false
    }
    else
    {
  this.isAadhaarbtn =  true
    }
  }

  setFocusOnHtmlElements(elementName, timeoutTime){
    let el = document.getElementById(elementName);
    setTimeout(()=>{
      if(el)
        el.focus();
    }, timeoutTime);
  }

  getAadharOTP(id?){
    if(id){
      this.setFocusOnHtmlElements('otp',100);
      this.customModalService.open(id);
    }
    this.newSubscriberService.getAadhaarOTP(this.form2.get('aadhar').value).subscribe((res)=>{
      ////console.log("Aadhaar otp res",res); 
      this.aadhaar_OTP = null
      if(res.responseCode !== 200){
        if(res.responseMessage==null){
          this.toastrService.error("Try again later")
        }
        else{
          this.toastrService.error(res.responseMessage)
        }
      }
      else{
        this.client_id = res.data.client_id;
        this.toastrService.success("OTP Sent Successfully")
      }
    });
  }

  getAddressMapping(){
    let temp = this.tokenservice.getAddressLevel()
    this.addressMapArray = temp.filter(res=> res.name.toLowerCase()!='pincode' && res.name.toLowerCase()!='area' && res.name.toLowerCase()!='building')
    this.addressMapArray2 = temp.filter(res=> (res.name.toLowerCase() == 'area' ))
    this.addressMapArray3 = temp.filter(res=> (res.name.toLowerCase() == 'building' ))
    let tempn = temp.filter(res=> (res.name.toLowerCase() == 'pincode' ))
    this.parentpin = tempn[0].levelNo
    if(this.addressMapArray.find(res=> res.name.toLowerCase()=='district')){
      this.form.get('District').setValidators([Validators.required]);
      this.form.get('District').updateValueAndValidity();
    }
    if(this.addressMapArray.find(res=> res.name.toLowerCase()=='taluka')){
      this.form.get('Taluka').setValidators([Validators.required]);
      this.form.get('Taluka').updateValueAndValidity();
    }
    if(this.addressMapArray.find(res=> res.name.toLowerCase()=='constituency')){
      this.form.get('Constituency').setValidators([Validators.required]);
      this.form.get('Constituency').updateValueAndValidity();
    }
  }

  onChangePlanType(event){
    this.PlanDetailForm.get('sellPrice').reset();
    this.NEXG_VAS = [];
    this.VAS_OTT = [];
    this.VAS_IPTV = [];
    this.PlanDetailForm.get('planId').reset();
    this.planDetailsList  = [];
    this.planGroup = null;
    if(event && event.value){
      this.planGroup = event.value
    }
    if(this.partnerId == null || this.partnerId == undefined){
      this.toastrService.error("Please Select Partner.");
    }
  }


  onChangePartner(event) {
    if(event == undefined)
    {
      this.partnerId = null;
    }
    this.commoissiontype = event.commission_type;
    this.partnerId = event.franchies_id;
    if(this.partnerId!=null){
      this.getConfigBypartner(this.partnerId);
    }
   }

   getConfigBypartner(id){
    this.franchiesService.getconfigByPartner(id).subscribe((res)=>{
      if(res.dataList.length>0){
        res.dataList.forEach((res)=>
        {
          if(res.clientServiceName=="Online Purchase" && res.value.toLowerCase()=='yes'){
            this.checkOnlineRenewal = true;
            this.form3.get('onlinerenewalflag').setValue(true)
          }
        })
      }
      else{
        this.checkOnlineRenewal = false;
        this.form3.get('onlinerenewalflag').setValue(false)
      }
    });
  }

  onChangeOnlineRenewal(el) {
    this.checkOnlineRenewal = el.target.checked;
    this.form3.get('onlinerenewalflag').setValue(this.checkOnlineRenewal)
  }

  onChangePlanDetails(el) {
    this.selectedVoiceStr = undefined;
    this.selectedOTTStr = undefined;
    this.selectedIPTVStr = undefined;
    if(!el){
      this.planDtl = [];
    }
    this.PlanDetailForm.get('sellPrice').reset();
    if(el && el.vasdtos){
      this.VAS_IPTV = el.vasdtos.filter((ls) => ls.type == 'IPTV');
      this.VAS_OTT = el.vasdtos.filter((ls) => ls.type == 'OTT');
      this.NEXG_VAS = el.vasdtos.filter((ls) => ls.type == 'VOICE');
      this.isAllowVAS = el.allowVasSelection;
      if(this.isAllowVAS){
        el.vasdtos.forEach(res=>res.selected = false)
        this.vasprice = 0;
      }else{
        el.vasdtos.forEach(res=>res.selected = true)
        this.calculateVasPrice();
      }
    }else{
      this.VAS_IPTV = [];
      this.VAS_OTT = [];
      this.NEXG_VAS = [];
    }
    this.planDtl = el;
    let offerpricewithtax = this.planDtl.offerprice + this.planDtl.taxamount + (this.isAllowVAS?0:this.vasprice);
    this.PlanDetailForm.get('sellPrice').setValue((offerpricewithtax).toFixed(2));
    this.setValidationToSellPrice(this.planDtl);
    let plan_id = el.id;
    if(this.partnerId == null){
      this.toastrService.error("Please Select Partner.");
    }
  } 

  deselectRadio(id,i){
    if(this.isAllowVAS){
      if(id == 'voice'){
        this.NEXG_VAS.map((ls) => ls.selected = false);
        this.NEXG_VAS[i].selected = true;
      }
      if(id == 'iptv'){
        this.VAS_IPTV.map((ls) => ls.selected = false);
        this.VAS_IPTV[i].selected = true;
      }
      if(id == 'ott'){
        this.VAS_OTT.map((ls) => ls.selected = false);
        this.VAS_OTT[i].selected = true;
      }
      this.calculateVasPrice();
    }
  }

  calculateVasPrice(){
    this.vasprice = 0;
    this.NEXG_VAS.forEach((ls) =>{
        if(ls.selected){
          this.selectedVoiceStr = ls.name;
          this.vasprice += (ls.offerPrice ? ls.offerPrice : 0) + (ls.taxAmount ? ls.taxAmount : 0);
        }
    });

    this.VAS_OTT.forEach((ls) =>{
        if(ls.selected){
          this.selectedOTTStr = ls.name
          this.vasprice += (ls.offerPrice ? ls.offerPrice : 0) + (ls.taxAmount ? ls.taxAmount : 0);
        }
    });

    this.VAS_IPTV.forEach((ls) =>{
        if(ls.selected){
          this.selectedIPTVStr = ls.name;
          this.vasprice += (ls.offerPrice ? ls.offerPrice : 0) + (ls.taxAmount ? ls.taxAmount : 0);
        }
    });

    // this.form = this.planDtl.offerprice + this.planDtl.taxamount +  this.planDtl.vasPrice;
    //this.PlanDetailForm.get('sellPrice').setValue((this.planDtl.offerprice + this.planDtl.taxamount ).toFixed(2));
}

  changeOverridePlanPrice(event){
    if(event.target.checked == true){
      this.isSellPrice = true;
    }else{
   this.isSellPrice = false;
    let temp = this.planDtl.offerprice + this.planDtl.taxamount;
    this.PlanDetailForm.get('sellPrice').setValue(temp)

    }
}
getcommonList(){
  this.newSubscriberService.getCommonListPropertyType().subscribe((res)=>{
    this.propertyTypeList = res.dataList;
  })
}

onChangeArea(event){
  this.buildingList = [];
  this.form.get('buildingId').reset();
  this.form.get('buildingId').updateValueAndValidity();
  if(event && event.buildings){
    this.buildingList = event.buildings
  }
  else{
    
  }
}

onChangeArea2(event){
  this.buildingList2 = [];
  this.form.get('buildingId2').reset();
  this.form.get('buildingId2').updateValueAndValidity();
  if(event && event.buildings){
    this.buildingList2 = event.buildings
  }
  else{
    
  }
}

onChangeArea3(event){
  this.buildingList3 = [];
  this.form.get('buildingId3').reset();
  this.form.get('buildingId3').updateValueAndValidity();
  if(event && event.buildings){
    this.buildingList3 = event.buildings
  }
  else{
    
  }
}

  getPostPaidPlanByService() {
    let reqObj = {
      serviceType: 1,
      partnerId: this.partnerId,
      planGroup: this.planGroup
     }
    if(this.planGroup!=null){
      this.planService.getPostPaidPlanByServiceList(reqObj).subscribe((result) => {
        this.planDetailsList = result.dataList;
      });
    }
  }

  setValidationToSellPrice(planDtl){
    this.planDtl = planDtl;
    if(this.planDtl && this.planDtl !== null){
      if(this.planDtl.minPrice && this.planDtl.maxPrice){
        this.PlanDetailForm.get("sellPrice").setValidators([Validators.min(this.planDtl.minPrice), Validators.max(this.planDtl.maxPrice)]);
      }else{
        this.PlanDetailForm.get("sellPrice").clearValidators();
        this.PlanDetailForm.get("sellPrice").updateValueAndValidity();
      }
      if(this.planDtl.minPrice && (this.planDtl.maxPrice === undefined || this.planDtl.maxPrice === null)){
        this.PlanDetailForm.get("sellPrice").setValidators([Validators.min(this.planDtl.minPrice)]);
      }
      if(this.planDtl.maxPrice && (this.planDtl.minPrice === undefined || this.planDtl.minPrice === null)){
        this.PlanDetailForm.get("sellPrice").setValidators([Validators.max(this.planDtl.maxPrice)]);
      }
    }
  }

  onSellPriceBlur(event){
    let minwithtax = this.planDtl.minPriceTax+this.planDtl.minPrice
    let maxwithtax = this.planDtl.maxPriceTax+this.planDtl.maxPrice
    if(event){
      this.changeIncludeTax(this.isIncludeTax)
    }
  }

  changeIncludeTax(event){
  }

  getSelectedVas(arr){
    return arr.find((ls) => ls.selected);
  }

 onClickAddPlanDetails(el) {
    let data = this.PlanDetailForm.value;
    let associatedVas = [];
    if(this.getSelectedVas(this.NEXG_VAS)){
      associatedVas.push(this.getSelectedVas(this.NEXG_VAS));
    }
    if(this.getSelectedVas(this.VAS_IPTV)){
      associatedVas.push(this.getSelectedVas(this.VAS_IPTV));
    }
    if(this.getSelectedVas(this.VAS_OTT)){
      associatedVas.push(this.getSelectedVas(this.VAS_OTT));
    }
    if (data.planId) {
      let selected_plan_services = "Data";
      let selected_plan_details = _.find(this.planDetailsList, { id: data.planId });
      let offerprice: any = parseFloat(selected_plan_details.offerprice) 
      this.taxDetailsByPlanData = selected_plan_details.taxamount;
      if(this.isSellPrice)
      {
        if(!this.isIncludeTax){

          let minwithtax =this.planDtl.minPrice + this.planDtl.minPriceTax
          let maxwithtax  = this.planDtl.maxPrice + this.planDtl.maxPriceTax
          
        
             let data={
        "amount": this.PlanDetailForm.get("sellPrice").value,
        "taxId": this.planDtl.taxId
      }
       this.planService.taxCalculationByAmount(data).subscribe((result) => {
        this.taxAmount = result.TaxAmount;
        let sellPrice = this.PlanDetailForm.get('sellPrice').value 
        let tempsell = Number(this.PlanDetailForm.get('sellPrice').value) + result.TaxAmount;
   
          if(this.planDtl.minPrice !== null && this.planDtl.maxPrice !== null){
            if(Number(tempsell) >  maxwithtax){
              this.toastrService.error("Sell Price Should be less than " + maxwithtax + "(Incl. Tax)")
              return;
            }
            else if(Number(tempsell) < minwithtax){
              this.toastrService.error("Sell Price Should be greater than " +  minwithtax + "(Incl. Tax)")
              return;
            }
            else{
            offerprice = Number(sellPrice) + result.TaxAmount;
    
            this.rowData = {
              service: selected_plan_services,
              planId: selected_plan_details.id,
              planname: selected_plan_details.name,
              isIncludeTax:this.isIncludeTax,
              sellPrice:Number(this.PlanDetailForm.get('sellPrice').value),
              isPriceOverride:this.isSellPrice,
              plandetails: selected_plan_details.name,
              validity: selected_plan_details.validity,
              offerprice: tempsell,
              associatedVas: associatedVas
            };
            if (this.addPlanList.length === 0) {
              this.addPlanList.push(this.rowData);
              this.refreshDataSource(this.addPlanList);
              this.closeModal();
            }
            else {
              this.toastrService.error("You can select Only One Plan.");
            }
            }
          }
          else {
            offerprice = tempsell;
    
            this.rowData = {
              service: selected_plan_services,
              planId: selected_plan_details.id,
              planname: selected_plan_details.name,
              isIncludeTax:this.isIncludeTax,
              sellPrice:Number(this.PlanDetailForm.get('sellPrice').value),
              isPriceOverride:this.isSellPrice,
              plandetails: selected_plan_details.name,
              validity: selected_plan_details.validity,
              offerprice: tempsell,
              associatedVas: associatedVas
            };
            if (this.addPlanList.length === 0) {
              this.addPlanList.push(this.rowData);
              this.refreshDataSource(this.addPlanList);
              this.closeModal();
            }
            else {
              this.toastrService.error("You can select Only One Plan.");
            }
          }
      })
     
        }
        if(this.isIncludeTax){
          let minwithtax = this.planDtl.minPrice + this.planDtl.minPriceTax
          let maxwithtax  = this.planDtl.maxPrice + this.planDtl.maxPriceTax
          if(this.planDtl.maxPrice !== null && this.planDtl.minPrice !== null){
            if(Number(this.PlanDetailForm.get('sellPrice').value) >  maxwithtax){
              this.toastrService.error("Sell Price Should be less than " + maxwithtax + "(Incl. Tax)")
              return;
            }
            else if(Number(this.PlanDetailForm.get('sellPrice').value) < minwithtax){
              this.toastrService.error("Sell Price Should be greater than " +  minwithtax + "(Incl. Tax)")
              return;}
            else{
          offerprice = Number(this.PlanDetailForm.get('sellPrice').value)
          this.rowData = {
            service: selected_plan_services,
            planId: selected_plan_details.id,
            planname: selected_plan_details.name,
            isIncludeTax:this.isIncludeTax,
            sellPrice:Number(this.PlanDetailForm.get('sellPrice').value),
            isPriceOverride:this.isSellPrice,
            plandetails: selected_plan_details.name,
            validity: selected_plan_details.validity,
            offerprice: offerprice,
            associatedVas: associatedVas
          };
          if (this.addPlanList.length === 0) {
            this.addPlanList.push(this.rowData);
            this.refreshDataSource(this.addPlanList);
            this.closeModal();
          }
          else {
            this.toastrService.error("You can select Only One Plan.");
          }
    
            }
          }
          else{
            offerprice = Number(this.PlanDetailForm.get('sellPrice').value)
            this.rowData = {
              service: selected_plan_services,
              planId: selected_plan_details.id,
              planname: selected_plan_details.name,
              isIncludeTax:this.isIncludeTax,
              sellPrice:Number(this.PlanDetailForm.get('sellPrice').value),
              isPriceOverride:this.isSellPrice,
              plandetails: selected_plan_details.name,
              validity: selected_plan_details.validity,
              offerprice: offerprice,
              associatedVas: associatedVas
            };
            if (this.addPlanList.length === 0) {
              this.addPlanList.push(this.rowData);
              this.refreshDataSource(this.addPlanList);
              this.closeModal();
            }
            else {
              this.toastrService.error("You can select Only One Plan.");
            }
          }
        
        }
      }
      else{
        if(!this.isSellPrice){
          if (this.taxDetailsByPlanData || this.taxDetailsByPlanData === 0) {
            offerprice = parseFloat(offerprice) + parseFloat(this.taxDetailsByPlanData.toFixed(2));
            this.rowData = {
              service: selected_plan_services,
              planId: selected_plan_details.id,
              planname: selected_plan_details.name,
              isIncludeTax:this.isIncludeTax,
              sellPrice:null,
              isPriceOverride:this.isSellPrice,
              plandetails: selected_plan_details.name,
              validity: selected_plan_details.validity,
              offerprice: offerprice,
              associatedVas: associatedVas
            };
            if (this.addPlanList.length === 0) {
              this.addPlanList.push(this.rowData);
              this.refreshDataSource(this.addPlanList);
              this.closeModal();
            }
            else {
              this.toastrService.error("You can select Only One Plan.");
            }
      
          }
        }
      
      }
    } else if(this.partnerId == null || this.partnerId == undefined){
      this.toastrService.error("Please Select Partner.");
    }
    
  }

  refreshDataSource(data) {
    if (data) {
      this.dataSource = new MatTableDataSource(JSON.parse(JSON.stringify(data)));
    }
  }

  refreshDataSourceForVAS(data) {
    if (data) {
      this.dataSourceForVAS = new MatTableDataSource(JSON.parse(JSON.stringify(data)));
    }
  }

  VerifyOtp(id:string){
    if(this.aadhaar_OTP && this.client_id){
      let obj = {
        "client_id": this.client_id,
        "mobile_number" : "",
         "otp" : this.aadhaar_OTP
      }
      this.newSubscriberService.verifyOtpAadhaar(obj).subscribe((res)=>{
       if(res.responseCode !== 200){
        this.toastrService.error(res.responseMessage)
       }
       else{
         this.form.get('address1').setValue(res.data.address);
         this.form2.get('firstname').setValue(res.data.full_name);
         this.form.get('landmark').setValue(res.data.landmark);
         this.form.get('pincodeId').setValue(res.data.pincode);
         this.form2.get('aadhaarImagePath').setValue(res.data.imagePath);
         this.form2.get('aadhaarImageName').setValue(res.data.imageName);
         this.imagePath= this.backendImageUrl + 'alticonImages/CustAadhaarImage/' + res.data.imageName;
         this.isImage=true;
        //  this.form.get('mobile_no').setValue(res.data.mobile);
        //  this.form.get('email').setValue(res.data.email);
        //console.log('--',this.form.get('pincode').setValue(res.data.pincode));
        this.default_pincode_value = res.data.pincode;
         this.aadharverified = true;
        this.toastrService.success("Aadhar Verrified Successfully")
        this.customModalService.close(id, { close_from: "close_button" });
       }
      
      });

    }
    else{
      this.toastrService.error("Please Enter OTP")
    }
  }

  spaceCheck(value){
    return value.indexOf(' ') >= 0;
  }
  SpaceNotAllowedUserName(ctrlValue: any) {
    if (ctrlValue.which === 32 || ctrlValue.code === "Space") {
      ctrlValue.preventDefault();
    }
  }

  spaceNotAllow(event) {
    if (event.which == 32) {
      // alertify.error("Space Not Allowed");
      return true;
    }
  }

  numberOnly(event): boolean {

    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  clearMessage() {
    if (this.form.controls['mobile'].invalid &&
      (this.form.controls['mobile'].dirty ||
        this.form.controls['mobile'].touched)) {
      if (this.form.controls['mobile'].errors.pattern) {
        this.invalidMobileAsPerApiMsg='';
      }
    }
    if (this.form.controls['email'].invalid &&
      (this.form.controls['email'].dirty ||
        this.form.controls['email'].touched)) {
      if (this.form.controls['email'].errors.email) {
        this.invalidEmailAsPerApiMsg='';
      }
    }
  }

  checkUniqueCustomerByEmail(el) {
    if (this.form.get('email').value !==null &&
    this.form.get('email').value !==undefined
    && this.email.allowDuplicate === true) {
      ////console.log("Email Uni"+this.form.get('email').value)
      let value = el.target.value;
      if (value && value.length) {
        this.invalidEmailAsPerApi = false;
        this.newSubscriberService.checkUniqueCustomerByEmail(value, null).subscribe(result => {
          if (result.responseCode !== 200) {
            this.invalidEmailAsPerApi = true;
            this.invalidEmailAsPerApiMsg = result.responseMessage;
            this.onTouchedandChange();
          } else {
            this.invalidEmailAsPerApi = false;
            this.invalidEmailAsPerApiMsg = '';
            this.onTouchedandChange();
          }
        });
      } else {
        this.invalidEmailAsPerApi = false;
        this.invalidEmailAsPerApiMsg = '';
      }
    } 
    else{
       // 3 LINE New code
      this.invalidEmailAsPerApi = false;
      this.invalidEmailAsPerApiMsg = '';
      this.onTouchedandChange();
    }

  }


  checkUniqueCustomerByCafno(el) {
    if (this.form.get('cafno').value !== null && 
    this.cafno.allowDuplicate === true) {
      ////console.log("Caf Unique"+this.insertPrimaryInformationForm.get('caf_no').value)
       let value = el.target.value;
        if (value && value.length !== 0) {
          this.invalidCafNoAsPerApi = false;
          this.newSubscriberService.checkUniqueCustomerByCafno(value).subscribe(result => {
            if (result.responseCode !== 200) {
              this.invalidCafNoAsPerApi = true;
              this.invalidCafNoAsPerApiMsg = result.responseMessage;
              this.onTouchedandChange();
            } else {
              this.invalidCafNoAsPerApi = false;
              this.invalidCafNoAsPerApiMsg = '';
              this.onTouchedandChange();
            }
          });
        } else {
          this.invalidCafNoAsPerApi = false;
          this.invalidCafNoAsPerApiMsg = '';
        }
      }
      else{ 
        //3 New Code
              this.invalidCafNoAsPerApi = false;
              this.invalidCafNoAsPerApiMsg = '';
              this.onTouchedandChange();
      }
    }

  onTouchedandChange() {
    let value = this.form.value;
    this.onChange(value);
    this.onTouched(value);
  }

resendOtp(id:string){
  this.newSubscriberService.MobileVerification(this.form2.get('mobile').value).subscribe(res => {
    if (res.responseCode !== 200) {
      this.toastrService.error(res.responseMessage )
    }
    else{
      this.toastrService.success('OTP sent successfully')
    }
  });
  this.customModalService.open(id)
}
onMobileVerify(verified?){
  this.newSubscriberService.MobileVerification(this.form2.get('mobile').value).subscribe(res => {
    if (res.responseCode !== 200) {
      this.toastrService.error(res.responseMessage);
    } else {
      if (res.data == 'success') {
        this.setFocusOnHtmlElements('name',100);
        this.toastrService.success('OTP sent successfully')
        if(verified){
          this.customModalService.open(verified);
        }
      }
    }
  })
}

onPincodeChange(elItem) {
  this.form.get('areaId').reset();
  this.form.get('buildingId').reset();
  this.buildingList = [];
  let el;
  let call_from_autocomplete;
  if (_.isArray(elItem.event) == true) {
    el = _.first(elItem.event);
  } else {
    el = elItem.event;
    call_from_autocomplete = elItem.call_from_autocomplete;
  }
  if (el ) {

    this.form.controls.pincodeId.setValue(el.addressDetails.pincodeId);
    this.form.controls.temppincode.setValue(el.addressDetails.pincode);
    // this.form.get('stateId').setValue(el.addressDetails.state)
      this.form.patchValue({ Country: el.addressDetails.country});
      this.form.patchValue({ State: el.addressDetails.state});
      this.form.patchValue({ City: el.addressDetails.city});
      this.form.patchValue({ District: el.addressDetails.district});
      this.form.patchValue({ Taluka: el.addressDetails.taluka});
      this.form.patchValue({ Constituency: el.addressDetails.constituency});
      this.form.patchValue({ countryId: el.addressDetails.countryId});
      this.form.patchValue({ stateId: el.addressDetails.stateId});
      this.form.patchValue({ cityId: el.addressDetails.cityId});
      this.form.patchValue({ districtId: el.addressDetails.districtId});
      this.form.patchValue({ talukaId: el.addressDetails.talukaId});
      this.form.patchValue({ constituencyId: el.addressDetails.constituencyId});
      for (const prop in this.form.controls) {
        this.form.value[prop] = this.form.controls[prop].value;
      }
      this.parentId = el.parentId;
      this.newSubscriberService.getDetailsByPincode(el.parentId).subscribe(res=>{
        this.areaAllList = res.dataList;
      })
  }
  else if(el == null){
    this.form.get('Country').reset()
    this.form.get('State').reset()
    this.form.get('City').reset()
    this.form.get('District').reset()
    this.form.get('Taluka').reset()
    this.form.get('Constituency').reset()
    this.form.get('countryId').reset()
    this.form.get('stateId').reset()
    this.form.get('cityId').reset()
    this.form.get('districtId').reset()
    this.form.get('talukaId').reset()
    this.form.get('constituencyId').reset()
    this.areaAllList = [];
  }
}

onPincodeChange2(elItem) {
  this.form.get('areaId2').reset();
  this.form.get('buildingId2').reset();
  this.buildingList = [];
  let el;
  let call_from_autocomplete;
  if (_.isArray(elItem.event) == true) {
    el = _.first(elItem.event);
  } else {
    el = elItem.event;
    call_from_autocomplete = elItem.call_from_autocomplete;
  }
  if (el ) {

    this.form.controls.pincodeId2.setValue(el.addressDetails.pincodeId);
    this.form.controls.temppincode2.setValue(el.addressDetails.pincode);
    // this.form.get('stateId').setValue(el.addressDetails.state)
      this.form.patchValue({ Country2: el.addressDetails.country});
      this.form.patchValue({ State2: el.addressDetails.state});
      this.form.patchValue({ City2: el.addressDetails.city});
      this.form.patchValue({ District2: el.addressDetails.district});
      this.form.patchValue({ Taluka2: el.addressDetails.taluka});
      this.form.patchValue({ Constituency2: el.addressDetails.constituency});
      this.form.patchValue({ countryId2: el.addressDetails.countryId});
      this.form.patchValue({ stateId2: el.addressDetails.stateId});
      this.form.patchValue({ cityId2: el.addressDetails.cityId});
      this.form.patchValue({ districtId2: el.addressDetails.districtId});
      this.form.patchValue({ talukaId2: el.addressDetails.talukaId});
      this.form.patchValue({ constituencyId2: el.addressDetails.constituencyId});
      for (const prop in this.form.controls) {
        this.form.value[prop] = this.form.controls[prop].value;
      }
      this.parentId = el.parentId;
      this.newSubscriberService.getDetailsByPincode(el.parentId).subscribe(res=>{
        this.areaAllList2 = res.dataList;
      })
  }
  else if(el == null){
    this.form.get('Country2').reset()
    this.form.get('State2').reset()
    this.form.get('City2').reset()
    this.form.get('District2').reset()
    this.form.get('Taluka2').reset()
    this.form.get('Constituency2').reset()
    this.form.get('countryId2').reset()
    this.form.get('stateId2').reset()
    this.form.get('cityId2').reset()
    this.form.get('districtId2').reset()
    this.form.get('talukaId2').reset()
    this.form.get('constituencyId2').reset()
    this.areaAllList2 = [];
  }
}

onPincodeChange3(elItem) {
  this.form.get('areaId3').reset();
  this.form.get('buildingId3').reset();
  this.buildingList = [];
  let el;
  let call_from_autocomplete;
  if (_.isArray(elItem.event) == true) {
    el = _.first(elItem.event);
  } else {
    el = elItem.event;
    call_from_autocomplete = elItem.call_from_autocomplete;
  }
  if (el ) {

    this.form.controls.pincodeId3.setValue(el.addressDetails.pincodeId);
    this.form.controls.temppincode3.setValue(el.addressDetails.pincode);
    // this.form.get('stateId').setValue(el.addressDetails.state)
      this.form.patchValue({ Country3: el.addressDetails.country});
      this.form.patchValue({ State3: el.addressDetails.state});
      this.form.patchValue({ City3: el.addressDetails.city});
      this.form.patchValue({ District3: el.addressDetails.district});
      this.form.patchValue({ Taluka3: el.addressDetails.taluka});
      this.form.patchValue({ Constituency3: el.addressDetails.constituency});
      this.form.patchValue({ countryId3: el.addressDetails.countryId});
      this.form.patchValue({ stateId3: el.addressDetails.stateId});
      this.form.patchValue({ cityId3: el.addressDetails.cityId});
      this.form.patchValue({ districtId3: el.addressDetails.districtId});
      this.form.patchValue({ talukaId3: el.addressDetails.talukaId});
      this.form.patchValue({ constituencyId3: el.addressDetails.constituencyId});
      for (const prop in this.form.controls) {
        this.form.value[prop] = this.form.controls[prop].value;
      }
      this.parentId = el.parentId;
      this.newSubscriberService.getDetailsByPincode(el.parentId).subscribe(res=>{
        this.areaAllList3 = res.dataList;
      })
  }
  else if(el == null){
    this.form.get('Country3').reset()
    this.form.get('State3').reset()
    this.form.get('City3').reset()
    this.form.get('District3').reset()
    this.form.get('Taluka3').reset()
    this.form.get('Constituency3').reset()
    this.form.get('countryId3').reset()
    this.form.get('stateId3').reset()
    this.form.get('cityId3').reset()
    this.form.get('districtId3').reset()
    this.form.get('talukaId3').reset()
    this.form.get('constituencyId3').reset()
    this.areaAllList3 = [];
  }
}

VerifiedOTP() {
  if (this.mobileOtp) {
    this.newSubscriberService.OTPverifiedServiceAtSave(this.form2.get('mobile').value, this.mobileOtp).subscribe(res => {
      if (res.responseCode === 200) {
        if (res.data === 'success') {
          this.mobileOtp = null;
          this.toastrService.success(res.responseMessage);
          this.mobileverified =  true;
          this.customModalService.close('mobile_verification');
        }
      } else {
        this.toastrService.error(res.responseMessage);
      }
    })
  } else {
    this.toastrService.error("OTP is required");
  }
}


payLoadData()
{
  for(let i=0;i<this.entityPayloadData.addressList.length; i++)
  {
      if ((this.entityPayloadData.addressList[i].addressType == 'Present'))
      {
        this.entityPayloadData.addressList.splice(i);
      }
  }

  this.entityPayloadData.title = this.form2.controls['title'].value;
  this.entityPayloadData.firstname = this.form2.controls['firstname'].value;
  this.entityPayloadData.middlename = this.form2.controls['middlename'].value;
  this.entityPayloadData.lastname = this.form2.controls['lastname'].value;
  this.entityPayloadData.aadhar = this.form2.controls['aadhar'].value;
  this.entityPayloadData.cafno = this.form2.controls['cafno'].value;
  this.entityPayloadData.username = this.form2.controls['username'].value ? this.form2.controls['username'].value.trim() : null;
  this.entityPayloadData.mobile = this.form2.controls['mobile'].value;
  this.entityPayloadData.email = this.form2.controls['email'].value;
  this.entityPayloadData.aadhaarImagePath = this.form2.controls['aadhaarImagePath'].value;
  this.entityPayloadData.aadhaarImageName = this.form2.controls['aadhaarImageName'].value;
  this.entityPayloadData.mobileverified = this.mobileverified;
  this.entityPayloadData.aadharverified = this.aadharverified;
  this.entityPayloadData.partnerid = this.form2.controls['partnerid'].value;
  this.entityPayloadData.defaultpoolid = 1;
  if(this.addPlanList && this.addPlanList.length > 0){
    if(this.addPlanList[0].associatedVas && this.addPlanList[0].associatedVas.length > 0){
      this.addPlanList[0].selectedVASIds =  this.addPlanList[0].associatedVas.map((ls) => ls.id); 
    }
    this.addPlanList[0].associatedVas = undefined;
  }
  this.entityPayloadData.planMappingList = this.addPlanList;

  let presentAddressDetails: any = {
    addressType: 'Present'
  };
  presentAddressDetails.address1 = this.form.controls['address1'].value;
  presentAddressDetails.landmark = this.form.controls['landmark'].value;
  presentAddressDetails.areaId = this.form.controls['areaId'].value;
  presentAddressDetails.pincodeId = this.form.controls['pincodeId'].value;
  presentAddressDetails.countryId = this.form.controls['countryId'].value;
  presentAddressDetails.stateId = this.form.controls['stateId'].value;
  presentAddressDetails.cityId = this.form.controls['cityId'].value;
  presentAddressDetails.constituencyId = this.form.controls['constituencyId'].value;
  presentAddressDetails.talukaId = this.form.controls['talukaId'].value;
  presentAddressDetails.districtId = this.form.controls['districtId'].value;
  presentAddressDetails.buildingId = this.form.controls['buildingId'].value;
  presentAddressDetails.blockNo = this.form.controls['blockNo'].value;
  presentAddressDetails.propertyType = this.form.controls['propertyType'].value;
  
  this.entityPayloadData.addressList.push(presentAddressDetails); 

  if(this.showPermanentAddressSection)
  {
    let permanentAddressDetails: any = {
      addressType: 'Permanent'
    };
    permanentAddressDetails.address1 = this.form.controls['address1'].value;
    permanentAddressDetails.landmark = this.form.controls['landmark'].value;
    permanentAddressDetails.areaId = this.form.controls['areaId'].value;
    permanentAddressDetails.pincodeId = this.form.controls['pincodeId'].value;
    permanentAddressDetails.countryId = this.form.controls['countryId'].value;
    permanentAddressDetails.stateId = this.form.controls['stateId'].value;
    permanentAddressDetails.cityId = this.form.controls['cityId'].value;
    permanentAddressDetails.constituencyId = this.form.controls['constituencyId'].value;
    permanentAddressDetails.talukaId = this.form.controls['talukaId'].value;
    permanentAddressDetails.districtId = this.form.controls['districtId'].value;
    permanentAddressDetails.buildingId = this.form.controls['buildingId'].value;
    permanentAddressDetails.blockNo = this.form.controls['blockNo'].value;
    
    this.entityPayloadData.addressList.push(permanentAddressDetails);
  
  }
  else if(!this.showPermanentAddressSection)
  {
    let permanentAddressDetails: any = {
      addressType: 'Permanent'
    };

    permanentAddressDetails.address1 = this.form.controls['address2'].value;
    permanentAddressDetails.landmark = this.form.controls['landmark2'].value;
    permanentAddressDetails.areaId = this.form.controls['areaId2'].value;
    permanentAddressDetails.pincodeId = this.form.controls['pincodeId2'].value;
    permanentAddressDetails.countryId = this.form.controls['countryId2'].value;
    permanentAddressDetails.stateId = this.form.controls['stateId2'].value;
    permanentAddressDetails.cityId = this.form.controls['cityId2'].value;
    permanentAddressDetails.constituencyId = this.form.controls['constituencyId2'].value;
    permanentAddressDetails.talukaId = this.form.controls['talukaId2'].value;
    permanentAddressDetails.districtId = this.form.controls['districtId2'].value;
    permanentAddressDetails.buildingId = this.form.controls['buildingId2'].value;
    permanentAddressDetails.blockNo = this.form.controls['blockNo2'].value;

    this.entityPayloadData.addressList.push(permanentAddressDetails);
  }
  
  if(this.showPaymentAddressSection)
  {
    let paymentAddressDetails: any = {
      addressType: 'Payment'
    };
    paymentAddressDetails.address1 = this.form.controls['address1'].value;
    paymentAddressDetails.landmark = this.form.controls['landmark'].value;
    paymentAddressDetails.areaId = this.form.controls['areaId'].value;
    paymentAddressDetails.pincodeId = this.form.controls['pincodeId'].value;
    paymentAddressDetails.countryId = this.form.controls['countryId'].value;
    paymentAddressDetails.stateId = this.form.controls['stateId'].value;
    paymentAddressDetails.cityId = this.form.controls['cityId'].value;
    paymentAddressDetails.constituencyId = this.form.controls['constituencyId'].value;
    paymentAddressDetails.talukaId = this.form.controls['talukaId'].value;
    paymentAddressDetails.districtId = this.form.controls['districtId'].value;
    paymentAddressDetails.buildingId = this.form.controls['buildingId'].value;
    paymentAddressDetails.blockNo = this.form.controls['blockNo'].value;
    
    this.entityPayloadData.addressList.push(paymentAddressDetails);
  }
  else if(!this.showPaymentAddressSection)
  {
    let paymentAddressDetails: any = {
      addressType: 'Payment'
    };
    paymentAddressDetails.address1 = this.form.controls['address3'].value;
    paymentAddressDetails.landmark = this.form.controls['landmark3'].value;
    paymentAddressDetails.areaId = this.form.controls['areaId3'].value;
    paymentAddressDetails.pincodeId = this.form.controls['pincodeId3'].value;
    paymentAddressDetails.countryId = this.form.controls['countryId3'].value;
    paymentAddressDetails.stateId = this.form.controls['stateId3'].value;
    paymentAddressDetails.cityId = this.form.controls['cityId3'].value;
    paymentAddressDetails.constituencyId = this.form.controls['constituencyId3'].value;
    paymentAddressDetails.talukaId = this.form.controls['talukaId3'].value;
    paymentAddressDetails.districtId = this.form.controls['districtId3'].value;
    paymentAddressDetails.buildingId = this.form.controls['buildingId3'].value;
    paymentAddressDetails.blockNo = this.form.controls['blockNo3'].value;
    
    this.entityPayloadData.addressList.push(paymentAddressDetails);
  }

  this.entityPayloadData.voicesrvtype = this.form3.controls['voicesrvtype'].value;
  this.entityPayloadData.didno = this.form3.controls['didno'].value;
  this.entityPayloadData.childdidno = this.form3.controls['childdidno'].value;
  this.entityPayloadData.voipenableflag = this.form3.controls['voipenableflag'].value;
  this.entityPayloadData.emailenable = this.form3.controls['emailenable'].value;
  this.entityPayloadData.smsenable = this.form3.controls['smsenable'].value;
  this.entityPayloadData.onlinerenewalflag = this.form3.controls['onlinerenewalflag'].value;
  this.entityPayloadData.intercomgrp = this.form3.controls['intercomgrp'].value;
  this.entityPayloadData.intercomno = this.form3.controls['intercomno'].value;
  this.entityPayloadData.serviceareaid = this.form5.controls['serviceareaid'].value;
  this.entityPayloadData.networktype = this.form5.controls['networktype'].value;
  this.entityPayloadData.servicetype = this.form5.controls['servicetype'].value;
  this.entityPayloadData.oltportid = this.form5.controls['oltportid'].value;
  this.entityPayloadData.oltslotid = this.form5.controls['oltslotid'].value;
  this.entityPayloadData.networkdevicesid = this.form5.controls['olt'].value;
  this.entityPayloadData.strconntype = this.form5.controls['strconntype'].value;
  this.entityPayloadData.reason = this.form5.controls['reason'].value;
  this.entityPayloadData.routerType = this.form5.controls['routerType'].value;
  this.entityPayloadData.onuid = this.form5.controls['onuid'].value ? (this.form5.controls['onuid'].value).trim() : null
  this.entityPayloadData.ipPoolDtlsId = this.form5.controls['ipPoolDtlsId'].value;
  this.entityPayloadData.poolId = this.form5.controls['poolId'].value;
  this.entityPayloadData.ipPoolDtlsId = this.form5.controls['ipPoolDtlsId'].value;
  this.entityPayloadData.iptvId = this.form6.controls['iptvId'].value;
  this.entityPayloadData.iptvSerialNo = this.form6.controls['iptvSerialNo'].value;
  this.entityPayloadData.iptvMacAddress = this.form6.controls['iptvMacAddress'].value;
  this.entityPayloadData.iptvEthMacAddress = this.form6.controls['iptvEthMacAddress'].value;

  this.entityPayloadData.overChargeList = this.addChargeDetailsList;
}

chkSameAsPresentAddressForPermanentAddressDetails(el) {
  if (el.checked === true) {
    this.showPermanentAddressSection = true;
    this.form.get('address2').clearValidators();
    this.form.get('address2').updateValueAndValidity();
    this.form.get('pincodeId2').clearValidators();
    this.form.get('pincodeId2').updateValueAndValidity();
    this.form.get('Country2').clearValidators();
    this.form.get('Country2').updateValueAndValidity();
    this.form.get('State2').clearValidators();
    this.form.get('State2').updateValueAndValidity();
    this.form.get('District2').clearValidators();
    this.form.get('District2').updateValueAndValidity();
    this.form.get('Taluka2').clearValidators();
    this.form.get('Taluka2').updateValueAndValidity();
    this.form.get('Constituency2').clearValidators();
    this.form.get('Constituency2').updateValueAndValidity();
    this.form.get('City2').clearValidators();
    this.form.get('City2').updateValueAndValidity();
  }
  else
  {
    if (el.checked === false) {
      this.showPermanentAddressSection = false;

      for(let i=0;i<this.entityPayloadData.addressList.length; i++)
      {
          if ((this.entityPayloadData.addressList[i].addressType == 'Permanent'))
          {
            this.entityPayloadData.addressList.splice(i);
          }
      }
      this.form.get('address2').setValidators(Validators.required);
      this.form.get('address2').updateValueAndValidity();
      this.form.get('pincodeId2').setValidators(Validators.required);
      this.form.get('pincodeId2').updateValueAndValidity();
      this.form.get('Country2').setValidators(Validators.required);
      this.form.get('Country2').updateValueAndValidity();
      this.form.get('State2').setValidators(Validators.required);
      this.form.get('State2').updateValueAndValidity();
      this.form.get('District2').setValidators(Validators.required);
      this.form.get('District2').updateValueAndValidity();
      this.form.get('Taluka2').setValidators(Validators.required);
      this.form.get('Taluka2').updateValueAndValidity();
      this.form.get('Constituency2').setValidators(Validators.required);
      this.form.get('Constituency2').updateValueAndValidity();
      this.form.get('City2').setValidators(Validators.required);
      this.form.get('City2').updateValueAndValidity()
    }
  }
}

chkSameAsPresentAddressForPaymentAddressDetails(el) {
  if (el.checked === true) {
    this.showPaymentAddressSection = true;

    this.form.get('address3').clearValidators();
    this.form.get('address3').updateValueAndValidity();
    this.form.get('pincodeId3').clearValidators();
    this.form.get('pincodeId3').updateValueAndValidity();
    this.form.get('Country3').clearValidators();
    this.form.get('Country3').updateValueAndValidity();
    this.form.get('State3').clearValidators();
    this.form.get('State3').updateValueAndValidity();
    this.form.get('District3').clearValidators();
    this.form.get('District3').updateValueAndValidity();
    this.form.get('Taluka3').clearValidators();
    this.form.get('Taluka3').updateValueAndValidity();
    this.form.get('Constituency3').clearValidators();
    this.form.get('Constituency3').updateValueAndValidity();
    this.form.get('City3').clearValidators();
    this.form.get('City3').updateValueAndValidity();
  }
  else
  {
    if (el.checked === false) {
      this.showPaymentAddressSection = false;
      for(let i=0;i<this.entityPayloadData.addressList.length; i++)
      {
          if ((this.entityPayloadData.addressList[i].addressType == 'Payment'))
          {
            this.entityPayloadData.addressList.splice(i);
          }
      }
      this.form.get('address3').setValidators(Validators.required);
      this.form.get('address3').updateValueAndValidity();
      this.form.get('pincodeId3').setValidators(Validators.required);
      this.form.get('pincodeId3').updateValueAndValidity();
      this.form.get('Country3').setValidators(Validators.required);
      this.form.get('Country3').updateValueAndValidity();
      this.form.get('State3').setValidators(Validators.required);
      this.form.get('State3').updateValueAndValidity();
      this.form.get('District3').setValidators(Validators.required);
      this.form.get('District3').updateValueAndValidity();
      this.form.get('Taluka3').setValidators(Validators.required);
      this.form.get('Taluka3').updateValueAndValidity();
      this.form.get('Constituency3').setValidators(Validators.required);
      this.form.get('Constituency3').updateValueAndValidity();
      this.form.get('City3').setValidators(Validators.required);
      this.form.get('City3').updateValueAndValidity();
    }
  }
}

backToViewSubscriberList() {
  //this.router.navigate(['/masters/subscriber']);
  if(this.sharingdataService.lastAuditUrl == this.router.url){
    this.router.navigate(['/operations/audit']);
    this.sharingdataService.lastAuditUrl = undefined;
  }else{
    this.router.navigate(['/masters/subscriber']);
  }
}


RequiredFields()
{
  if(this.form.invalid)
  {
    this.addressDetail = true;
  }
  else
  {
    this.addressDetail = false;
  }

  if(this.form2.invalid)
  {
    this.basicDetail = true;
  }
  else
  {
    this.basicDetail = false;
  }

  if(this.form3.invalid)
  {
    this.additionalService = true;
  }
  else
  {
    this.additionalService = false;
  }

  if(this.form4.invalid)
  {
    this.chargeDetail = true;
  }
  else
  {
    this.chargeDetail = false;
  }

  if(this.form5.invalid)
  {
    this.subConNetDetails = true;
  }
  else
  {
    this.subConNetDetails = false;
  }

  if(this.form6.invalid)
  {
    this.IPTVDetails = true;
  }
  else
  {
    this.IPTVDetails = false;
  }

  if(this.PlanDetailForm.invalid)
  {
    this.planDetail = true;
  }
  else
  {
    this.planDetail = false;
  }

}



// this.chargeDetailsData.forEach(chargeItem => {
//   let newItem = {
//     actualprice: chargeItem.actualprice,
//     price: chargeItem.offerprice,
//     chargeid: chargeItem.chargeid,
//     chargetype: chargeItem.charge_type,
//     validity: chargeItem.validity,
//     charge_date: this.datePipe.transform(chargeItem.charge_date, 'yyyy-MM-dd'),
//     // "id": chargeItem.actualprice,
//     // "planid": 0,
//     overridePrice:chargeItem.overridePrice,
//     isPriceOverride:chargeItem.isPriceOverride,
//     isIncludeTax:chargeItem.isIncludeTax
//   };
//   formatedInputData.overChargeList.push(newItem);
// });

formSubmit(rowData) {
  this.submitted = true;
  this.payLoadData();
  this.RequiredFields();
  let self = this;
  if (self.form.valid && self.form2.valid && self.form3.valid && self.form4.valid && self.form5.valid && self.form6.valid && self.PlanDetailForm.valid) {
    if(!self.isUpdateComponent){
      this.newSubscriberService.checkUniqueCustomerByUsername(self.entityPayloadData.username).subscribe(result => {
        if (result.responseCode !== 200) {
          this.toastrService.warning(result.responseMessage,'ERROR');
        } else {
          let title = 'Create Customer'
          if(this.addPlanList && this.addPlanList.length > 0){
            alertify.confirm(title, 'Are you sure you want to create a customer with this plan ' + '<b>'+this.addPlanList[0].planname + '</b>' +  ' ?', function () {
              self.saveAPI();
            },
            function () {
                
            });
          }else{
            alertify.confirm(title, 'Are you sure you want to create a customer without plan ?', function () {
              self.saveAPI();
            },
            function () {
                
            });
          }
        }
      });
    }
  }
  else
  {
    this.toastrService.error("Please fill all the details!");
  }
  // this.router.navigate(['plan-management/value-added-service/add',rowData.id]);
}

saveAPI(){
  let self = this;
  self.newSubscriberService.addSubscriberDetailsService(self.entityPayloadData).subscribe((result) => {
    if (result.status !== 200) {
      self.saveBtn = true;
      if (result.ERROR) {
        self.toastrService.error(result.ERROR);
      } else {
        self.toastrService.error(result.responseMessage);
      }
    } else {
      self.saveBtn = false;
      self.inventoryService.saveInventoryStock(result.customer.id);
      self.toastrService.success("Subscriber Saved Successfully.");
      self.backToViewSubscriberList();
    }
  }, err => {
    self.saveBtn = true;
    self.toastrService.error(err.error.ERROR);
  }
  );
}

CloseVerify(id:string){
  this.customModalService.close(id, { close_from: "close_button" });
}

onChangeVoiceServiceType(el) {
  if (el && el.value) {
    let value = el.value;
    this.selected_change_voice_service_type = value; //PhoneLine , ShipTrunk , Intercom
    if (value === "PhoneLine") {
      this.form3.get('didno').setValidators([Validators.required]);
      this.form3.get('didno').updateValueAndValidity();
      this.form3.get('childdidno').reset();
      this.form3.get('childdidno').clearValidators();
      this.form3.get('childdidno').updateValueAndValidity();
      this.form3.get('intercomno').reset();
      this.form3.get('intercomno').clearValidators();
      this.form3.get('intercomno').updateValueAndValidity();
      this.form3.get('intercomgrp').reset();
      this.form3.get('intercomgrp').clearValidators();
      this.form3.get('intercomgrp').updateValueAndValidity();
    } else if (value === "ShipTrunk") {
      this.form3.get('didno').setValidators([Validators.required]);
      this.form3.get('didno').updateValueAndValidity();
      this.form3.get('childdidno').setValidators([Validators.required]);
      this.form3.get('childdidno').updateValueAndValidity();
      this.form3.get('intercomno').reset();
      this.form3.get('intercomno').clearValidators();
      this.form3.get('intercomno').updateValueAndValidity();
      this.form3.get('intercomgrp').reset();
      this.form3.get('intercomgrp').clearValidators();
      this.form3.get('intercomgrp').updateValueAndValidity();

    }
    else if (value === "Intercom") {
      this.form3.get('intercomno').setValidators([Validators.required]);
      this.form3.get('intercomno').updateValueAndValidity();
      this.form3.get('intercomgrp').setValidators([Validators.required]);
      this.form3.get('intercomgrp').updateValueAndValidity();
      this.form3.get('didno').reset();
      this.form3.get('didno').clearValidators();
      this.form3.get('didno').updateValueAndValidity();
      this.form3.get('childdidno').reset();
      this.form3.get('childdidno').clearValidators();
      this.form3.get('childdidno').updateValueAndValidity();
    }
    else {
      this.form3.get('didno').reset();
      this.form3.get('didno').clearValidators();
      this.form3.get('didno').updateValueAndValidity();
      this.form3.get('didno').reset();
      this.form3.get('didno').clearValidators();
      this.form3.get('didno').updateValueAndValidity();
      this.form3.get('childdidno').reset();
      this.form3.get('childdidno').clearValidators();
      this.form3.get('childdidno').updateValueAndValidity();
      this.form3.get('intercomno').reset();
      this.form3.get('intercomno').clearValidators();
      this.form3.get('intercomno').updateValueAndValidity();
      this.form3.get('intercomgrp').reset();
      this.form3.get('intercomgrp').clearValidators();
      this.form3.get('intercomgrp').updateValueAndValidity();
    }
    ////console.log(value);
  } else {
    this.form3.get('didno').reset();
    this.form3.get('didno').clearValidators();
    this.form3.get('didno').updateValueAndValidity();
    this.form3.get('didno').reset();
    this.form3.get('didno').clearValidators();
    this.form3.get('didno').updateValueAndValidity();
    this.form3.get('childdidno').reset();
    this.form3.get('childdidno').clearValidators();
    this.form3.get('childdidno').updateValueAndValidity();
    this.form3.get('intercomno').reset();
    this.form3.get('intercomno').clearValidators();
    this.form3.get('intercomno').updateValueAndValidity();
    this.form3.get('intercomgrp').reset();
    this.form3.get('intercomgrp').clearValidators();
    this.form3.get('intercomgrp').updateValueAndValidity();
    this.selected_change_voice_service_type = '';
  }
}

validate(_: FormControl) {

  if (this.form.valid === true) {
    let formValue = this.form3.value;
    let voicesrvtype = formValue.voicesrvtype;
    if (voicesrvtype === '') {
      return { planServiceInformation: { valid: false } };
    } else {
      // phone line DID No *
      // intercom   intercom no and group
      // ship trunk  / DID No ,child did no
      if (voicesrvtype === "PhoneLine") {
        // if (formValue.didno.length === 0)  {
        //   this.isPhonelineDidNoValid = false;
        //   return { planServiceInformation: { valid: false } };
        // } else {
        //   this.isPhonelineDidNoValid = true;
        // }
      } else if (voicesrvtype === "ShipTrunk") {
        if (formValue.didno.length === 0) {
          this.shipTrunkDidNoValid = false;
        } else {
          this.shipTrunkDidNoValid = true;
        }
        if (formValue.childdidno.length === 0) {
          this.shipTrunkChildDidNoValid = false;
        } else {
          this.shipTrunkChildDidNoValid = true;
        }
        if (formValue.didno.length === 0) {
          return { planServiceInformation: { valid: false } };
        } else if (formValue.childdidno.length === 0) {
          return { planServiceInformation: { valid: false } };
        }
      } else if (voicesrvtype === "Intercom") {
        if (formValue.intercomno.length === 0) {
          this.intercomeNoValid = false;
        } else {
          this.intercomeNoValid = true;
        }
        if (formValue.intercomgrp.length === 0) {
          this.intercomGroupValid = false;
        } else {
          this.intercomGroupValid = true;
        }
        if (formValue.intercomno.length === 0) {
          return { planServiceInformation: { valid: false } };
        } else if (formValue.intercomgrp.length === 0) {
          return { planServiceInformation: { valid: false } };
        }
      }
    }
  } else if (this.form.valid === false) {
    return { planServiceInformation: { valid: false } };
  } else if (this.form.valid === false) {
    return { planServiceInformation: { valid: false } };
  } else {
    return null;
  }
}

changedSMS(event){
  if(event.target.checked){
    this.form3.get('smsenable').setValue(true);
  }else{
    this.form3.get('smsenable').setValue(false);
  }
}
changedEmail(event){
  if(event.target.checked){
    this.form3.get('emailenable').setValue(true);
  }else{
    this.form3.get('emailenable').setValue(false);
  }
}
changedVOIP(event){
  if(event.target.checked){
    this.form3.get('voipanble').setValue(true);
  }else{
    this.form3.get('voipanble').setValue(false);
  }
}

// changeInventory(event){
//   console.log('event =>',event);
//   if(event.target.checked){
//     this.form.get('allocateInventory').setValue(true);
//   }else{
//     this.form.get('allocateInventory').setValue(false);
//   }
// }

onChargeChange(charge) {
  this.hideLabelFlag=false;
  this.maxChargeAmount  =charge.maxamount;
  this.minChargeAmount  =charge.minamount;
  this.chargeDetails=charge;
  this.selectedchargeTaxId = charge.taxid

   if(charge.isPriceOverride===true){
    this.hideShowField=true;
    this.isPriceOverrideCharge=true;
    this.isPriceOverrideReadOnly=false;
    }else{
    this.hideShowField=false;
    this.isPriceOverrideCharge=false;
    this.isPriceOverrideReadOnly=true;
    }
  
    if(charge.isIncludeTax===true){
        this.isIncludeTaxflag=true;
      }else{
        this.isIncludeTaxflag=false;
    }
   
  this.overridePriceTaxWithTax=charge.price +charge.taxamount;
   if(charge.price && charge.taxamount){
     this.form4.get('overridePrice').setValue(this.overridePriceTaxWithTax)
      }else{
      this.form4.get('pricoverridePricee').setValue(null)
      }
   
    if (this.presentAddressStateChange && this.presentAddressStateChange.state_id) {
      let data: any = {
        "locationId": this.presentAddressStateChange.state_id,
        "chargeId": charge.id
      };
      // this.planService.getTaxDetailsByCharge(data).subscribe((result) => {
      //   this.taxDetailsByChargeData = result.TotalAmount;
      // });
    }
}

   clearChargeChange(){

    this.chargeDetails=null;
    this.chargeAmount=null;
    this.taxAmount=null;
    this.finalAmount=null;
    this.isPriceOverrideCharge=false;
    this.isIncludeTaxflag=false;
    this.form4.get('overridePrice').setValue(null);
    this.form4.get('isPriceOverride').setValue(false);
    this.form4.get('isIncludeTax').setValue(false);

  }

  changeOverridePlanPriceCharge(event){
        
    if(event==="true"){
      this.isPriceOverrideReadOnly=false;
      this.isPriceOverrideCharge=true;
      this.form4.get('isPriceOverride').setValue(true);
        
    }else{
      this.isPriceOverrideReadOnly=true;
      this.isPriceOverrideCharge=false;
      this.form4.get('isPriceOverride').setValue(false);
        
    }

  }

  isIncludeTaxChange(e){
    
    if(e==="true"){
       this.isIncludeTaxflag=true;
       this.form4.get('overridePrice').updateValueAndValidity();
       let currentValue= this.form4.get('overridePrice').value;
      
     if((this.minChargeAmount !== null) && (this.maxChargeAmount !==null) && currentValue !== null && this.isIncludeTaxflag===true){
          this.isIncludeTaxflag=true;    
          this.OnCheckAPICallTaxCalculation();
         }
       }else{
        let currentValue= this.form4.get('overridePrice').value;
          if((this.minChargeAmount !== null) && (this.maxChargeAmount !==null) && currentValue !== null &&  this.isIncludeTaxflag===false){
           this.onOverridePrice();
        }
       }
    }

    OnCheckAPICallTaxCalculation(){
    
      let AmountValue=this.form4.get('overridePrice').value;
      let obj = {
          amount: AmountValue,
          taxId: this.selectedchargeTaxId,
      };
      this.applyChargeService.getTaxByAmountOnChecked(obj).subscribe((res)=>{
        
      
        if(res.status===200){
  
          this.chargeAmount=res.ReverseTaxAmount;
          this.taxAmount= Number(AmountValue)-res.ReverseTaxAmount;
          this.finalAmount= Number(this.chargeAmount)+Number(this.taxAmount);
          this.hideLabelFlag=true;
          if(this.finalAmount < this.minChargeAmount && this.minChargeAmount !==null){
            this.toastrService.error("Price Override should not less than Min Amount  "  +this.minChargeAmount);
            this.form4.get('overridePrice').setErrors({'incorrect': true});
            return;
         }else if(this.finalAmount > this.maxChargeAmount && this.maxChargeAmount !==null)
         {
          this.toastrService.error("Price Override should not be greater than Max Amount  "+ this.maxChargeAmount);
          this.form4.get('overridePrice').setErrors({'incorrect': true});
          return;
         }
        }        
          })
  
    }

  onOverridePrice() {
      let AmountValue=this.form4.get('overridePrice').value;
      let obj = {
          amount: AmountValue,
          taxId: this.selectedchargeTaxId,
      };
  
    this.changePlanService.getTaxByAmount(obj).subscribe((res) => 
    {
    if(res.status===200){
     let latestFinalAmount=this.form4.get('overridePrice').value;
     
       if(this.isIncludeTaxflag=== false){
        this.chargeAmount=latestFinalAmount;
        this.taxAmount=Number(res.TaxAmount); 
        this.finalAmount= Number(latestFinalAmount) +Number(res.TaxAmount);
        this.hideLabelFlag=true;
      }
     
      if(this.finalAmount <= this.minChargeAmount && this.minChargeAmount !==null){
          this.toastrService.error("Price Override should not less than Min Amount  "  +this.minChargeAmount);
          this.form4.get('overridePrice').setErrors({'incorrect': true});
          return;
       }else if(this.finalAmount >= this.maxChargeAmount && this.maxChargeAmount !==null)
       {
        this.toastrService.error("Price Override should not be greater than Max Amount  "+ this.maxChargeAmount);
        this.form4.get('overridePrice').setErrors({'incorrect': true});
        return;
       }
       }
    }
    );
      
  }
  
  onClickAddChargeDetails(el) {
  
    if(this.isIncludeTaxflag===true){
    let AmountValue=this.form4.get('overridePrice').value;
       
    if(AmountValue < this.minChargeAmount && this.minChargeAmount !==null){
        this.toastrService.error("Price Override should not less than Min Amount  "  +this.minChargeAmount);
        this.form4.get('overridePrice').setErrors({'incorrect': true});
        return;
    }
    else if(AmountValue > this.maxChargeAmount && this.maxChargeAmount !==null)
    {
      this.toastrService.error("Price Override should not be greater than Max Amount  "+ this.maxChargeAmount);
      this.form4.get('overridePrice').setErrors({'incorrect': true});
      return;
    }
     }else{
      
      this.finalAmount= this.finalAmount ? this.finalAmount: this.form4.get('overridePrice').value
      if(this.finalAmount < this.minChargeAmount && this.minChargeAmount !==null){
        this.toastrService.error("Price Override should not less than Min Amount  "  +this.minChargeAmount);
        this.form4.get('overridePrice').setErrors({'incorrect': true});
        
        return;
        }
      else if(this.finalAmount > this.maxChargeAmount && this.maxChargeAmount !==null)
      {
        this.toastrService.error("Price Override should not be greater than Max Amount  "+ this.maxChargeAmount);
        this.form4.get('overridePrice').setErrors({'incorrect': true});
        return;
      }
     
     }

    let data = this.form4.value;

    if (data.charge_id && data.charge_date) {
      let selected_charge_details = _.find(this.chargeForCustomerList, { id: data.charge_id });
      this.taxDetailsByChargeData=selected_charge_details.taxamount;
      if (this.taxDetailsByChargeData === null) {
        this.taxDetailsByChargeData = 0;
      } 
      
      if(this.isIncludeTaxflag=== false){
      let rowData = {
          charge_type: selected_charge_details.chargecategory,
          charge_name: selected_charge_details.text,
          // offerprice: selected_charge_details.price + this.taxDetailsByChargeData,
          offerprice: this.finalAmount ? this.finalAmount : (selected_charge_details.price + this.taxDetailsByChargeData),
          actualprice: selected_charge_details.actualprice,
          charge_date: data.charge_date,
          chargeid: selected_charge_details.id,
         // overridePrice:selected_charge_details.overridePrice?selected_charge_details.overridePrice:null,
          overridePrice:this.form4.get('overridePrice').value ?this.form4.get('overridePrice').value:null,
          isPriceOverride:selected_charge_details.isPriceOverride,
          isIncludeTax:this.isIncludeTaxflag
          //selected_charge_details.isIncludeTax

        };
        this.addChargeDetailsList.push(rowData);
         this.finalAmount=null; 
         this.chargeAmount=null;
         this.taxAmount=null; 
         this.hideLabelFlag=false;
         this.form4.get('overridePrice').setValue(this.overridePriceTaxWithTax)
      }
      else if(this.isIncludeTaxflag===true){
        
        let rowData = {
          charge_type: selected_charge_details.chargecategory,
          charge_name: selected_charge_details.text,
          // offerprice: selected_charge_details.price + this.taxDetailsByChargeData,
          offerprice: this.form4.get('overridePrice').value,
          actualprice: selected_charge_details.actualprice,
          charge_date: data.charge_date,
          chargeid: selected_charge_details.id,
          overridePrice:this.form4.get('overridePrice').value ?this.form4.get('overridePrice').value:null,
          isPriceOverride:selected_charge_details.isPriceOverride,
          isIncludeTax:this.isIncludeTaxflag
          // selected_charge_details.isIncludeTax
        };
        this.addChargeDetailsList.push(rowData);
         this.finalAmount=null; 
         this.chargeAmount=null;
         this.taxAmount=null;
         this.hideLabelFlag=false;
        this.form4.get('overridePrice').setValue(this.overridePriceTaxWithTax)
      }
      
    } else {
      this.toastrService.error("Please Select Charge and Charge Date");
    }
  }

  onOverridePriceBlur(){
  
    if(this.form4.get('overridePrice').value !==null && this.isIncludeTaxflag===false){
      this.onOverridePrice();
    }else{
      this.OnCheckAPICallTaxCalculation();
    }
  }

  deleteSelectedChargeDetails(index) {
    this.addChargeDetailsList.splice(index, 1);
  }

  onChangeNetworkType(event){
    if(event && event.value == 'IPOE'){
     this.showIpcontrol=true;
     this.form5.get('select_ip').setValidators([Validators.required]);
     this.form5.get('select_ip').updateValueAndValidity();
    }
    else{
     this.showIpcontrol=false;
     this.form5.get('select_ip').clearValidators();
     this.form5.get('select_ip').updateValueAndValidity();
    }
   }
   
   openAllocationIpSelectPopupClick(content) {
    this.openAllocationIpSelectPopup = true;
    if(this.partnerId != null){
      this.ngbmodalservice.open(content, { ariaLabelledBy: 'modal-basic-title', size: 'xl' ,backdrop:'static'})
    }else{
      this.toastrService.error('Please Select Partner');
    }
  }

  
  closeAllocationIpSelectPopup(data) {
    if (data) {
      this.allocationIpSelectedData = data;
      this.allocationIpService.getippoolnamebyid(this.allocationIpSelectedData.selectedFreeIpData.poolId).subscribe((res) => {
        this.form5.get('poolId').setValue(this.allocationIpSelectedData.selectedIpPool.poolId)
        this.form5.get('ipPoolDtlsId').setValue(this.allocationIpSelectedData.selectedFreeIpData.poolDetailsId)
        this.form5.patchValue({ select_ip: this.allocationIpSelectedData.selectedFreeIpData.ipAddress });
      })
    }
    this.ngbmodalservice.dismissAll();
    this.openAllocationIpSelectPopup = false;
  }

  onChangeServiceAreaGetNetworkDeviceByServiceId(service_area) {
    if (service_area) {
      this.networkDeviceServices.getNetworkDeviceByServiceId(service_area.id).subscribe((result) => {
        this.networkDeviceList = result.dataList;
        this.snmpEnabled = null;
        this.form5.get('networkdevicesid').reset();
        this.form5.get("oltslotid").reset();
        this.form5.get("oltportid").reset();
      });
    } else {
      this.networkDeviceList = [];
      this.oltSlotListAllData = null;
      this.oltSlotList = [];
      this.oltPortList = [];
      this.form5.get('networkdevicesid').reset();
      this.form5.get('oltslotid').reset();
      this.form5.get('oltportid').reset();
      this.form5.get('olt').reset();
    }
  }

  onChangeOltGetOltSlotByNetworkDeviceId(network_device_data) {
    if (network_device_data) {
      this.networkDeviceServices.getOltSlotByNetworkDeviceId(network_device_data.id).subscribe((result) => {
        this.oltSlotListAllData = result;
        this.oltSlotList = result.dataList;
        this.oltPortList = [];
        this.snmpEnabled=network_device_data.snmpEnabled;
        this.form5.get('oltslotid').reset();
        this.form5.get('oltportid').reset();
      });
    } else {
      this.oltSlotListAllData = null;
      this.oltSlotList = [];
      this.oltPortList = [];
      this.form5.get('oltslotid').reset();
      this.form5.get('oltportid').reset();
    }
  }

  onChangeOltSlot(olt_slot_data) {
    if (olt_slot_data) {
      let oltportid = [];
      this.oltSlotListAllData.dataList.forEach(element => {
        if (element.id === olt_slot_data.id) {
          this.oltPortList = element.oltPortDetailsList;
          this.form5.get('oltportid').reset();
        }
      });
    } else {
      this.oltPortList = [];
      this.form5.get('oltportid').reset();
    }
  }

  onChangeConnectionType(event){
    this.form5.get('routerType').reset();
    if(event.value==='Router' && this.RouterType.isMandatory===true){
      this.form5.get('routerType').setValidators(Validators.required);
      this.form5.get('routerType').updateValueAndValidity();
    }
    else if(event.value!=='Router'){
      this.form5.get('routerType').clearValidators();
      this.form5.get('routerType').updateValueAndValidity();
    }
    if((event.value==='Free' || event==='Free')){
      this.form5.get('approvedBy').setValidators(Validators.required);
      this.form5.get('reason').setValidators(Validators.required);
      this.form5.get('approvedBy').updateValueAndValidity();
      this.form5.get('reason').updateValueAndValidity();
    }
    else if(event.value!=='Free' || event!=='Free'){
      this.form5.get('approvedBy').reset();
      this.form5.get('approvedBy').clearValidators();
      this.form5.get('reason').reset();
      this.form5.get('reason').clearValidators();
      this.form5.get('approvedBy').updateValueAndValidity();
      this.form5.get('reason').updateValueAndValidity();
    }
  }

  onCLearConnectionType(){
    this.form5.get('routerType').reset();
    this.form5.get('routerType').clearValidators();
    this.form5.get('approvedBy').reset();
    this.form5.get('approvedBy').clearValidators();
    this.form5.get('reason').reset();
    this.form5.get('reason').clearValidators();
    this.form5.get('routerType').updateValueAndValidity();
    this.form5.get('approvedBy').updateValueAndValidity();
    this.form5.get('reason').updateValueAndValidity();
  }

// plan selection new screen pop-up

  getTableColumn() {
    let self = this;
    this.allColumns = [
      {
        colKey: 'index',
        default: 'ID',
        rowKey: 'id',
        width: '3%',
        isFilterAllow: false,
        isNumber: true,
      },
      {
        colKey: 'planname',
        default: 'Plan Name',
        rowKey: 'planname',
        width: '20%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'plandetails',
        default: 'Plan Details',
        rowKey: 'plandetails',
        width: '20%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'offerprice',
        default: 'Price (incl. Tax)',
        rowKey: 'offerprice',
        width: '20%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'associatedVAS',
        default: 'Associated VAS',
        rowKey: 'associatedVAS',
        width: '20%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'actionCol',
        default: 'Action',
        rowKey: 'id',
        enableEdit: false,
        enableDelete: false,
        isFilterAllow: false,
        enableSort: false,
        isExpandable: false,
        isNumber: false,
        width: '1%',
        isStickyEnd: true,
      },
    ];

    this.allVasColumns = [
      {
        colKey: 'index',
        default: 'ID',
        rowKey: 'id',
        width: '3%',
        isFilterAllow: false,
        isNumber: true,
      },
      {
        colKey: 'type',
        default: 'Type',
        rowKey: 'type',
        width: '20%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'name',
        default: 'Name',
        rowKey: 'name',
        width: '20%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      },
      {
        colKey: 'validity',
        default: 'Validity (Days)',
        rowKey: 'validity',
        width: '20%',
        isNumber: false,
        isFilterAllow: true,
        enableEdit: true,
        enableDelete: true,
        enableSort: true,
      }
    ];

    this.allColumns.forEach(element => {
      element.isFilterAllow = false;
    });

    this.allVasColumns.forEach(element => {
      element.isFilterAllow = false;
    });
    this.getTableSetting();
    this.getTableSearchSetting();  
  }

  getTableSetting()
  {
    this.neoGridSettings = {
      showAdvanceSearch: false,
      showFooter: false,
      showPagination: true,
      showFilter: false,
      tableId: this.tableKeyEnum.PLAN_TBL_FOR_SUBSCRIBER,
      perPage: this.paginationData.rowsPerPage,
      pageNumber:this.paginationData.pageNumber - 1,
      pageSizeOptions: [5, 10, 15, 20, 50, 100],
      serverSideSortPagination: true,
      pageTotalLength: 0,
      sort: true,
      defaultSortCol: '',
      defaultSortDirection: 'asc',
      // enableMultiDelete: true,
      columns: this.allColumns,
      deleteMsgBoxTitle: 'Are you sure to delete this Country ?',
      deleteMsgBoxDetail: 'Country Name :${country_name} ',
    };
    this.neoGridSettingsForVAS = {
      showAdvanceSearch: false,
      showFooter: false,
      showPagination: true,
      showFilter: false,
      tableId: this.tableKeyEnum.PLAN_TBL_FOR_SUBSCRIBER_VAS,
      perPage: this.paginationData.rowsPerPage,
      pageNumber:this.paginationData.pageNumber - 1,
      pageSizeOptions: [5, 10, 15, 20, 50, 100],
      serverSideSortPagination: true,
      pageTotalLength: 0,
      sort: true,
      defaultSortCol: '',
      defaultSortDirection: 'asc',
      // enableMultiDelete: true,
      columns: this.allVasColumns,
      deleteMsgBoxTitle: 'Are you sure to delete this Country ?',
      deleteMsgBoxDetail: 'Country Name :${country_name} ',
    };
  }

  getTableSearchSetting()
  {
    this.searchSettings = [
      {
        display_field: 'Country Name',
        value_field: 'country_name',
        control_setting: [
          {
            control_type: SearchControlTypeEnum.TEXT,
            display_style: '',
          },
        ],
        data_type: SearchDataTypeEnum.STRING,
      },
      {
        display_field: 'Status',
        value_field: 'status',
        control_setting: [
          {
            control_type: SearchControlTypeEnum.SELECT,
            display_style: '',
          },
        ],
        data_type: SearchDataTypeEnum.BOOLEAN,
        select_items: [
          {
            display_field: 'All',
            value_field: ['Y', 'N'],
          },
          {
            display_field: 'True',
            value_field: 'Y',
          },
          {
            display_field: 'False',
            value_field: 'N',
          },
        ],
      },
    ];
  }

  ngAfterViewInit() {
    this.allColumns.forEach((col) => {
      if (col.colKey == 'actionCol') {
        col.customControls = [this.actionBtnRef];
      }
      if(col.colKey == 'associatedVAS'){
        col.customControls = [this.associatedVAS];
      }
      if(col.colKey == 'offerprice'){
        col.customControls = [this.priceRef];
      }
    });
  }

  openPlanPopUp(content,el)
  {
    if(el == 'planPopUpField'){
      this.addPlanList = [];
      this.vasprice = 0;
      this.isSellPrice = false;
      this.isIncludeTax = true;
      this.PlanDetailForm.reset();
    }
    this.NEXG_VAS = [];
    this.VAS_OTT = [];
    this.VAS_IPTV = [];
    setTimeout(() => {
      this.ngbRef = this.ngbModalService.open(content, { size: 'lg',backdrop:'static'});
    }, 100);
  }

  closeModal() {
    this.PlanDetailForm.reset();
    this.ngbModalService.dismissAll();
   }

   onDelete(data) {
    let temp = this.addPlanList.findIndex(res => res.planname === data.planname)
    this.addPlanList.splice(temp, 1);
    this.selectedVASIds = [];
    this.vasprice = 0;
    this.planDtl = [];
    this.isSellPrice = false;
    this.refreshDataSource(this.addPlanList);
  }

  onChangeBoxType(event){
    if(event){
      if(event.value == 'Android TV'){
        this.form6.get('iptvMacAddress').clearValidators();
        this.form6.get('iptvMacAddress').updateValueAndValidity();
        this.form6.get('iptvEthMacAddress').clearValidators();
        this.form6.get('iptvEthMacAddress').updateValueAndValidity();
        this.form6.get('iptvSerialNo').clearValidators();
        this.form6.get('iptvSerialNo').updateValueAndValidity();
        this.form6.get('iptvId').setValidators(Validators.required);
        this.form6.updateValueAndValidity();
      }else{
        this.form6.get('iptvMacAddress').setValidators(Validators.required);
        this.form6.get('iptvEthMacAddress').setValidators(Validators.required);
        this.form6.get('iptvSerialNo').setValidators(Validators.required);
        this.form6.get('iptvId').clearValidators();
        this.form6.get('iptvId').updateValueAndValidity();
        this.form6.updateValueAndValidity();
      }
    }else{
      this.form6.get('iptvId').clearValidators();
      this.form6.get('iptvId').updateValueAndValidity();
      this.form6.get('iptvMacAddress').clearValidators();
      this.form6.get('iptvMacAddress').updateValueAndValidity();
      this.form6.get('iptvEthMacAddress').clearValidators();
      this.form6.get('iptvEthMacAddress').updateValueAndValidity();
      this.form6.get('iptvSerialNo').clearValidators();
      this.form6.get('iptvSerialNo').updateValueAndValidity();
      this.form6.updateValueAndValidity();
    }
  }

  totalCalc(){
    if(this.isAllowVAS){
      return (Number(this.PlanDetailForm.get('sellPrice').value) + Number(this.vasprice)).toFixed(2);
    }else{
      return (Number(this.PlanDetailForm.get('sellPrice').value)).toFixed(2);
    }
  }
}