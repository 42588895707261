import { SearchFilterConditionEnum, SearchFilterTypeValueEnum } from "./searchType.enum";

export const DynamicPojoData = [
    {
        "moduleName": "Subscriber",
        "dynamicFormData": {
            "paramName": "Name",
            "paramType": "text",
            "paramKey": 'name',
            "filterDataType": "string",
            "BindlableKey": null,
            "BindlableVaule": null,
            "filterconditionsList": [
                {
                    "key": SearchFilterTypeValueEnum.IS_EQUAL_TO,
                    "value": "Equal To"
                },
                {
                    "key": SearchFilterTypeValueEnum.IS_NOT_EQUAL_TO,
                    "value": "Not Equal"
                },
                {
                    "key": SearchFilterTypeValueEnum.CONTAINS,
                    "value": "Contains"
                },
                {
                    "key": SearchFilterTypeValueEnum.STARTS_WITH,
                    "value": "Starts With"
                }
            ]
        }
    },
    {
        "moduleName": "Subscriber",
        "dynamicFormData": {
            "paramName": "Username",
            "paramType": "text",
            "paramKey": 'username',
            "BindlableKey": null,
            "BindlableVaule": null,
            "filterconditionsList": [
                {
                    "key": SearchFilterTypeValueEnum.IS_EQUAL_TO,
                    "value": "Equal To"
                },
                {
                    "key": SearchFilterTypeValueEnum.IS_NOT_EQUAL_TO,
                    "value": "Not Equal"
                },
                {
                    "key": SearchFilterTypeValueEnum.CONTAINS,
                    "value": "Contains"
                },
                {
                    "key": SearchFilterTypeValueEnum.STARTS_WITH,
                    "value": "Starts With"
                }
            ]
        }
    },
    {
        "moduleName": "Subscriber",
        "dynamicFormData": {
            "paramName": "Email",
            "paramType": "text",
            "paramKey": 'email',
            "BindlableKey": null,
            "BindlableVaule": null,
            "filterconditionsList": [
                {
                    "key": SearchFilterTypeValueEnum.IS_EQUAL_TO,
                    "value": "Equal To"
                },
                {
                    "key": SearchFilterTypeValueEnum.IS_NOT_EQUAL_TO,
                    "value": "Not Equal"
                },
                {
                    "key": SearchFilterTypeValueEnum.CONTAINS,
                    "value": "Contains"
                },
                {
                    "key": SearchFilterTypeValueEnum.STARTS_WITH,
                    "value": "Starts With"
                }
            ]
        }
    },
    {
        "moduleName": "Subscriber",
        "dynamicFormData": {
            "paramName": "Mobile",
            "paramType": "text",
            "paramKey": 'mobile',
            "BindlableKey": null,
            "BindlableVaule": null,
            "filterconditionsList": [
                {
                    "key": SearchFilterTypeValueEnum.IS_EQUAL_TO,
                    "value": "Equal To"
                },
                {
                    "key": SearchFilterTypeValueEnum.IS_NOT_EQUAL_TO,
                    "value": "Not Equal"
                },
                {
                    "key": SearchFilterTypeValueEnum.CONTAINS,
                    "value": "Contains"
                },
                {
                    "key": SearchFilterTypeValueEnum.STARTS_WITH,
                    "value": "Starts With"
                }
            ]
        }
    },
    {
        "moduleName": "Subscriber",
        "dynamicFormData": {
            "paramName": "Address",
            "paramType": "text",
            "paramKey": 'address',
            "BindlableKey": null,
            "BindlableVaule": null,
            "filterconditionsList": [
                {
                    "key": SearchFilterTypeValueEnum.IS_EQUAL_TO,
                    "value": "Equal To"
                },
                {
                    "key": SearchFilterTypeValueEnum.CONTAINS,
                    "value": "Contains"
                },
                {
                    "key": SearchFilterTypeValueEnum.STARTS_WITH,
                    "value": "Starts With"
                }
            ]
        }
    },
    {
        "moduleName": "Subscriber",
        "dynamicFormData": {
            "paramName": "Account No",
            "paramType": "text",
            "paramKey": 'acctno',
            "BindlableKey": null,
            "BindlableVaule": null,
            "filterconditionsList": [
                {
                    "key": SearchFilterTypeValueEnum.IS_EQUAL_TO,
                    "value": "Equal To"
                },
                {
                    "key": SearchFilterTypeValueEnum.IS_NOT_EQUAL_TO,
                    "value": "Not Equal"
                },
                {
                    "key": SearchFilterTypeValueEnum.CONTAINS,
                    "value": "Contains"
                },
                {
                    "key": SearchFilterTypeValueEnum.STARTS_WITH,
                    "value": "Starts With"
                }
            ]
        }
    },
    {
        "moduleName": "Subscriber",
        "dynamicFormData": {
            "paramName": "Partner",
            "paramType": "ddl",
            "paramKey": 'partner',
            "BindlableKey": "name",
            "BindlableVaule": "id",
            "api": "partner/all",
            "apiList":true,
            "filterconditionsList": [
                {
                    "key": SearchFilterTypeValueEnum.IS_EQUAL_TO,
                    "value": "Equal To"
                },
                {
                    "key": SearchFilterTypeValueEnum.IS_NOT_EQUAL_TO,
                    "value": "Not Equal"
                },
                {
                    "key": SearchFilterTypeValueEnum.CONTAINS,
                    "value": "Contains"
                },
                {
                    "key": SearchFilterTypeValueEnum.STARTS_WITH,
                    "value": "Starts With"
                }
            ]
        }
    },
    {
        "moduleName": "Subscriber",
        "dynamicFormData": {
            "paramName": "Status",
            "paramType": "ddl",
            "paramKey": 'status',
            "BindlableKey": "text",
            "BindlableVaule": "value",
            "api": "commonList/generic/custStatus",
            "apiList":true,
            "filterconditionsList": [
                {
                    "key": SearchFilterTypeValueEnum.IS_EQUAL_TO,
                    "value": "Equal To"
                },
                {
                    "key": SearchFilterTypeValueEnum.IS_NOT_EQUAL_TO,
                    "value": "Not Equal"
                }
            ]
        }
    },
    {
        "moduleName": "Subscriber",
        "dynamicFormData": {
            "paramName": "Plan",
            "paramType": "text",
            "paramKey": 'plan',
            "BindlableKey": null,
            "BindlableVaule": null,
            "filterconditionsList": [
                {
                    "key": SearchFilterTypeValueEnum.IS_EQUAL_TO,
                    "value": "Equal To"
                },
                {
                    "key": SearchFilterTypeValueEnum.IS_NOT_EQUAL_TO,
                    "value": "Not Equal"
                },
                {
                    "key": SearchFilterTypeValueEnum.CONTAINS,
                    "value": "Contains"
                },
                {
                    "key": SearchFilterTypeValueEnum.STARTS_WITH,
                    "value": "Starts With"
                }
            ]
        }
    },
    {
        "moduleName": "Subscriber",
        "dynamicFormData": {
            "paramName": "Expiry Date",
            "paramType": "date",
            "paramKey": "expirydate",
            "BindlableKey": null,
            "BindlableVaule": null,
            "filterconditionsList": [
                {
                    "key": SearchFilterTypeValueEnum.GREATER_THAN,
                    "value": "Greater Than"
                },
                {
                    "key": SearchFilterTypeValueEnum.IS_EQUAL_TO,
                    "value": "Equal To"
                },
                {
                    "key": SearchFilterTypeValueEnum.LESS_THAN,
                    "value": "Less Than"
                },
                {
                    "key": SearchFilterTypeValueEnum.BETWEEN,
                    "value": "Between"
                }
            ]
        }
    },
    {
        "moduleName": "Subscriber",
        "dynamicFormData": {
            "paramName": "Mac Address",
            "paramType": "text",
            "paramKey": 'macaddress',
            "BindlableKey": null,
            "BindlableVaule": null,
            "api": "api/v1/customers/list",
            "filterconditionsList": [
                {
                    "key": SearchFilterTypeValueEnum.CONTAINS,
                    "value": "Contains"
                }
            ]
        }
    },
    {
        "moduleName": "Subscriber",
        "dynamicFormData": {
            "paramName": "Did No",
            "paramType": "text",
            "paramKey": 'didno',
            "BindlableKey": null,
            "BindlableVaule": null,
            "filterconditionsList": [
                {
                    "key": SearchFilterTypeValueEnum.IS_EQUAL_TO,
                    "value": "Equal To"
                },
                {
                    "key": SearchFilterTypeValueEnum.IS_NOT_EQUAL_TO,
                    "value": "Not Equal"
                },
                {
                    "key": SearchFilterTypeValueEnum.CONTAINS,
                    "value": "Contains"
                },
                {
                    "key": SearchFilterTypeValueEnum.STARTS_WITH,
                    "value": "Starts With"
                }
            ]
        }
    },
    {
        "moduleName": "Subscriber",
        "dynamicFormData": {
            "paramName": "Ip Address",
            "paramType": "text",
            "paramKey": 'ipaddress',
            "BindlableKey": null,
            "BindlableVaule": null,
            "filterconditionsList": [
                {
                    "key": SearchFilterTypeValueEnum.IS_EQUAL_TO,
                    "value": "Equal To"
                },
                {
                    "key": SearchFilterTypeValueEnum.IS_NOT_EQUAL_TO,
                    "value": "Not Equal"
                },
                {
                    "key": SearchFilterTypeValueEnum.CONTAINS,
                    "value": "Contains"
                },
                {
                    "key": SearchFilterTypeValueEnum.STARTS_WITH,
                    "value": "Starts With"
                }
            ]
        }
    },
    {
        "moduleName": "Subscriber",
        "dynamicFormData": {
            "paramName": "Service Area",
            "paramType": "ddl",
            "paramKey": 'serviceArea',
            "BindlableKey": "name",
            "BindlableVaule": "id",
            "api": "serviceArea/all",
            "apiList":true,
            "filterconditionsList": [
                {
                    "key": SearchFilterTypeValueEnum.IS_EQUAL_TO,
                    "value": "Equal To"
                },
                {
                    "key": SearchFilterTypeValueEnum.IS_NOT_EQUAL_TO,
                    "value": "Not Equal"
                },
                {
                    "key": SearchFilterTypeValueEnum.CONTAINS,
                    "value": "Contains"
                },
                {
                    "key": SearchFilterTypeValueEnum.STARTS_WITH,
                    "value": "Starts With"
                }
            ]
        }
    },
    {
        "moduleName": "Subscriber",
        "dynamicFormData": {
            "paramName": "Onu Id",
            "paramType": "text",
            "paramKey": 'onuid',
            "BindlableKey": null,
            "BindlableVaule": null,
            "filterconditionsList": [
                {
                    "key": SearchFilterTypeValueEnum.IS_EQUAL_TO,
                    "value": "Equal To"
                },
                {
                    "key": SearchFilterTypeValueEnum.IS_NOT_EQUAL_TO,
                    "value": "Not Equal"
                },
                {
                    "key": SearchFilterTypeValueEnum.CONTAINS,
                    "value": "Contains"
                },
                {
                    "key": SearchFilterTypeValueEnum.STARTS_WITH,
                    "value": "Starts With"
                }
            ]
        }
    },
    {
        "moduleName": "Subscriber",
        "dynamicFormData": {
            "paramName": "Sales Representative",
            "paramType": "ddl",
            "paramKey": 'salesRepresentative',
            "BindlableKey": "fullName",
            "BindlableVaule": "id",
            "api": "staffuserByRole/6",
            "apiList":true,
            "filterconditionsList": [
                {
                    "key": SearchFilterTypeValueEnum.IS_EQUAL_TO,
                    "value": "Equal To"
                },
                {
                    "key": SearchFilterTypeValueEnum.IS_NOT_EQUAL_TO,
                    "value": "Not Equal"
                },
                {
                    "key": SearchFilterTypeValueEnum.CONTAINS,
                    "value": "Contains"
                },
                {
                    "key": SearchFilterTypeValueEnum.STARTS_WITH,
                    "value": "Starts With"
                }
            ]
        }
    },
    {
        "moduleName": "Case",
        "dynamicFormData": {
            "moduleName": "Case",
            "paramName": "Case Title",
            "paramType": "text",
            "paramKey": "casetitle",
            "BindlableKey": null,
            "BindlableVaule": null,
            "filterconditionsList": [
                {
                    "key": SearchFilterTypeValueEnum.IS_EQUAL_TO,
                    "value": "Equal To"
                },
                {
                    "key": SearchFilterTypeValueEnum.IS_NOT_EQUAL_TO,
                    "value": "Not Equal"
                },
                {
                    "key": SearchFilterTypeValueEnum.CONTAINS,
                    "value": "Contains"
                },
                {
                    "key": SearchFilterTypeValueEnum.STARTS_WITH,
                    "value": "Starts With"
                }
            ]
        }
    },
    {
        "moduleName": "Case",
        "dynamicFormData": {
            "moduleName": "Case",
            "paramName": "Case No",
            "paramType": "text",
            "paramKey": "caseNumber",
            "BindlableKey": null,
            "BindlableVaule": null,
            "filterconditionsList": [
                {
                    "key": SearchFilterTypeValueEnum.IS_EQUAL_TO,
                    "value": "Equal To"
                },
                {
                    "key": SearchFilterTypeValueEnum.IS_NOT_EQUAL_TO,
                    "value": "Not Equal"
                },
                {
                    "key": SearchFilterTypeValueEnum.CONTAINS,
                    "value": "Contains"
                },
                {
                    "key": SearchFilterTypeValueEnum.STARTS_WITH,
                    "value": "Starts With"
                }
            ]
        }
    },
    {
        "moduleName": "Case",
        "dynamicFormData": {
            "moduleName": "Case",
            "paramName": "Customer Status",
            "paramType": "ddl",
            "paramKey": "custStatus",
            "BindlableKey": "text",
            "BindlableVaule": "value",
            "api": "staticList",
            "apiList":false,
            "filterconditionsList": [
                {
                    "key": SearchFilterTypeValueEnum.IS_EQUAL_TO,
                    "value": "Equal To"
                }
            ]
        }
    },
    {
        "moduleName": "Case",
        "dynamicFormData": {
            "moduleName": "Case",
            "paramName": "Current Assignee Name",
            "paramType": "ddl",
            "paramKey": "currentAssigneeName",
            "BindlableKey": "fullName",
            "BindlableVaule": "id",
            "api": "staffuserByRole/7",
            "apiList":true,
            "filterconditionsList": [
                {
                    "key": SearchFilterTypeValueEnum.IS_EQUAL_TO,
                    "value": "Equal To"
                },
                {
                    "key": SearchFilterTypeValueEnum.IS_NOT_EQUAL_TO,
                    "value": "Not Equal"
                }
            ]
        }
    },
    {
        "moduleName": "Case",
        "dynamicFormData": {
            "moduleName": "Case",
            "paramName": "Customer Name",
            "paramType": "text",
            "paramKey": "name",
            "BindlableKey": null,
            "BindlableVaule": null,
            "filterconditionsList": [
                {
                    "key": SearchFilterTypeValueEnum.IS_EQUAL_TO,
                    "value": "Equal To"
                },
                {
                    "key": SearchFilterTypeValueEnum.IS_NOT_EQUAL_TO,
                    "value": "Not Equal"
                },
                {
                    "key": SearchFilterTypeValueEnum.CONTAINS,
                    "value": "Contains"
                },
                {
                    "key": SearchFilterTypeValueEnum.STARTS_WITH,
                    "value": "Starts With"
                }
            ]
        }
    },
    {
        "moduleName": "Case",
        "dynamicFormData": {
            "moduleName": "Case",
            "paramName": "Contact No",
            "paramType": "text",
            "paramKey": "mobile",
            "BindlableKey": null,
            "BindlableVaule": null,
            "filterconditionsList": [
                {
                    "key": SearchFilterTypeValueEnum.IS_EQUAL_TO,
                    "value": "Equal To"
                },
                {
                    "key": SearchFilterTypeValueEnum.IS_NOT_EQUAL_TO,
                    "value": "Not Equal"
                },
                {
                    "key": SearchFilterTypeValueEnum.CONTAINS,
                    "value": "Contains"
                },
                {
                    "key": SearchFilterTypeValueEnum.STARTS_WITH,
                    "value": "Starts With"
                }
            ]
        }
    },
    {
        "moduleName": "Case",
        "dynamicFormData": {
            "moduleName": "Case",
            "paramName": "Username",
            "paramType": "text",
            "paramKey": "userName",
            "BindlableKey": null,
            "BindlableVaule": null,
            "filterconditionsList": [
                {
                    "key": SearchFilterTypeValueEnum.IS_EQUAL_TO,
                    "value": "Equal To"
                },
                {
                    "key": SearchFilterTypeValueEnum.IS_NOT_EQUAL_TO,
                    "value": "Not Equal"
                },
                {
                    "key": SearchFilterTypeValueEnum.CONTAINS,
                    "value": "Contains"
                },
                {
                    "key": SearchFilterTypeValueEnum.STARTS_WITH,
                    "value": "Starts With"
                }
            ]
        }
    },
    {
        "moduleName": "Case",
        "dynamicFormData": {
            "paramName": "Olt",
            "paramType": "ddl",
            "paramKey": 'olt',
            "BindlableKey": "name",
            "BindlableVaule": "id",
            "api": "NetworkDevice/getAllOltDevice",
            "apiList":true,
            "filterconditionsList": [
                {
                    "key": SearchFilterTypeValueEnum.IS_EQUAL_TO,
                    "value": "Equal To"
                },
                {
                    "key": SearchFilterTypeValueEnum.IS_NOT_EQUAL_TO,
                    "value": "Not Equal"
                }
            ]
        }
    },
    {
        "moduleName": "Case",
        "dynamicFormData": {
            "paramName": "Created By",
            "paramType": "ddl",
            "paramKey": 'createdBy',
            "BindlableKey": "fullName",
            "BindlableVaule": "id",
            "api": "staffuserByLoginPartner",
            "apiList":true,
            "filterconditionsList": [
                {
                    "key": SearchFilterTypeValueEnum.IS_EQUAL_TO,
                    "value": "Equal To"
                },
                {
                    "key": SearchFilterTypeValueEnum.IS_NOT_EQUAL_TO,
                    "value": "Not Equal"
                }
            ]
        }
    },
]
