import { Component, ContentChild, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatTableDataSource } from '@angular/material';
import { Subscription } from 'rxjs';
import { TableKeyEnum } from '../../../../../core/enum/table-key.enum';
import { TroubleTicketsDetailsService } from './trouble-tickets-details.service';
import { ViewChild, TemplateRef } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Router , ActivatedRoute } from '@angular/router';
import { SubscriberDetailsComponent } from '../../subscriber-details.component';
import { AclClassConstants } from '../../../../../pages/aclClassConstants';
import { AclConstants } from '../../../../../pages/aclOperationConstants';
import { LoginService } from '../../../../../pages/login/login.service';
import { Updatelistmodel } from '../../../../../pages/customer-care/cases/model/updatelistmodel';
import { CommonListServicesService } from '../../../../../services/common_list_service/common-list-services.service';
import { CasesModel } from '../../../../../pages/customer-care/cases/model/cases-model';
import { CasesService } from '../../../../../pages/customer-care/cases/service/cases.service';
import { DatePipe } from '@angular/common';
declare let $;
@Component({
  selector: 'app-trouble-tickets-details',
  templateUrl: './trouble-tickets-details.component.html',
  styleUrls: ['./trouble-tickets-details.component.scss']
})
export class TroubleTicketsDetailsComponent implements OnInit {
  @ViewChild('cancelWrapper', { static: false }) cancelWrapperRef: TemplateRef<any>;
  @ViewChild('editCases', { static: false }) editCasesReff: TemplateRef<any>;
  // @ViewChild(SubscriberDetailsComponent, { static: false }) subscriberdetailReff: SubscriberDetailsComponent;
  // @ContentChild(SubscriberDetailsComponent, {static: false}) subscriberdetailReff: SubscriberDetailsComponent;
  listTemp: any = [];
  QuickCaseFlag:string;
  @Input() custId: any;
  @Input() name:any;
  @Output() onCaseRefresh = new EventEmitter<any>();
  tableKeyEnum = TableKeyEnum;
  allColumns: any[] = [];
  dataSource = new MatTableDataSource([]);
  ticketDetailsNeoGridSettings = {};
  closeCaseData: any;
  subscriptions: Subscription[] = [];
  remark: any = null;
  editviewId:any;
  id:any;
  ticketDetailsTableData: any;
  customerName: any;
  AclClassConstants = AclClassConstants;
  AclConstants = AclConstants;
  public loginService: LoginService;
  updateList:Updatelistmodel;
  entity: CasesModel

  constructor(
    private troubleTicketsDetailsService: TroubleTicketsDetailsService,
    private ngbModalService: NgbModal,
    private toaster: ToastrService,
    private router: Router,
    private ngbService:NgbModal,
    private route : ActivatedRoute,
    loginService: LoginService,
    private CommonListService : CommonListServicesService,
    private caseService : CasesService,
    private datePipe: DatePipe,
  ) { 
    this.loginService = loginService;
  }

  ngOnInit() {
    if(this.caseService)
      this.caseService.Internalcase = false;
    this.id = this.route.snapshot.paramMap.get('id');
    this.setGridSetting();
    this.getTroubleTicketsDetailsData();
  }
  ngAfterViewInit() {
    this.allColumns.forEach((col) => {
      if (col.colKey === 'action') {
        col.customControls = [this.cancelWrapperRef];
      }
      if (col.colKey === 'caseNumber') {
        col.customControls = [this.editCasesReff];
      }
    });
  }
  
  setGridSetting() {
    let self = this;
    this.allColumns = [
      {
        colKey: 'caseNumber',
        default: 'Ticket No',
        rowKey: 'caseNumber',
        width: '10%',
        isFilterAllow: false,
        isNumber: false,
      },
      // {
      //   colKey: 'currentAssigneeName',
      //   default: 'Contact Person',
      //   rowKey: 'currentAssigneeName',
      //   width: '10%',
      //   isNumber: false,
      //   isFilterAllow: false,
      // },
      {
        colKey: 'reason',
        default: 'Reason',
        rowKey: 'reason',
        width: '10%',
        isNumber: false,
        isFilterAllow: false,
      },
      {
        colKey: 'caseStatus',
        default: 'Status',
        rowKey: 'caseStatus',
        width: '10%',
        isNumber: false,
        isFilterAllow: false,
      },

      {
        colKey: 'createdate',
        default: 'Date',
        rowKey: 'createdate',
        width: '10%',
        isNumber: false,
        isFilterAllow: false,
      },
      // {
      //   colKey: 'finalResolutionDate',
      //   default: 'Resolution Date',
      //   rowKey: 'finalResolutionDate',
      //   width: '10%',
      //   isNumber: false,
      //   isFilterAllow: false,
      // },
      // {
      //   colKey: 'finalResolvedByName',
      //   default: 'Resloved By',
      //   rowKey: 'finalResolvedByName',
      //   width: '10%',
      //   isNumber: false,
      //   isFilterAllow: false,
      // },
      // {
      //   colKey: 'finalResolutionName',
      //   default: 'Resolution Given',
      //   rowKey: 'finalResolutionName',
      //   width: '10%',
      //   isNumber: false,
      //   isFilterAllow: false,
      // },
      {
        colKey: 'createdByName',
        default: 'Created By',
        rowKey: 'createdByName',
        width: '10%',
        isNumber: false,
        isFilterAllow: false,
      },
      {
        colKey: 'action',
        default: 'Action',
        rowKey: 'action',
        width: '13%',
        isNumber: false,
        isFilterAllow: false,
      },
      // {
      //   colKey: 'caseTitle',
      //   default: 'Title',
      //   rowKey: 'caseTitle',
      //   width: '10%',
      //   isNumber: false,
      //   isFilterAllow: false,
      // }
    ];

    this.ticketDetailsNeoGridSettings = {
      showAdvanceSearch: false,
      showFooter: false,
      showPagination: true,
      tableId: this.tableKeyEnum.TROUBLETICKETSDETAILS,
      perPage: 5,
      pageSizeOptions: [5, 10, 15, 20, 50, 100],
      sort: true,
      defaultSortCol: '',
      defaultSortDirection: 'asc',
      columns: this.allColumns
    };
  }
  openCase(trouble, data) {
    this.remark = null;
    this.closeCaseData = data;
    this.ngbModalService.open(trouble, { size: 'lg' });
  }
  onEdit(event,editviewmode) {
    this.editviewId =  event.caseId
    this.ngbService.open(editviewmode, { size: 'xl' });
    //this.onEditCaseModule(event);
  }
  onEditCaseModule(event) {
    this.router.navigate(['/customer-care/cases/add-cases'], { queryParams: { id: event.caseId } });
  }

  refreshData(event) {
    this.entity = event;
    this.updateFormSubmit();
  }
  

  saveCase() {
    let caseData: any = {};
    if (this.remark != null) {
      let ticketId = this.closeCaseData.caseUpdateList[0].ticketId;
      let caseStatus = 'Resolved'
      caseData.remark = this.remark;
      caseData.ticketId = ticketId;
      caseData.status = caseStatus;
      caseData.remarkType = 'external'
      this.troubleTicketsDetailsService.updateData(caseData).subscribe(res => {
        if (res.responseCode !== 200) {
          this.toaster.error(res.responseMessage);
        } else {
          this.toaster.success("Data has been Successfully saved");
          this.getTroubleTicketsDetailsData();
          this.ngbModalService.dismissAll();
        }
      })
    } else {
      this.toaster.error("Please Enter remark");
    }
  }

  openUpdate(update,data) {
    this.caseService.getById(data.caseId).subscribe((res)=>{
      if(res.responseCode == 200){
        this.entity = res.data;
        this.updateList = new Updatelistmodel();
     this.CommonListService.getGenericRemarkType("remarkType").subscribe((res) => {
       res.dataList.forEach((x) => {
         if (x.value === "external") {
           this.updateList.remarkType = x.text;
         }
       });
       this.ngbService.open(update, { size: 'lg' });
     });
      }
    })
     
   }

   openPrivateUpdate(privateUpdate,data) {
    this.caseService.getById(data.caseId).subscribe((res)=>{
      if(res.responseCode == 200){
        this.entity = res.data;
        this.updateList = new Updatelistmodel();
     this.CommonListService.getGenericRemarkType("remarkType").subscribe((res) => {
       res.dataList.forEach((x) => {
         if (x.value === "internal") {
           this.updateList.remarkType = x.text;
         }
       });
       this.ngbService.open(privateUpdate, { size: 'lg' });
     });
      }
    })
     
   }

   getCaseById(id){
    
  }

  refreshPage(data){
    this.updateFormSubmit()
  }

  updateFormSubmit() {
    this.updateList.resolutionId = this.entity.finalResolutionId;
    this.updateList.nextFollowupDate = this.datePipe.transform(this.entity.nextFollowupDate, 'yyyy-MM-dd');
    if(typeof(this.entity.nextFollowupTime) == 'object'){
      this.updateList.nextFollowupTime = this.datePipe.transform(this.entity.nextFollowupTime,'hh:mm:ss')
    }
    else{
      this.updateList.nextFollowupTime = this.entity.nextFollowupTime
    }
    this.updateList.suspendDate = this.datePipe.transform(this.entity.suspendDate, 'yyyy-MM-dd');
    this.updateList.filename = "abc"

    this.updateList.ticketId = this.entity.caseId;
    this.updateList.reasonId = this.entity.caseReasonId;
    this.updateList.status = this.entity.caseStatus;
    this.updateList.priority = this.entity.priority;
    this.updateList.commentBy = 'Staff';
    this.updateList.bucketType = this.entity.bucketType;
    this.updateList.title = this.entity.caseTitle;
    this.updateList.groupId = this.entity.groupId; 
    this.updateList.teamId = this.entity.teamId;
    this.updateList.serviceAreaId = this.entity.serviceAreaId;
    this.updateList.assignee = this.entity.currentAssigneeId;
    this.updateList.teamType = this.entity.teamType;
    this.updateList.allocationType = this.entity.allocationType
    this.updateList.algorithmType = this.entity.algorithmType;
    this.caseService.updateList(this.updateList).subscribe((res) => {
      if(res.responseCode == 200){
        //this.entity = res.data;
        this.toaster.success('Case Update Successfully')
        this.getTroubleTicketsDetailsData();
      }
      else{
        this.toaster.error(res.responseMessage)
      }

    });
    this.ngbService.dismissAll();
  }
 
  refreshDataSource(data) {
    if (data) {
      this.dataSource = new MatTableDataSource(JSON.parse(JSON.stringify(data)));
    }
  }

  onQuickCasePopup(caseDetail) {
    
    this.QuickCaseFlag = 'Customer';
   
    this.listTemp = ['name', 'username', 'phone', 'email'];
    
    this.ngbService.open(caseDetail);
   
  }

  onCancel(){
    this.ngbService.dismissAll();
  }

  getlist(){
  
    //this.getList(this.paginationData, this.orderingData, this.searchData);
    this.getTroubleTicketsDetailsData();
  }

  getTroubleTicketsDetailsData() {
    this.troubleTicketsDetailsService.getTroubleTicketsDetailsList(this.custId).subscribe(result => {
      
      this.ticketDetailsTableData = result.dataList;
      this.refreshDataSource(this.ticketDetailsTableData);
      this.customerName = this.name;
      this.onCaseRefresh.emit(result.data);
      return
    });
    
  }

}
