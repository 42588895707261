import { BroadcastPortModel } from "./BroadcastPortModel";

export class BroadcastModel {
    id:number;
    type:string;
    priority:string;
    templateid:number;
    customer_id:number;
    emailsubject:string;
    custstatus:string;
    custcondition:string;
    planid:number;
    serviceareaid:number;
    networkdeviceid:number;
    slotid:number;
    broadcastPortsList:BroadcastPortModel[]=[];
    expiry_condition:string;
    expirydate1:Date;
    expirydate2:Date;
    expirywithin:number;
    email_body:string
    email_subject:string;
    sms_body:string
    pushNotificationBody:string
    partnerId:number;
    snmp_slot:string;
    snmp_port:Array<any>;
    templateName:string;
    serviceAreaName:string;
    networkDeviceName:string;
    slotName:string;
    portNames:string;
    partnerSpecific:string;
    broadcastParamList:Array<Broadcastparam>
    
    constructor(){
        this.type="";
        this.custcondition="";
        this.customer_id=null;
        this.emailsubject="";
    }
}
export class Broadcastparam{
    paramKey:string;
    paramValue:string;
}


