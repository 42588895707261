import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { CommentModel } from '../model/comment-model';
import { BaseService } from '../../../../../services/base-service';
import { RequestData } from '../../../../../core/modal/base-save-update-modal';

  const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  }
  
  const BASE_API_URL = '';
  
  @Injectable({
    providedIn: 'root'
  })
  export class CommentService extends BaseService<CommentModel> {
  
    constructor(http: HttpClient) {
      super(http);
    }
    
    getModuleName(): string {
      return 'comment';
    }
  
    validate(): boolean {
      return false;
    }
    
    save(data: any): Observable<any> {
      const post_url = BASE_API_URL + 'comment/save';
      return this.http.post<any>(post_url, data, httpOptions).pipe(
        map(data => data),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  
    }
    delete(data: any): Observable<any> {
      const post_url = BASE_API_URL + 'comment/delete';
      return this.http.post<any>(post_url, data, httpOptions).pipe(
        map(data => data),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  
    }
  }
  