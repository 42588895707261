import { Component, forwardRef, Input, EventEmitter, OnInit, AfterViewInit, ViewChild, Output } from '@angular/core';
import { AbstractControl, ControlValueAccessor, FormControl, FormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { MessageService } from '../../../../services/message_service/message_service.service';

import { CityService } from '../../city/city.service';
import { CountryService } from '../../country/country.service';
import { StateService } from '../../state/state.service';
import { PaymentModeService } from '../../../../services/payment_mode/payment_mode.service';
import { TitleService } from '../../../../services/title/title.service';
import { HostListener } from '@angular/core';
import { PubSubService } from '../../../../services/pubsub/pubsub.service';
import { FranchiesService } from '../../../masters/franchies/franchies.service';
declare let $: any;

import { CustomModalService } from '../../../../components/custom-modal';
import { AddMactalComponent } from '../add-mactal/add-mactal.component';
import { AddSubscriberDetailsService } from '../add-subscriber-details.service';
import _ from 'underscore';
import { StaffService } from '../../../../pages/hr/staff/staff.service';
import { YearsCalendarViewModel } from 'ngx-bootstrap/datepicker/models';
import { ToastrService } from 'ngx-toastr';
import { LoginService } from '../../../login/login.service';
import { AclClassConstants } from '../../../aclClassConstants';
import { AclConstants } from '../../../aclOperationConstants';
import { AppConfig } from '../../../../../../src/app/app.config';
import { TokenStorageService } from '../../../../auth/token-storage-service';
import { NgSelectComponent } from '@ng-select/ng-select';
import { PaymentCollectionService } from '../../../reports/payment-collection-report/service/payment-collection.service';
import { RecentRenewalService } from '../../../reports/recent-renewal.service';
import { OtherDetailsComponent } from '../other-details/other-details.component';
import { PlanServiceComponent } from '../plan-service/plan-service.component';
import { CommonListServicesService } from '../../../../services/common_list_service/common-list-services.service';


@Component({
  selector: 'app-primary-information',
  templateUrl: './primary-information.component.html',
  styleUrls: ['./primary-information.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PrimaryInformationComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => PrimaryInformationComponent),
      multi: true
    }
  ]
})
export class PrimaryInformationComponent implements ControlValueAccessor, OnInit, AfterViewInit {
  @Output() tempPartnerId = new EventEmitter();
  @Output() stateId = new EventEmitter();
  @ViewChild(OtherDetailsComponent, { static: false }) OtherDetailsComponent: OtherDetailsComponent;
  @ViewChild(PlanServiceComponent, { static: false }) planServiceComponent: PlanServiceComponent;
  paymentRecievedValue: any;
  paymentRecievedFlage: boolean = false;
  paymentRequiredFlage:boolean = false;
  AclClassConstants = AclClassConstants;
  AclConstants = AclConstants;
  subscriptions: Subscription[] = [];
  collectorList: any[] = [];
  subscription: Subscription;
  submitted: boolean;
  partnerId: number;
  aadhaar_OTP:number;
  Gst:any;
  req_message = "This field is required.";
  selected_payment_mode_value: any;
  enableMactal: boolean = true;
  enableVlan: boolean = false;
  enableAutoGenPass: boolean = false;
  invalidUsernameAsPerApi: boolean = true;
  invalidUsernameAsPerApiMsg: string = '';
  invalidEmailAsPerApi: boolean = false;
  invalidEmailAsPerApiMsg: string = '';
  public CustomerList: string;
  autoField: any = ['name', 'username']
  custId:number;
  custname:string;
  invalidCafNoAsPerApi: boolean = false;
  invalidCafNoAsPerApiMsg: string = '';
  invalidMobileAsPerApi: boolean = false;
  invalidMobileAsPerApiMsg: string = '';
  mactalLabel: string = '';
  userName: any;
  partnerFlag: any;
  mobile: any;
  propertyTypeList
  client_id :string
  default_pincode_value:string;
  imagePath:string;
  backendImageUrl:string;
  isVerifybtn:boolean = true;
  mobileOtp: any = null;
  aadharverified:boolean = false;
  mobileverified:boolean = false;
  parentpin:any;
  TypeOfProperty:any;
  Area:any;
  @ViewChild('select',{static: false}) select: NgSelectComponent;
  @ViewChild(AddMactalComponent, { static: false })
  private addMactalComponent: AddMactalComponent;
  block_House_No:any;
  property_Type:any;
  dynamicrequiredArray: any;
  firstname:any;
  middlename:any;
  lastname:any;
  caf_no: any;
  customerSourceList:[]
  email: any;
   customer_source: { isMandatory: boolean; displayname:boolean }
  usernameOfReferredByFriendOrRelative:{ isMandatory: boolean; displayname:boolean }
  alt_mobile:any;
  sales_representative_id:any
  searchPincodeLink: string = '';
  addressMapArray:any;
  addressMapArray2:any;
  addressMapArray3:any;
  buildingList: [];
  typeOfPropertyList:any;
  parentId: number;
  commonYN:any
  public areaAllList: any[] = [
    {
      name: '',
      id: '',
    },
  ];

  public pincodeAllList: any[] = [
    {
      pincode: '',
      pincodeid: '',
    },
  ];

  public countryList: any[] = [
    {
      country_name: '',
      country_id: '',
    },
  ];
  public stateList: any[] = [
    {
      state_name: '',
      state_id: '',
    },
  ];
  public cityList: any[] = [
    {
      city_name: '',
      city_id: '',
    },
  ];
  public paymentModeList: any[] = [
    {
      payment_mode_text: '',
      payment_mode_value: '',
    },
  ];
  public titleList: any[] = [
    {
      text: '',
      value: '',
    },
  ];

  public salesRepresentativeList: any[] = [
    {
      fullname: '',
      id: '',
    },
  ];

franchiesList: any;
buildingHouseName:any;
isAadhaarbtn:boolean= true;
isImage:boolean=false;
  insertPrimaryInformationForm = new FormGroup({
    title: new FormControl(null, [Validators.required]),
    firstname: new FormControl(null),
    middlename: new FormControl(null),
    lastname: new FormControl(null),
    contact_person: new FormControl(''),
    gst: new FormControl(''),
    gstenabled: new FormControl(null, [Validators.required]),
    pan: new FormControl(''),
    aadhar: new FormControl(''),
    caf_no: new FormControl(null),
    username: new FormControl('', [Validators.required, this.cannotContainSpace,Validators.pattern('^[a-zA-Z0-9_]+$')]),
    enable_mactal: new FormControl(false),
    mobile_no: new FormControl(null),
    telephone: new FormControl('', Validators.pattern('^[0-9]{10}$')),
    email: new FormControl(null),
    customerSource:new FormControl(null),
    referralName:new FormControl(null),
    custId:new FormControl(null),
    alternate_mobile_no: new FormControl(null),
    alternate_telephone_no: new FormControl('', Validators.pattern('^[0-9]{10}$')),
    alternate_email: new FormControl('', Validators.email),
    fax: new FormControl(''),
    address: new FormControl('', [Validators.required]),
    landmark: new FormControl(null),
    area: new FormControl(null),
    temppincode: new FormControl(''),
    pincode: new FormControl('', [Validators.required]),
    Country: new FormControl(null, [Validators.required]),
    State: new FormControl(null, [Validators.required]),
    City: new FormControl(null,[Validators.required]),
    typeOfProperty: new FormControl(null,[Validators.required]),
    District: new FormControl(null),
    Taluka: new FormControl(null),
    Constituency: new FormControl(null),
    block: new FormControl(null),
    building: new FormControl(null),
    countryId: new FormControl(null),
    stateId: new FormControl(null),
    cityId: new FormControl(null),
    districtId: new FormControl(null),
    talukaId: new FormControl(null),
    constituencyId: new FormControl(null),
    partnerId: new FormControl(null, [Validators.required]),
    payment_received: new FormControl(null),
    payment_date: new FormControl(''),
    payment_mode: new FormControl(null),
    vlanFlag:new FormControl(false),
    autoGeneratePassword:new FormControl(false),
    sales_representative_id: new FormControl(null),
    cheque_no: new FormControl(''),
    cheque_date: new FormControl(''),
    bank_name: new FormControl(''),
    bank_branch: new FormControl(''),
    payment_reference_no: new FormControl(''),
    imagePath: new FormControl(null),
    imageName: new FormControl(null),
    propertyType:new FormControl(null),
    collectorid:new FormControl(null)

  });

  get value(): PrimaryInformationComponent {
    return this.insertPrimaryInformationForm.value;
  }

  set value(value: PrimaryInformationComponent) {
    this.insertPrimaryInformationForm.setValue(value);
    this.onChange(value);
    //this.onTouched(value);
  }

  cannotContainSpace(control: AbstractControl) : ValidationErrors | null {
    if(control && control.value && (control.value as string).indexOf(' ') >= 0){
        return {cannotContainSpace: true}
    }

    return null;
}

  constructor(
    private messageService: MessageService,
    private countryService: CountryService,
    private stateService: StateService,
    private cityService: CityService,
    
    private paymentModeService: PaymentModeService,
    private titleService: TitleService,
    private pubsub: PubSubService,
    private staffService: StaffService,
    private franchiesService: FranchiesService,
    private customModalService: CustomModalService,
    private tosterService:ToastrService,
    public loginService: LoginService,
    private addSubscriberDetailsService: AddSubscriberDetailsService,
    private appConfig: AppConfig,
    private tokenservice: TokenStorageService,
    private recentRenewalService: RecentRenewalService,
    private commonListService: CommonListServicesService,
    //private entityService: PaymentCollectionService,
  ) {
    this.getRequiredAllField();
    this.subscriptions.push(
      this.insertPrimaryInformationForm.valueChanges.subscribe(value => {
        this.onChange(value);
        this.onTouched(value);
      }));
    this.backendImageUrl=this.appConfig.config.serverUrl;
    this.pubsub.$sub('get_present_address_for_permanent_address_details').subscribe((data) => {
      this.pubsub.$pub('receive_present_address_for_permanent_address_details', this.insertPrimaryInformationForm.value);
    });

    this.pubsub.$sub('get_present_address_for_payment_address_details').subscribe((data) => {
      this.pubsub.$pub('receive_present_address_for_payment_address_details', this.insertPrimaryInformationForm.value);
    });
  }

  ngOnInit() {
     this.pubsub.$sub('primary_information_validation_fail').subscribe((data) => {
      this.submitted = true;
      this.validateAllFields(this.insertPrimaryInformationForm);
    });

    this.clearErrorMsg();
    this.getCountryList();
    this.getPaymentMode();
    this.getTitle();
    //this.getSalesRepresentativeList();
    this.getFranchiesList();
    // this.getPincodeAll();
    this.getSubscriberCustomer()
    this.getAddressMapping();
    this.getPincode();
    this.getcommonList();
    this.getcommonYN();
    this.getCustomerSourceList();
   

    //  this.getAreaAll();
    this.partnerFlag = localStorage.getItem('partnerFlag');
    
  }

  setFocusOnFirstElement(elementName: string, timeoutTime: number){
    setTimeout(()=>{
      if(!this.insertPrimaryInformationForm.get('title').value)
      this.select.open();
    },timeoutTime)
  }

  getcommonYN(){
    this.commonListService.getCommonListForYN().subscribe((res)=>{
      this.commonYN=res.dataList
          });
  }

  getcommonList(){
    this.addSubscriberDetailsService.getCommonListPropertyType().subscribe((res)=>{
      this.propertyTypeList = res.dataList;
    })
  }
  VerifyOtp(id:string){
    if(this.aadhaar_OTP && this.client_id){
      let obj = {
        "client_id": this.client_id,
        "mobile_number" : "",
         "otp" : this.aadhaar_OTP
      }
      this.addSubscriberDetailsService.verifyOtpAadhaar(obj).subscribe((res)=>{
       if(res.responseCode !== 200){
        this.tosterService.error(res.responseMessage)
       }
       else{
         this.insertPrimaryInformationForm.get('address').setValue(res.data.address);
         this.insertPrimaryInformationForm.get('firstname').setValue(res.data.full_name);
         this.insertPrimaryInformationForm.get('landmark').setValue(res.data.landmark);
         this.insertPrimaryInformationForm.get('pincode').setValue(res.data.pincode);
         this.insertPrimaryInformationForm.get('imagePath').setValue(res.data.imagePath);
         this.insertPrimaryInformationForm.get('imageName').setValue(res.data.imageName);
         this.imagePath= this.backendImageUrl + 'alticonImages/CustAadhaarImage/' + res.data.imageName;
         this.isImage=true;
        //  this.insertPrimaryInformationForm.get('mobile_no').setValue(res.data.mobile);
        //  this.insertPrimaryInformationForm.get('email').setValue(res.data.email);
        //console.log('--',this.insertPrimaryInformationForm.get('pincode').setValue(res.data.pincode));
        this.default_pincode_value = res.data.pincode;
         this.aadharverified = true;
        this.tosterService.success("Aadhar Verrified Successfully")
        this.customModalService.close(id, { close_from: "close_button" });
       }
      
      });

    }
    else{
      this.tosterService.error("Please Enter OTP")
    }
  }
  paymentRecieved(data)
  {
    this.paymentRecievedValue = data.target.value;
    if(this.paymentRecievedValue && this.paymentRecievedValue != "")
    {
      this.paymentRecievedFlage = true;
      this.insertPrimaryInformationForm.get('payment_date').setValidators(Validators.required)
      this.insertPrimaryInformationForm.get('payment_date').updateValueAndValidity();
      this.insertPrimaryInformationForm.get('payment_mode').setValidators(Validators.required)
      this.insertPrimaryInformationForm.get('payment_mode').updateValueAndValidity();
      this.insertPrimaryInformationForm.get('payment_reference_no').setValidators(Validators.required)
      this.insertPrimaryInformationForm.get('payment_reference_no').updateValueAndValidity();
    }
    else if(this.paymentRecievedValue == ""){
      this.paymentRecievedFlage = false;
      this.insertPrimaryInformationForm.get('payment_date').clearValidators()
      this.insertPrimaryInformationForm.get('payment_date').updateValueAndValidity();
      this.insertPrimaryInformationForm.get('payment_mode').clearValidators()
      this.insertPrimaryInformationForm.get('payment_mode').updateValueAndValidity();
      this.insertPrimaryInformationForm.get('payment_reference_no').clearValidators()
      this.insertPrimaryInformationForm.get('payment_reference_no').updateValueAndValidity();

    }
  }

  getCustomerSourceList() {
    this.addSubscriberDetailsService.getCustomerSource().subscribe((res) => {
      this.customerSourceList = res.dataList;
      //Default Selected

    });
  }
  
  keypress(event: any) {  
    const pattern = /[0-9\.]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && event.keyCode != 9 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }
  CloseVerify(id:string){
    this.customModalService.close(id, { close_from: "close_button" });
  }
  getPincode() {
    this.searchPincodeLink = 'address/searchNew?id=' + this.parentpin + '&s=';
  }


  @HostListener('window:keydown', ['$event'])
  SpaceNotAllowed(ctrlValue: any) {
    if (ctrlValue.target.type == 'email' && ctrlValue.which === 32) {
      ctrlValue.preventDefault();
    }
    if (ctrlValue.target.selectionStart === 0 && ctrlValue.code === "Space") {
      ctrlValue.preventDefault();
    }
  }

  onAadhaarInput(event){
    if(event && event.length >= 12){
     this.isAadhaarbtn = false
    }
    else
    {
  this.isAadhaarbtn =  true
    }

  }

  numberOnlyInput(event)
  {
    if(event && event.length != 10){
      this.isVerifybtn = true
     }
     else
     {
      this.isVerifybtn =  false
     }
  }

  VerifiedOTP() {
    if (this.mobileOtp) {
      this.addSubscriberDetailsService.OTPverifiedServiceAtSave(this.insertPrimaryInformationForm.get('mobile_no').value, this.mobileOtp).subscribe(res => {
        if (res.responseCode === 200) {
          if (res.data === 'success') {
            this.mobileOtp = null;
            this.tosterService.success(res.responseMessage);
            this.mobileverified =  true;
            this.customModalService.close('mobile_verification');
          }
        } else {
          this.tosterService.error(res.responseMessage);
        }
      })
    } else {
      this.tosterService.error("OTP is required");
    }
  }
  spaceCheck(value){
    return value.indexOf(' ') >= 0;
  }
  SpaceNotAllowedUserName(ctrlValue: any) {
    if (ctrlValue.which === 32 || ctrlValue.code === "Space") {
      ctrlValue.preventDefault();
    }
  }

  numberOnly(event): boolean {

    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

resendOtp(id:string){
  this.addSubscriberDetailsService.MobileVerification(this.insertPrimaryInformationForm.get('mobile_no').value).subscribe(res => {
    if (res.responseCode !== 200) {
      this.tosterService.error(res.responseMessage )
    }
    else{
      this.tosterService.success('OTP sent successfully')
    }
  });
  this.customModalService.open(id)
}
onMobileVerify(verified?){
  this.addSubscriberDetailsService.MobileVerification(this.insertPrimaryInformationForm.get('mobile_no').value).subscribe(res => {
    if (res.responseCode !== 200) {
      this.tosterService.error(res.responseMessage);
    } else {
      if (res.data == 'success') {
        this.setFocusOnHtmlElements('name',100);
        this.tosterService.success('OTP sent successfully')
        if(verified){
          this.customModalService.open(verified);
        }
      }
    }
  })
}

setFocusOnHtmlElements(elementName, timeoutTime){
  let el = document.getElementById(elementName);
  setTimeout(()=>{
    if(el)
      el.focus();
  }, timeoutTime);
}

getAadharOTP(id?){
  if(id){
    this.setFocusOnHtmlElements('otp',100);
    this.customModalService.open(id);
  }
  this.addSubscriberDetailsService.getAadhaarOTP(this.insertPrimaryInformationForm.get('aadhar').value).subscribe((res)=>{
    ////console.log("Aadhaar otp res",res);
    this.aadhaar_OTP = null
    if(res.responseCode !== 200){
      if(res.responseMessage==null){
        this.tosterService.error("Try again later")
      }
      else{
        this.tosterService.error(res.responseMessage)
      }
    }
    else{
      this.client_id = res.data.client_id;
      this.tosterService.success("OTP Sent Successfully")
    }
  });
}
  clearMessage() {
    
    if (this.insertPrimaryInformationForm.controls['mobile_no'].invalid &&
      (this.insertPrimaryInformationForm.controls['mobile_no'].dirty ||
        this.insertPrimaryInformationForm.controls['mobile_no'].touched)) {
      if (this.insertPrimaryInformationForm.controls['mobile_no'].errors.pattern) {
        this.invalidMobileAsPerApiMsg='';
      }
    }
    if (this.insertPrimaryInformationForm.controls['email'].invalid &&
      (this.insertPrimaryInformationForm.controls['email'].dirty ||
        this.insertPrimaryInformationForm.controls['email'].touched)) {
      if (this.insertPrimaryInformationForm.controls['email'].errors.email) {
        this.invalidEmailAsPerApiMsg='';
      }
    }
  }
  getMactalList() {
    return this.addMactalComponent.getMactalListData();
  }

  getIsInvalidUsernameAsPerApi() {
    return this.invalidUsernameAsPerApi;
  }

  getIsInvalidEmailAsPerApi() {
    return this.invalidEmailAsPerApi;
  }

  getIsInvalidCafNoAsPerApi() {
    return this.invalidCafNoAsPerApi;
  }

  getIsInvalidMobileAsPerApi() {
    return this.invalidMobileAsPerApi;
  }

  closeAddMactal(data: any) {
    let mactalList = this.getMactalList();

    if (mactalList && mactalList.length == 1) {
      this.mactalLabel = mactalList[0].macAddress;
    } else if (mactalList && mactalList.length > 1) {
      this.mactalLabel = mactalList[0].macAddress + "...";
    } else {
      this.mactalLabel = '';
    }
  }
  

  ngAfterViewInit() {
    //////console.log("after view init")
  }

  openAddMactalPopup(id: string) {
    this.addMactalComponent.setFocusOnMacText();
    this.customModalService.open(id);
  }

  getFranchiesList() {
    let partnerId = localStorage.getItem('partnerId')
    let isPartner = localStorage.getItem('partnerFlag')
    if(isPartner == 'false'){
      this.franchiesService.getFranchiesList().subscribe(result => {
        this.franchiesList = Object.assign([],result.content);
      });
    }
    else{
      this.franchiesService.getManagablePartnerlistService().subscribe(result => {
        this.franchiesList = Object.assign([],result.content);
        this.partnerId = Number(partnerId);
      })
    }

    setTimeout(() => {
      // console.log('aaaa',this.franchiesList)
      if(this.franchiesList){
        if(this.franchiesList.length == 1){
          this.insertPrimaryInformationForm.get('partnerId').setValue(Number(partnerId))
          //this.insertPrimaryInformationForm.get('partnerId').disable()
        }
        this.tempPartnerId.emit(this.partnerId);
      }
    }, 250);
    
    this.getSaleRespresentativedata(localStorage.partnerId,6)
  }

  // getSalesRepresentativeList() {
  //   let role_id = 6
  //   this.staffService.getSalesRepresentativeList(role_id).subscribe(result => {
  //     this.salesRepresentativeList = result.content;
  //   });
  // }

  getTitle() {
    this.titleService.getTitleList().subscribe((result) => {
      this.titleList = result.dataList;
    });
  }

  getPaymentMode() {
    this.paymentModeService.getPaymentModeList().subscribe((result) => {
      this.paymentModeList = result.content;
    });
  }

  // getAreaAll() {
  //   this.addSubscriberDetailsService.getAreaAll().subscribe(result => {
  //     this.areaAllList = result.dataList;
  //   });
  // }

  onAreaChange(el) {
    //////console.log(el);
  }

  // getPincodeAll() {
  //   this.addSubscriberDetailsService.getPincodeAll().subscribe(result => {
  //     this.pincodeAllList = result.dataList;
  //   });
  // }

  spaceNotAllow(event) {
    if (event.which == 32) {
      // alertify.error("Space Not Allowed");
      return true;
    }
  }
  // getPincodeAllAndSetValue(pincode_no?: any, area?: any) {
  
  //   if (pincode_no) {
  //     this.default_pincode_value = pincode_no;

  //     this.addSubscriberDetailsService.getDetailsByPincode(pincode_no).subscribe(result => {

  //       let data = result.data;
  //       if (data) {
  //         if (data.country && data.country.id) {
  //           this.insertPrimaryInformationForm.patchValue({ countryId: data.country.id });
  //         }
  //         if (data.state && data.state.id) {
  //           this.getStateListByCountryIdAndSetState(data.country.id, data.state.id);
  //         }
  //         if (data.city && data.city.id) {
  //           this.getCityListByStateIdAndSetState(data.state.id, data.city.id);
  //         }
  //         if (data.pincode && data.pincode.id) {
  //           this.insertPrimaryInformationForm.patchValue({ pincode: data.pincode.id });
  //         }

  //         if (data.areaList) {
  //           this.areaAllList = data.areaList;
  //           if (data.areaList.length === 1) {
  //             let area = data.areaList[0];
  //             this.insertPrimaryInformationForm.patchValue({ area: area.id });
  //           } else {
  //             this.insertPrimaryInformationForm.patchValue({ area: null });
  //           }
  //         }
  //       }
  //     });
  //   }
  // }

  onChangeArea(event){
    this.typeOfPropertyList = [];
    this.buildingList = [];
    this.insertPrimaryInformationForm.get('typeOfProperty').reset();
    this.insertPrimaryInformationForm.get('typeOfProperty').updateValueAndValidity();
    this.insertPrimaryInformationForm.get('building').reset();
    this.insertPrimaryInformationForm.get('building').updateValueAndValidity();
    if(event && event.id){
      this.getPropertyType();
    }
 
  }

  onChangePropertyTypeBuilding(event){
    this.insertPrimaryInformationForm.get('building').reset();
    this.insertPrimaryInformationForm.get('building').updateValueAndValidity();
    let area = this.insertPrimaryInformationForm.get('area').value;
    this.buildingList = []
    if(event && event.value){
      this.addSubscriberDetailsService.getDetailsByProperty(area, event.value).subscribe(res=>{
        this.buildingList = res.dataList
      })
    }
  }

  getPropertyType(){
   this.commonListService.getCommonListtypeOfProperty('typeOfProperty').subscribe(res=>{
      this.typeOfPropertyList = res.dataList
    })
  }

  

  getAddressMapping(){
    let temp = this.tokenservice.getAddressLevel()
    this.addressMapArray = temp.filter(res=> res.name.toLowerCase()!='pincode' && res.name.toLowerCase()!='area' && res.name.toLowerCase()!='building')
    this.addressMapArray2 = temp.filter(res=> (res.name.toLowerCase() == 'area' ))
    this.addressMapArray3 = temp.filter(res=> (res.name.toLowerCase() == 'building' ))
    let tempn = temp.filter(res=> (res.name.toLowerCase() == 'pincode' ))
    this.parentpin = tempn[0].levelNo
    if(this.addressMapArray.find(res=> res.name.toLowerCase()=='district')){
      this.insertPrimaryInformationForm.get('District').setValidators([Validators.required]);
      this.insertPrimaryInformationForm.get('District').updateValueAndValidity();
    }
    if(this.addressMapArray.find(res=> res.name.toLowerCase()=='taluka')){
      this.insertPrimaryInformationForm.get('Taluka').setValidators([Validators.required]);
      this.insertPrimaryInformationForm.get('Taluka').updateValueAndValidity();
    }
    if(this.addressMapArray.find(res=> res.name.toLowerCase()=='constituency')){
      this.insertPrimaryInformationForm.get('Constituency').setValidators([Validators.required]);
      this.insertPrimaryInformationForm.get('Constituency').updateValueAndValidity();
    }
  }

  onPincodeChange(elItem) {
    this.insertPrimaryInformationForm.get('area').reset();
    this.insertPrimaryInformationForm.get('typeOfProperty').reset();
    this.insertPrimaryInformationForm.get('building').reset();
    //this.buildingList = [];
    let el;
    let call_from_autocomplete;
    if (_.isArray(elItem.event) == true) {
      el = _.first(elItem.event);
    } else {
      el = elItem.event;
      call_from_autocomplete = elItem.call_from_autocomplete;
    }
    if (el ) {

      this.insertPrimaryInformationForm.controls.pincode.setValue(el.addressDetails.pincodeId);
      this.insertPrimaryInformationForm.controls.temppincode.setValue(el.addressDetails.pincode);
      // this.insertPrimaryInformationForm.get('stateId').setValue(el.addressDetails.state)
        this.insertPrimaryInformationForm.patchValue({ Country: el.addressDetails.country});
        this.insertPrimaryInformationForm.patchValue({ State: el.addressDetails.state});
        this.insertPrimaryInformationForm.patchValue({ City: el.addressDetails.city});
        this.insertPrimaryInformationForm.patchValue({ District: el.addressDetails.district});
        this.insertPrimaryInformationForm.patchValue({ Taluka: el.addressDetails.taluka});
        this.insertPrimaryInformationForm.patchValue({ Constituency: el.addressDetails.constituency});
        this.insertPrimaryInformationForm.patchValue({ countryId: el.addressDetails.countryId});
        this.insertPrimaryInformationForm.patchValue({ stateId: el.addressDetails.stateId});
        this.stateId.emit(el.addressDetails.stateId);
        this.insertPrimaryInformationForm.patchValue({ cityId: el.addressDetails.cityId});
        this.insertPrimaryInformationForm.patchValue({ districtId: el.addressDetails.districtId});
        this.insertPrimaryInformationForm.patchValue({ talukaId: el.addressDetails.talukaId});
        this.insertPrimaryInformationForm.patchValue({ constituencyId: el.addressDetails.constituencyId});
        for (const prop in this.insertPrimaryInformationForm.controls) {
          this.insertPrimaryInformationForm.value[prop] = this.insertPrimaryInformationForm.controls[prop].value;
        }
        this.parentId = el.parentId;
        this.addSubscriberDetailsService.getDetailsByPincode(el.parentId).subscribe(res=>{
          this.areaAllList = res.dataList;
        })
    }
    else if(el == null){
      this.insertPrimaryInformationForm.reset();
      this.areaAllList = [];
    }
  }
  getCountryList() {
    this.countryService.getCountryList().subscribe((result) => {
      this.countryList = result.content;
    });
  }

  // onCountryChange($event) {
  //   if ($event) {
  //     this.getStateListByCountryId($event.country_id);
  //   } else {
  //     this.stateList = [];
  //     this.insertPrimaryInformationForm.get('stateId').patchValue([]);
  //   }
  // }

  // onStateChange($event) {
  //   if ($event) {
  //     this.getCityListByStateId($event.state_id);
  //     this.pubsub.$pub('present_address_state_change', $event);
  //   } else {
  //     this.cityList = [];
  //     this.insertPrimaryInformationForm.get('cityId').patchValue([]);
  //   }
  // }

  onCountryClear() {
    this.stateList = [];
    this.insertPrimaryInformationForm.get('stateId').patchValue([]);
  }

  // getStateListByCountryId(country_id: any) {
  //   this.stateService.getStateListByCountryId(country_id).subscribe((result) => {
  //     this.stateList = result.content;
  //   });
  // }

  // getStateListByCountryIdAndSetState(country_id: any, state_id?: any) {
  //   this.stateService.getStateListByCountryId(country_id).subscribe((result) => {
  //     this.stateList = result.content;
  //     if (state_id) {
  //       this.insertPrimaryInformationForm.patchValue({ stateId: state_id });
  //       this.pubsub.$pub('present_address_state_change', { state_id: state_id });
  //     }
  //   });
  // }

  // getCityListByStateId(state_id) {
  //   this.cityService.getCityListByStateId(state_id).subscribe(
  //     result => {
  //       this.cityList = result.content;
  //     });
  // }

  // getCityListByStateIdAndSetState(state_id: any, city_id?: any) {
  //   this.cityService.getCityListByStateId(state_id).subscribe(
  //     result => {
  //       this.cityList = result.content;
  //       if (city_id) {
  //         this.insertPrimaryInformationForm.patchValue({ cityId: city_id });
  //       }
  //     });
  // }

  onChangePaymentMode(el) {
    
    if(el !== null || el !== undefined || el !== '')
    {
      this.paymentRequiredFlage = true;
      this.insertPrimaryInformationForm.get('payment_received').setValidators(Validators.required)
      this.insertPrimaryInformationForm.get('payment_received').updateValueAndValidity();
    }
    if(el === undefined)
    {
      this.paymentRequiredFlage = false;
      this.insertPrimaryInformationForm.get('payment_received').clearValidators()
      this.insertPrimaryInformationForm.get('payment_received').updateValueAndValidity();
    }
    if(el.payment_mode_value === "cheque"){
      this.insertPrimaryInformationForm.get('payment_reference_no').clearValidators()
      this.insertPrimaryInformationForm.get('payment_reference_no').updateValueAndValidity();   
    }
    let payment_mode_value = el.payment_mode_value;
    this.selected_payment_mode_value = payment_mode_value;
    
  }

  keypressNegetive(e: any) 
  {
    if(!((e.keyCode > 95 && e.keyCode < 106)
    || (e.keyCode > 47 && e.keyCode < 58) 
    || e.keyCode == 8)) {
      return false;
    }
  }

  onChange: any = () => { };
  onTouched: any = () => { };

  registerOnChange(fn) {
    this.onChange = fn;
  }
  onChangeEnableMactal(el) {
    this.enableMactal = el.target.checked;
    this.insertPrimaryInformationForm.get('enable_mactal').setValue(this.enableMactal)
    
  }
onChangeEnableVlan(el){
  this.enableVlan = el.target.checked;
  this.insertPrimaryInformationForm.get('vlanFlag').setValue(this.enableVlan)
}
onChangeEnableAutoGenPass(el){
  this.enableAutoGenPass = el.target.checked;
  this.insertPrimaryInformationForm.get('autoGeneratePassword').setValue(this.enableAutoGenPass)
}


  writeValue(value) {
    if (value) {
      this.value = value;
    }
  }

  validateAllFields(insertPrimaryInformationForm) {
    Object.keys(insertPrimaryInformationForm.controls).forEach(field => {
      const control = insertPrimaryInformationForm.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFields(control);
      }
    });
  }
  clearErrorMsg() {
    if (this.submitted === true) {
      this.submitted = false;
      this.insertPrimaryInformationForm.reset();
    }
  }
  registerOnTouched(fn) {
    this.onTouched = fn;
  }
  onTouchedandChange() {
    let value = this.insertPrimaryInformationForm.value;
    this.onChange(value);
    this.onTouched(value);
  }

  validate(_: FormControl) {
    if (this.insertPrimaryInformationForm.valid === true) {
      if (this.getIsInvalidUsernameAsPerApi() === true ||
        this.getIsInvalidEmailAsPerApi() === true ||
        this.getIsInvalidCafNoAsPerApi() === true ||
        this.getIsInvalidMobileAsPerApi() === true
      ) {
        return { primaryInformation: { valid: false } };
      } else {
        return null;
      }
    } else {
      return { primaryInformation: { valid: false } };
    }
    //return this.insertPrimaryInformationForm.valid ? null : { primaryInformation: { valid: true, }, };
  }

  checkUniqueCustomerByUsername(el) {
    let value = el.target.value;
    if (value && value.length) {
      if(!this.spaceCheck(value)){
        this.invalidUsernameAsPerApi = false;
        this.addSubscriberDetailsService.checkUniqueCustomerByUsername(value).subscribe(result => {
          if (result.responseCode !== 200) {
            this.invalidUsernameAsPerApi = true;
            this.invalidUsernameAsPerApiMsg = result.responseMessage;
            this.onTouchedandChange();
          } else {
            this.invalidUsernameAsPerApi = false;
            this.invalidUsernameAsPerApiMsg = '';
            this.onTouchedandChange();
          }
        });

      }
     
    } else {
      this.invalidUsernameAsPerApi = false;
      this.invalidUsernameAsPerApiMsg = '';
    }
    console.log('data =>', this.insertPrimaryInformationForm.get('username'));
  }

  checkUniqueCustomerByEmail(el) {
    if (this.insertPrimaryInformationForm.get('email').value !==null &&
    this.insertPrimaryInformationForm.get('email').value !==undefined
    && this.email.allowDuplicate === true) {
      ////console.log("Email Uni"+this.insertPrimaryInformationForm.get('email').value)
      let value = el.target.value;
      if (value && value.length) {
        this.invalidEmailAsPerApi = false;
        this.addSubscriberDetailsService.checkUniqueCustomerByEmail(value, null).subscribe(result => {
          if (result.responseCode !== 200) {
            this.invalidEmailAsPerApi = true;
            this.invalidEmailAsPerApiMsg = result.responseMessage;
            this.onTouchedandChange();
          } else {
            this.invalidEmailAsPerApi = false;
            this.invalidEmailAsPerApiMsg = '';
            this.onTouchedandChange();
          }
        });
      } else {
        this.invalidEmailAsPerApi = false;
        this.invalidEmailAsPerApiMsg = '';
      }
    } 
    else{
       // 3 LINE New code
      this.invalidEmailAsPerApi = false;
      this.invalidEmailAsPerApiMsg = '';
      this.onTouchedandChange();
    }

  }

  checkUniqueCustomerByCafno(el) {
  if (this.insertPrimaryInformationForm.get('caf_no').value !== null && 
  this.caf_no.allowDuplicate === true) {
    ////console.log("Caf Unique"+this.insertPrimaryInformationForm.get('caf_no').value)
     let value = el.target.value;
      if (value && value.length !== 0) {
        this.invalidCafNoAsPerApi = false;
        this.addSubscriberDetailsService.checkUniqueCustomerByCafno(value).subscribe(result => {
          if (result.responseCode !== 200) {
            this.invalidCafNoAsPerApi = true;
            this.invalidCafNoAsPerApiMsg = result.responseMessage;
            this.onTouchedandChange();
          } else {
            this.invalidCafNoAsPerApi = false;
            this.invalidCafNoAsPerApiMsg = '';
            this.onTouchedandChange();
          }
        });
      } else {
        this.invalidCafNoAsPerApi = false;
        this.invalidCafNoAsPerApiMsg = '';
      }
    }
    else{ 
      //3 New Code
            this.invalidCafNoAsPerApi = false;
            this.invalidCafNoAsPerApiMsg = '';
            this.onTouchedandChange();
    }
  }

  getRequiredAllField() {
    this.mobile = null;
    this.addSubscriberDetailsService.getRequiredAllField().subscribe(result => {
       console.log("res===>",result)
       
      if (result.responseCode === 200) {

        this.dynamicrequiredArray = result.data;
        this.firstname = this.dynamicrequiredArray.find((e => e.fieldname === 'firstname'));
        this.middlename = this.dynamicrequiredArray.find((e => e.fieldname === 'middlename'));
        this.lastname = this.dynamicrequiredArray.find((e => e.fieldname === 'lastname'));
        
        this.customer_source=this.dynamicrequiredArray.find((e=>e.fieldname==='customer_source'));
        
        this.usernameOfReferredByFriendOrRelative=this.dynamicrequiredArray.find(e=>e.fieldname==='usernameOfFriendOrRelative')
        
        this.mobile = this.dynamicrequiredArray.find((e => e.fieldname === 'mobile_no'));
        this.Gst = this.dynamicrequiredArray.find((e => e.fieldname === 'gst'));
        this.caf_no = this.dynamicrequiredArray.find((e => e.fieldname === 'caf_no'));
        this.email = this.dynamicrequiredArray.find((e => e.fieldname === 'email'));
        this.alt_mobile = this.dynamicrequiredArray.find((e => e.fieldname === 'alt_mobile'));
        this.sales_representative_id = this.dynamicrequiredArray.find(e => e.fieldname == 'salesrepid');
        this.block_House_No=this.dynamicrequiredArray.find(e=>e.fieldname==='blockHouseNo')
        this.Area=this.dynamicrequiredArray.find(e=>e.fieldname==='Area')
        this.property_Type =this.dynamicrequiredArray.find(e=>e.fieldname==='propertyType')
        this.buildingHouseName =this.dynamicrequiredArray.find(e=>e.fieldname==='BuildingHouseName')
        this.TypeOfProperty =this.dynamicrequiredArray.find(e=>e.fieldname==='typeOfProperty')
        

        if ((this.firstname !== null && this.firstname !== undefined) && this.firstname.isMandatory === true) {
          this.insertPrimaryInformationForm.get('firstname').setValidators(Validators.required)
          this.insertPrimaryInformationForm.get('firstname').updateValueAndValidity();
        }
        else {
          this.insertPrimaryInformationForm.get('firstname').clearValidators()
          this.insertPrimaryInformationForm.get('firstname').updateValueAndValidity();
        }

        if ((this.middlename !== null && this.middlename !== undefined) && this.middlename.isMandatory === true) {
          this.insertPrimaryInformationForm.get('middlename').setValidators(Validators.required)
          this.insertPrimaryInformationForm.get('middlename').updateValueAndValidity();
        }
        else {
          this.insertPrimaryInformationForm.get('middlename').clearValidators()
          this.insertPrimaryInformationForm.get('middlename').updateValueAndValidity();
        }

        if ((this.lastname !== null && this.lastname !== undefined) && this.lastname.isMandatory === true) {
          this.insertPrimaryInformationForm.get('lastname').setValidators(Validators.required)
          this.insertPrimaryInformationForm.get('lastname').updateValueAndValidity();
        }
        else {
          this.insertPrimaryInformationForm.get('lastname').clearValidators()
          this.insertPrimaryInformationForm.get('lastname').updateValueAndValidity();
        }





        if ((this.mobile !== null && this.mobile !== undefined) && this.mobile.isMandatory === true) {
          this.insertPrimaryInformationForm.get('mobile_no').setValidators([Validators.required, Validators.pattern('^[0-9]{10}$')])
          this.insertPrimaryInformationForm.get('mobile_no').updateValueAndValidity();
        }
        else {
          this.insertPrimaryInformationForm.get('mobile_no').clearValidators();
          this.insertPrimaryInformationForm.get('mobile_no').setValidators(Validators.pattern('^[0-9]{10}$'))
          this.insertPrimaryInformationForm.get('mobile_no').updateValueAndValidity();
        }

        if ((this.caf_no !== null && this.caf_no !== undefined) && this.caf_no.isMandatory === true) {
          this.insertPrimaryInformationForm.get('caf_no').setValidators(Validators.required)
          this.insertPrimaryInformationForm.get('caf_no').updateValueAndValidity();
        }
        else {
          this.insertPrimaryInformationForm.get('caf_no').clearValidators()
          this.insertPrimaryInformationForm.get('caf_no').updateValueAndValidity();
        }

        if ((this.email !== null && this.email !== undefined) && this.email.isMandatory === true) {
          this.insertPrimaryInformationForm.get('email').setValidators([Validators.required, Validators.email]);
          this.insertPrimaryInformationForm.get('email').updateValueAndValidity();
        }
        else {
          this.insertPrimaryInformationForm.get('email').clearValidators();
          this.insertPrimaryInformationForm.get('email').setValidators(Validators.email);
          this.insertPrimaryInformationForm.get('email').updateValueAndValidity();
        }
        if ((this.customer_source !== null && this.customer_source !== undefined) && this.customer_source.isMandatory === true) {
          this.insertPrimaryInformationForm.get('customerSource').setValidators([Validators.required]);
          this.insertPrimaryInformationForm.get('customerSource').updateValueAndValidity();
        }
        else {
          this.insertPrimaryInformationForm.get('customerSource').clearValidators();
          this.insertPrimaryInformationForm.get('customerSource').setValidators(null);
          this.insertPrimaryInformationForm.get('customerSource').updateValueAndValidity();
        }
        

        if ((this.alt_mobile !== null && this.alt_mobile !== undefined) && this.alt_mobile.isMandatory === true) {
          this.insertPrimaryInformationForm.get('alternate_mobile_no').setValidators([Validators.required, Validators.pattern('^[0-9]{10}$')]);
          this.insertPrimaryInformationForm.get('alternate_mobile_no').updateValueAndValidity();
        }
        else {
          this.insertPrimaryInformationForm.get('alternate_mobile_no').clearValidators();
          this.insertPrimaryInformationForm.get('alternate_mobile_no').setValidators(Validators.pattern('^[0-9]{10}$'))
          this.insertPrimaryInformationForm.get('alternate_mobile_no').updateValueAndValidity();
        }
        if (this.sales_representative_id && this.sales_representative_id.isMandatory === true) {
          this.insertPrimaryInformationForm.get('sales_representative_id').setValidators([Validators.required]);
          this.insertPrimaryInformationForm.get('sales_representative_id').updateValueAndValidity();
        }
        else {
          this.insertPrimaryInformationForm.get('sales_representative_id').clearValidators();
          this.insertPrimaryInformationForm.get('sales_representative_id').updateValueAndValidity();
        }


        if(this.block_House_No && this.block_House_No.isMandatory === true) {
          this.insertPrimaryInformationForm.get('block').setValidators([Validators.required]);
          this.insertPrimaryInformationForm.get('block').updateValueAndValidity();
        }else{
          this.insertPrimaryInformationForm.get('block').clearValidators();
          this.insertPrimaryInformationForm.get('block').updateValueAndValidity();
        }

        if(this.property_Type && this.property_Type.isMandatory === true) {
          this.insertPrimaryInformationForm.get('propertyType').setValidators([Validators.required]);
          this.insertPrimaryInformationForm.get('propertyType').updateValueAndValidity();
        }else {
          this.insertPrimaryInformationForm.get('propertyType').clearValidators();
          this.insertPrimaryInformationForm.get('propertyType').updateValueAndValidity();
        }

        if(this.TypeOfProperty && this.TypeOfProperty.isMandatory === true) {
          this.insertPrimaryInformationForm.get('typeOfProperty').setValidators([Validators.required]);
          this.insertPrimaryInformationForm.get('typeOfProperty').updateValueAndValidity();
        }else {
          this.insertPrimaryInformationForm.get('typeOfProperty').clearValidators();
          this.insertPrimaryInformationForm.get('typeOfProperty').updateValueAndValidity();
        }

        if(this.buildingHouseName && this.buildingHouseName.isMandatory === true) {
          this.insertPrimaryInformationForm.get('building').setValidators([Validators.required]);
          this.insertPrimaryInformationForm.get('building').updateValueAndValidity();
        }else {
          this.insertPrimaryInformationForm.get('building').clearValidators();
          this.insertPrimaryInformationForm.get('building').updateValueAndValidity();
        }

        if ((this.Area !== null && this.Area !== undefined) && this.Area.isMandatory === true) {
          this.insertPrimaryInformationForm.get('area').setValidators(Validators.required)
          this.insertPrimaryInformationForm.get('area').updateValueAndValidity();
        }
        else {
          this.insertPrimaryInformationForm.get('area').clearValidators()
          this.insertPrimaryInformationForm.get('area').updateValueAndValidity();
        }


      }
    })
  }

 onChangeCustomerSource(event){
    if(event && event.value == 'referredByFriendOrRelative'){
      if ((this.usernameOfReferredByFriendOrRelative !== null && this.usernameOfReferredByFriendOrRelative !== undefined) && this.usernameOfReferredByFriendOrRelative.isMandatory === true) {
        this.insertPrimaryInformationForm.get('referralName').setValidators([Validators.required]);
        this.insertPrimaryInformationForm.get('referralName').updateValueAndValidity();
      }
      else {
        this.insertPrimaryInformationForm.get('referralName').clearValidators();
        this.insertPrimaryInformationForm.get('referralName').setValidators(null);
        this.insertPrimaryInformationForm.get('referralName').updateValueAndValidity();
      }
      
    }
    else{
      this.insertPrimaryInformationForm.get('referralName').clearValidators();
      this.insertPrimaryInformationForm.get('referralName').setValidators(null);
      this.insertPrimaryInformationForm.get('referralName').updateValueAndValidity();
    }
  }

  checkUniqueCustomerByMobile(el) {
  
  
    if (this.insertPrimaryInformationForm.get('mobile_no').value !== null && 
    this.insertPrimaryInformationForm.get('mobile_no').value !==undefined
    && this.mobile.allowDuplicate === true){
      ////console.log("Mobile Uni"+this.insertPrimaryInformationForm.get('mobile_no').value)
      let value = el.target.value;
      if (value && value.length) {
        this.invalidMobileAsPerApi = false;
        if(value.length !== 10){
          this.isVerifybtn = true;
        }
        this.addSubscriberDetailsService.checkUniqueCustomerByMobile(value,null).subscribe(result => {
          if (result.responseCode !== 200) {
            this.invalidMobileAsPerApi = true;
            this.isVerifybtn = true;
            this.invalidMobileAsPerApiMsg = result.responseMessage;
            this.onTouchedandChange(); 
          } else {
            this.invalidMobileAsPerApi = false;
            if(value.length === 10){
              this.isVerifybtn = false;
            }
            this.invalidMobileAsPerApiMsg = '';
            this.onTouchedandChange();
          }
        });
      } else {
        this.isVerifybtn = true;
        this.invalidMobileAsPerApi = false;
        this.invalidMobileAsPerApiMsg = '';
      }

    }
    else{
      //3 Line New code
      this.invalidMobileAsPerApi = false;
            this.invalidMobileAsPerApiMsg = '';
            this.onTouchedandChange();
    }
    
  }

  onChangePartner(event) {
    this.insertPrimaryInformationForm.get('sales_representative_id').reset();
    this.insertPrimaryInformationForm.get('collectorid').reset();
    this.collectorList = [];
    
    if (event) {
      localStorage.setItem('partnerType', event.commission_type);
      localStorage.setItem('partnerIDSelected', event.franchies_id);
      this.partnerId = event.franchies_id;
      this.tempPartnerId.emit(this.partnerId);   
      this.salesRepresentativeList=null;
      this.getSaleRespresentativedata(event.franchies_id,6)  
      this.getCollectorList(this.partnerId);  
    }else{
      this.tempPartnerId.emit(undefined); 
    }
   }
   onChangePropertyType(event){
    this.insertPrimaryInformationForm.get('propertyType').setValue(event.text);
    this.insertPrimaryInformationForm.updateValueAndValidity();
   }

   getSaleRespresentativedata(id,role_id){
    this.staffService.getPartnerwiseSaleRespresentative(id,role_id).subscribe(res=>{
    this.salesRepresentativeList= res.staffUserlist;
    })  
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  firstCharacterSpaceNotAllowed(ctrlValue: any) {
    if (ctrlValue.target.selectionStart === 0 && ctrlValue.code === "Space") {
      ctrlValue.preventDefault();
    }
  }

  getCollectorList(partnerId){
    this.addSubscriberDetailsService.getCollectorListByPartner(partnerId).subscribe((res)=>{
      this.collectorList = res.staffUserlist;
    })
  } 

  public getSubscriberCustomer() {
    this.CustomerList = 'subscriber/searchForAll?s=';
  }
  setSubscriber(eventcust){
    let event = eventcust.event;
    this.custId = event.custid;
    this.custname=event.username;
    this.insertPrimaryInformationForm.get('referralName').setValue(this.custname)
    this.insertPrimaryInformationForm.get('custId').setValue(this.custId)
  }

  onChangeGst(event){
    this.insertPrimaryInformationForm.get('gst').reset();
    if (event.value === 'Yes' && this.Gst.isMandatory === true) {
      this.insertPrimaryInformationForm.get('gst').setValidators(Validators.required);
      this.insertPrimaryInformationForm.get('gst').updateValueAndValidity();
    }
    else if (event.value !== 'Yes') {
      this.insertPrimaryInformationForm.get('gst').clearValidators();
      this.insertPrimaryInformationForm.get('gst').updateValueAndValidity();
    }
  }

  onCLearGst() {
    this.insertPrimaryInformationForm.get('gst').reset();
    this.insertPrimaryInformationForm.get('gst').clearValidators();
    this.insertPrimaryInformationForm.get('gst').updateValueAndValidity();
  }
}
