import { Component, Inject, OnInit, Optional,OnDestroy } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import _ from 'underscore';
import { AclConstants } from '../../aclOperationConstants';
import { AclClassConstants } from '../../aclClassConstants';
import { LoginService } from '../../login/login.service';
import { NotificationService } from '../notification/notification.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { HostListener } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';


export class DialogPassData{
  id:number;
}
import { eventNames } from 'cluster';
import { DataSharingServiceService } from '../../data-sharing-service.service';
import { CaseReasonService } from '../../customer-care/case-reason/service/case-reason.service';
import { env } from 'process';
@Component({
  selector: 'app-add-notification',
  templateUrl: './add-notification.component.html',
  styleUrls: ['./add-notification.component.scss']
})
export class AddNotificationComponent implements OnInit {
  

  saveBtn:boolean=false;
  AclClassConstants = AclClassConstants;
  AclConstants = AclConstants;
  public loginService: LoginService;
  isChecked:boolean=false;
  isdisabled:boolean=false;

  eventlist:any[]=[];
  eventNotification:any[]=[];
  notificationForm = new FormGroup({
    category: new FormControl(null,[Validators.required]),
    email_body: new FormControl(null),
    email_subject: new FormControl(null),
    email_enabled: new FormControl(null,[Validators.required]),
    name: new FormControl(null,Validators.required),
    event_name:new FormControl(null,Validators.required),
    notificationConfig: new FormGroup({
      atrr_condi: new FormControl(null),
      attr_value: new FormControl(null),
      config_atrr_type: new FormControl(null),
      config_attribute: new FormControl(null),
      config_entity: new FormControl(null),
      id: new FormControl(null)
    }),
    sms_body: new FormControl(null),
    sms_enabled: new FormControl(null,Validators.required),
    pushNotificationEnabled: new FormControl(null,Validators.required),
    pushNotificationBody: new FormControl(null),
    status: new FormControl(null,[Validators.required]),
    template_id: new FormControl(''),
    isBroadcast:new FormControl('false')
  });

  planSelected: boolean;
  dateSelected: boolean;
  categorySelected: boolean;
  public notification_id: any = null;
  show_update: any = false;
  submitted: boolean = false;
  selectForUpdate: any;
  commonStatus: any;
  public sub: any = null;
  valueAbsolutateSelected: boolean;
  attrSelected: boolean;
  smsSelected: boolean;
  pushNotificSelected: boolean;
  emailSelected: boolean;
  notificationGroupId:any;
  public caseReason = [];
  // statusList: any[] = [
  //   { value_field: 'ACTIVE', display_field: 'Active' },
  //   { value_field: 'INACTIVE', display_field: 'In Active' },
  // ];
  attplaceholder = 'Enter Attribute Value';
  constructor(
    private notificationService: NotificationService,
    loginService: LoginService,
    private route: ActivatedRoute,
    private router: Router,
    private tosterservice:ToastrService,
    private sharingdataService:DataSharingServiceService,
    public dialog:MatDialog,
    public caseReasonService: CaseReasonService,
    public dialogRef: MatDialogRef<AddNotificationComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: DialogPassData,
  ) {
    this.loginService = loginService;
  }

  ngOnInit() {
    let id = this.route.snapshot.paramMap.get('id');
    this.notificationGroupId=null;
    this.notificationGroupId=localStorage.getItem('ngId');//Local Storage old
    if (id) {
      this.notification_id = id;
      this.show_update = true; 
      this.getNotification(this.notification_id);
    }
    this.getEventAll();
    this.commonList();
  }


  @HostListener('window:keydown', ['$event'])
  firstCharacterSpaceNotAllowed(ctrlValue:any){
    if(ctrlValue.target.selectionStart===0 && ctrlValue.code==="Space"){
      ctrlValue.preventDefault();
     }
  }

  public getcaseReason() {
    this.caseReasonService.getAllcaseReason().subscribe((res) => {
      this.caseReason =  res.dataList
    });
  }

  commonList()
  {
    this.notificationService.commonStatus().subscribe(res => {
      this.commonStatus = res.dataList;
    })
  }


  getBillingTemplateData() {
    
    let id = this.notification_id;
    this.notificationService.getNotificationById(id).subscribe(result => {
      ////console.log('data Key ->', result.data);
      this.notificationForm.patchValue(this.transformData(result.data));
      if (result.data.category === 'generic') {
        this.categorySelected = false;
      } 
      else if (result.data.category === 'configurable') {
        this.categorySelected = true;
      }
      else if (result.data.category === 'configurableManual') {
        this.categorySelected = true;
      }
      if (result.data.sms_enabled === true) {
        this.smsSelected = true;
      }
      else {
        this.smsSelected = false;
      }
      if (result.data.email_enabled === true) {
        this.emailSelected = true;
      }
      else {
        this.emailSelected = false;
      }
      if(result.data.isBroadcast ===true){
        this.notificationForm.get('isBroadcast').setValue(true);
      } 
      else{
        this.notificationForm.get('isBroadcast').setValue(false);
      }
    });
  }
  getNotification(notificationID){
    this.getcaseReason();
    this.notificationService.NotificationById(notificationID).subscribe(result => {  
          if (result.data.sms_enabled === true) {
            this.smsSelected = true;
            this.notificationForm.get('template_id').setValidators([Validators.required]);
          }
          else {
            this.smsSelected = false;
            this.notificationForm.get('template_id').setValue(null);
            this.notificationForm.get('template_id').clearValidators();
            this.notificationForm.get('template_id').updateValueAndValidity();
          }
          if (result.data.email_enabled === true) {
            this.emailSelected = true;
          }
          else {
            this.emailSelected = false;
          }
          if (result.data.pushNotificationEnabled === true) {
            this.pushNotificSelected = true;
          }
          else {
            this.pushNotificSelected = false;
          }
         
          if(result.data.isBroadcast ===true){
            this.notificationForm.get('isBroadcast').setValue(true);
          } 
          else{
            this.notificationForm.get('isBroadcast').setValue(false);
          }
          if(result.data && result.data.notificationConfig != null && (result.data.notificationConfig.config_attribute == 'cust_suspension' || result.data.notificationConfig.config_attribute == 'ip_expiry' || result.data.notificationConfig.config_attribute == 'plan_expiry')){
            this.attrSelected = false;
          }else if(result.data && result.data.notificationConfig != null && (result.data.notificationConfig.config_attribute == 'quota')){
            this.attrSelected = true;
          }
          if(result.data && result.data.notificationConfig != null && (result.data.notificationConfig.config_atrr_type == 'value_absolute' || result.data.notificationConfig.config_atrr_type == 'value_percentage')){
            this.dateSelected = false;
            this.valueAbsolutateSelected = true;
          }else if(result.data && result.data.notificationConfig != null && result.data.notificationConfig.config_atrr_type == 'date'){
            this.dateSelected = true;
            this.valueAbsolutateSelected = false;
          }
          this.notificationForm.patchValue(result.data);
          if (result.data.category === 'generic') {
            this.categorySelected = false;
          } else if (result.data.category === 'configurable') {
            this.categorySelected = true;
          } else if (result.data.category === 'configurableManual') {
            this.categorySelected = true;
            let atrr_condi_str = result.data.notificationConfig.atrr_condi;
            const arrayOfNumbers = atrr_condi_str.split(",").map(Number);
            let tempArr = []
            tempArr.push(...arrayOfNumbers);
            this.notificationForm.get("notificationConfig.atrr_condi").patchValue(tempArr);
            this.attplaceholder = 'abc@email.com,xyz@email.com';
          } 
    })

  }

  transformData(data) {
    let notification: any = {};
    notification.category = data.category,
      notification.email_body = data.email_body,
      notification.email_subject = data.email_subject,
      notification.email_enabled = data.email_enabled,
      notification.name = data.name,
      notification.event_name=data.event_name;
      notification.notificationConfig = {};
    if (data.notificationConfig !== null) {
      notification.notificationConfig.atrr_condi = data.notificationConfig.atrr_condi,
        notification.notificationConfig.attr_value = data.notificationConfig.attr_value,
        notification.notificationConfig.config_atrr_type = data.notificationConfig.config_atrr_type,
        notification.notificationConfig.config_attribute = data.notificationConfig.config_attribute,
        notification.notificationConfig.config_entity = data.notificationConfig.config_entity,
        notification.notificationConfig.id = data.notificationConfig.id
    }
    notification.sms_body = data.sms_body,
      notification.sms_enabled = data.sms_enabled,
      notification.status = data.status,
      notification.template_id = data.template_id,
      notification.pushNotificationEnabled = data.pushNotificationEnabled,
      notification.pushNotificationBody = data.pushNotificationBody
    return notification;
  }

  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '15rem',
    minHeight: '5rem',
    placeholder: 'Enter text here...',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
    toolbarHiddenButtons: [
      ['bold']
    ],
    customClasses: [
      {
        name: "quote",
        class: "quote",
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h1",
      },
    ]
  };

  categoryChange(event) {
    this.notificationForm.get("notificationConfig.config_attribute").reset();
    this.notificationForm.get("notificationConfig.config_atrr_type").reset();
    this.notificationForm.get("notificationConfig.atrr_condi").reset();
    this.caseReason = [];
    if (event === 'plan') {
      this.planSelected = true;
    } else if (event === 'customer') {
      this.planSelected = false;
    }
  }

  templateCategoryChange(event) {
    this.notificationForm.get("notificationConfig.config_entity").reset();
    this.notificationForm.get("notificationConfig.config_attribute").reset();
    this.notificationForm.get("notificationConfig.config_atrr_type").reset();
    this.notificationForm.get("notificationConfig.atrr_condi").reset();
    this.caseReason = [];
    if (event === undefined) {
      this.categorySelected = false;
    } else if (event === 'generic') {
      this.categorySelected = false;
    } else if (event === 'configurable') {
      this.categorySelected = true;
      this.attplaceholder = 'Enter Attribute Value';
    } else if (event === 'configurableManual') {
      this.categorySelected = true;
      this.attplaceholder = 'abc@email.com,xyz@email.com'
    } 

  }

  configAttriChange(event) {
    this.notificationForm.get("notificationConfig.config_atrr_type").reset();
    this.notificationForm.get("notificationConfig.atrr_condi").reset();
    this.caseReason = [];
    if (event === 'quota') {
      this.attrSelected = true;
    } else if (event === 'ip_expiry' || event === 'plan_expiry' || event === 'cust_suspension') {
      this.attrSelected = false;
    }
  }

  smsChange(event) {
    if (event === true) {
      this.smsSelected = true;
      this.notificationForm.get('template_id').setValidators([Validators.required]);
    } else {
      this.notificationForm.get('template_id').clearValidators();
      this.notificationForm.get('template_id').updateValueAndValidity();
      this.smsSelected = false;
    }
  }

  pushNotificationChange(event){
    this.notificationForm.get('pushNotificationBody').reset();
    if (event === true) {
      this.pushNotificSelected = true;
    } else {
      this.pushNotificSelected = false;
    }
  }

  onClickCheck(event){
    if(event.target.checked){
      let smsBody = this.notificationForm.get('sms_body').value;
      this.notificationForm.get('pushNotificationBody').setValue(smsBody)
    }
    else{
      this.notificationForm.get('pushNotificationBody').reset();
    }

  }

  emailChange(event) {
    if (event === true) {
      this.emailSelected = true;
    } else {
      this.emailSelected = false;
    }
  }

  configAttriTypeChange(event) {
    this.notificationForm.get("notificationConfig.atrr_condi").reset();
    this.caseReason = [];
    this.dateSelected = false;
      this.valueAbsolutateSelected = false;
    if (event === 'date') {
      this.dateSelected = true;
      this.valueAbsolutateSelected = false;
    } else if (event === 'value_absolute' || event === 'value_percentage') {
      this.dateSelected = false;
      this.valueAbsolutateSelected = true;
    }
    if(event && event == 'caseReason'){
      this.getcaseReason();
    }
  }

    saveNotification() {
      let valueString: string = '';
  ////console.log("form Vlaue",this.notificationForm)
    
      let atrr_condi_str = this.notificationForm.get("notificationConfig.atrr_condi").value;
      if(this.notificationForm.get('category').value == 'configurableManual'){
        const valueArray = atrr_condi_str;
        valueString = valueArray.toString();
      }else{
        valueString = atrr_condi_str
      }
    //this.notificationForm.get("notificationConfig.atrr_condi").setValue(valueString);

    this.submitted=true;
    this.notificationForm.markAllAsTouched();
    if(this.notificationForm.invalid ){
      this.tosterservice.error('Please fill all the details!');
    return   
    }

    let apiInputData = Object.assign({}, this.notificationForm.value);
    let apiInputDatanotconfig = Object.assign({}, this.notificationForm.get('notificationConfig').value);
    if (this.categorySelected == false) {
      apiInputData.notificationConfig = null;
    }
    if (!this.show_update) {
      this.saveBtn =true;
      // let notificationGroupId=localStorage.getItem('notificationGroupId');
      apiInputData['notificationGroupId']=this.notificationGroupId;
      apiInputDatanotconfig['atrr_condi']=valueString;
      apiInputData['notificationConfig'] = apiInputDatanotconfig;
      this.notificationService.saveNotification(apiInputData).subscribe((result) => {
        if (result.responseCode !== 200 && result.responseMessage) {
          this.saveBtn =false;
          this.tosterservice.error(result.responseMessage);
        } else {
          this.saveBtn =false;
          this.tosterservice.success('Notification Save Successfully.');
          this.router.navigate(['operations/notification-group/notification-group-add',this.notificationGroupId]);
        }
      }, err => {
        this.saveBtn =false;
        this.tosterservice.error(err.error.status + " " + err.error.ERROR);
      });
    } else {
      this.saveBtn =true;
      apiInputData.id = this.notification_id;
      // let notificationGroupId=localStorage.getItem('notificationGroupId');
      apiInputData['notificationGroupId']=this.notificationGroupId;
      apiInputDatanotconfig['atrr_condi']=valueString;
      apiInputData['notificationConfig'] = apiInputDatanotconfig;
      this.notificationService.updateNotification(apiInputData).subscribe((result) => {
        if (result.responseCode !== 200 && result.responseMessage) {
          this.saveBtn =false;
          this.tosterservice.error(result.responseMessage);
        } else {
          this.saveBtn =false;
          this.tosterservice.success('Notification Updated Successfully.');
          this.router.navigate(['operations/notification-group/notification-group-add',this.notificationGroupId]);
        }
      }, err => {
        this.saveBtn =false;
        this.tosterservice.error(err.error.status + " " + err.error.ERROR);
      });
    }
  }

  onBackToNotification() {
    // let notificationGroupId=localStorage.getItem('notificationGroupId');
    //this.router.navigate(['operations/notification-group/notification-group-add',this.notificationGroupId]);
    if(this.sharingdataService.lastAuditUrl == this.router.url){
      this.router.navigate(['/operations/audit']);
      this.sharingdataService.lastAuditUrl = undefined;
      }else{
        this.router.navigate(['operations/notification-group/notification-group-add',this.notificationGroupId]);
      }
  }

  checkValue(event: any){
    
    if(event === 'true'){
      this.notificationForm.get('isBroadcast').setValue(true);
    }else{
      this.notificationForm.get('isBroadcast').setValue(false);
    }
  }
  backToViewList(){
    this.router.navigate(['operations/notification']);
  }
  getEventAll(){
    this.notificationService.getAllEventList().subscribe(res=>{
      Object.keys(res.data).forEach(element=>{
        let event:any={};
        event.text=element;
        event.value=element;
        this.eventlist.push(event);
        this.eventNotification=this.eventlist;
      });
    })
  }
}
