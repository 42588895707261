import { Component, HostListener, OnInit } from '@angular/core';
import { RemarkTemplateModel } from '../model/remark-template-model';
import { RemarkTemplateService } from '../service/remark-template.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AbstractBaseAddUpdateComponent } from '../../../../components/base/abstract-base-add-update-component';
import { CommonStatusService } from '../../../../services/common_status/common-status.service';
import { IBaseService } from '../../../../services/ibase-service';
import { DataSharingServiceService } from '../../../data-sharing-service.service';

@Component({
  selector: 'app-add-update-remark-template',
  templateUrl: './add-update-remark-template.component.html',
  styleUrls: ['./add-update-remark-template.component.scss']
})
export class AddUpdateRemarkTemplateComponent extends AbstractBaseAddUpdateComponent<RemarkTemplateModel> implements OnInit {
  public entity: RemarkTemplateModel = new RemarkTemplateModel();
  public entity_id: any = null;
  form: FormGroup;
  url: string;
  public userList: any[] = [];

  //public userList = new Array<any>();
  commonStatus: any = [{
    text: 'Select Status', value: ''
  }];
  constructor(
    private RemarkTemplateService: RemarkTemplateService,
    private CommonStatus: CommonStatusService,
    private activeRoute: ActivatedRoute,
    private router: Router,
    public toastrService: ToastrService,
    private genericDataSharingService: DataSharingServiceService,
    ) {
    super(toastrService, router);
  }

  ngOnInit() {
    this.init();
    let id = this.activeRoute.snapshot.paramMap.get('id');
    if (id) {
      this.isUpdateComponent = true;
      this.getById(Number(id));
    }
    this.CommonStatus.getCommonList().subscribe((res) => {
      this.commonStatus = res.dataList
    })
    this.getStaff();
  } 
  @HostListener('window:keydown', ['$event'])
  firstCharacterSpaceNotAllowed(ctrlValue:any){
    if(ctrlValue.target.selectionStart===0 && ctrlValue.code==="Space"){
      ctrlValue.preventDefault();
     }
  }


  getStaff(){
    this.RemarkTemplateService.getStaff(12).subscribe((res) =>{
      this.userList = res.staffUserlist;
    })
  }

  getService(): IBaseService<RemarkTemplateModel> {
    return this.RemarkTemplateService;
  }

  setData(data: RemarkTemplateModel) {
    this.entity = data;
  }

  getData(): RemarkTemplateModel {
    return this.entity;
  }

  public getModuleHomeUrl(): string {

    this.url = 'customer-care/remarkTemplate'
    return this.url;
  }

  public getActiveRoute(): ActivatedRoute {
    return this.activeRoute;
  }
  public getNewModal(): RemarkTemplateModel {
    return new RemarkTemplateModel();
  }

  public getFormGroup(): FormGroup {
    return this.form;
  }

  public getId(): number {
    return this.entity.id;
  }

  get f() {
    return this.form.controls;
  }

  setValidation() {
    this.form = new FormGroup({
      serviceArea_name: new FormControl('', [Validators.required]),
      serviceArea_status: new FormControl('', [Validators.required]),
      body: new FormControl('', [Validators.required])
    });
  }

  formSubmit() {
    if (this.validateForm()) {
      this.onSubmit();
    }
  }

  closeDialog(){
    //
  }

getGenericDataService(){
return this.genericDataSharingService
}



}

