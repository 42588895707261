import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from '../../../../services/base-service';
import { CaseReasonModel } from '../model/case-reason-model';
const baseApiUrl = '';
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};


@Injectable({
  providedIn: 'root'
})

export class CaseReasonService extends BaseService<CaseReasonModel> {
  
  constructor(http: HttpClient) {
    super(http);
  }
 

  getModuleName(): string {
    return 'caseReason';
  }
 
  validate(): boolean {
    return false;
  }

  insertCaseReasonConfig(data: any): Observable<any> {
    const post_url = baseApiUrl + 'caseReason/config/save';
    return this.http.post<any>(post_url, data, { headers: httpOptions.headers });

  }

  getAllCaseReasonConfigByCaseReasonId(caseReasonId): Observable<any> {
    const get_url = baseApiUrl + 'caseReason/config/byCaseReasonId/' + caseReasonId;
    return this.http.get(get_url,{ headers: httpOptions.headers });
  }

  deleteCaseReasonConfig(data: any): Observable<any> {
    const delete_url = baseApiUrl + 'caseReason/config/delete';
    return this.http.post<any>(delete_url, data, { headers: httpOptions.headers });
  }

  insertCaseReason(data: any): Observable<any> {
    const post_url = baseApiUrl + 'caseReason/save';
    return this.http.post<any>(post_url, data, { headers: httpOptions.headers });

  }

  updateCaseReason(data: any): Observable<any> {
    const update_url = baseApiUrl +"caseReason/update";
    return this.http.post<any>(update_url, data, { headers: httpOptions.headers });

  }

  getAllcaseReason(): Observable<any>{
    return this.http.get(baseApiUrl + 'caseReason/all', { headers: httpOptions.headers });
  }

  getcaseReasonInternal(): Observable<any>{
    return this.http.get(baseApiUrl + 'caseReason/teamConfigReasonByType/internal', { headers: httpOptions.headers });
  }

  getcaseReasonCustomer(): Observable<any>{
    return this.http.get(baseApiUrl + 'caseReason/teamConfigReasonByType/customer', { headers: httpOptions.headers });
  }
  getcaseReasonByID(id): Observable<any>{
    return this.http.get(baseApiUrl + 'caseReason/' + id, { headers: httpOptions.headers });
  }

  getAllServiceArea(): Observable<any>{
    return this.http.get(baseApiUrl + 'serviceArea/getByTeams', { headers: httpOptions.headers });
  }

  getStaffByRole(roleId): Observable<any>{
    return this.http.get(baseApiUrl + 'staffuserByRole/' + roleId, { headers: httpOptions.headers });
  }

  getAllStaff(): Observable<any>{
    return this.http.get(baseApiUrl + 'staffuser/allActive', { headers: httpOptions.headers });
  }

  getAllRole(): Observable<any>{
    return this.http.get(baseApiUrl + 'role/all', { headers: httpOptions.headers });
  }

  getTeamType(serviceAreaId): Observable<any>{
    return this.http.get(baseApiUrl + 'teams/getTeamTypeByServiceAreaNew/' + serviceAreaId, { headers: httpOptions.headers });
  }

  getTeamTypeByServiceAreaForReason(data: any):Observable<any>{
    const post_url = baseApiUrl +"teams/getTeamTypeByServiceAreaForReason";
    return this.http.post<any>(post_url, data, { headers: httpOptions.headers });
  }


  public getTeamList(data): Observable<any> {
    const post_url = baseApiUrl + 'teams/getTeamsByTeamTypeAndServiceAreaAndGroup';
    return this.http.post<any>(post_url, data, { headers: httpOptions.headers })
  }

  public getTeamList1(data): Observable<any> {
    const post_url = baseApiUrl + 'teams/getTeamsByTeamTypeAndServiceArea';
    return this.http.post<any>(post_url, data, { headers: httpOptions.headers })
  }

  // ------
  getTeamForCaseReport(): Observable<any>{
    return this.http.get(baseApiUrl + 'serviceArea/getByTeams', { headers: httpOptions.headers });
  }

  getGroup(){
    const post_url = baseApiUrl + 'group';
    return this.http.post<any>(post_url,{});
  }

  getServiceAll(active): Observable<any>{
    return this.http.get(baseApiUrl + 'serviceArea/byStatus/' + active, { headers: httpOptions.headers });
  }


}
