import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild, Inject, Optional, Input, Output, EventEmitter, TemplateRef } from '@angular/core';
import { CasesModel } from '../model/cases-model';
import { AbstractBaseAddUpdateComponent } from '../../../../components/base/abstract-base-add-update-component';
import { IBaseService } from '../../../../services/ibase-service';
import { CasesService } from '../service/cases.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CommonListServicesService } from '../../../../services/common_list_service/common-list-services.service';
import { SubscriberService } from '../../../masters/subscriber/subscriber.service';
import { CaseReasonService } from '../../case-reason/service/case-reason.service';
import { StaffService } from '../../../hr/staff/staff.service';
import { CaseReasonModel } from '../../case-reason/model/case-reason-model';
import { DatePipe } from '@angular/common';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Updatelistmodel } from '../model/updatelistmodel';
import { HostListener } from '@angular/core';
import { MatAccordion } from '@angular/material/expansion';
import { SearchAutocompleteComponent } from '../../../../components/search-autocomplete/search-autocomplete.component';
import { LoginService } from '../../../login/login.service';
import { AclClassConstants } from '../../../aclClassConstants';
import { AclConstants } from '../../../aclOperationConstants';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatTableDataSource } from '@angular/material';
import { DataSharingServiceService } from '../../../data-sharing-service.service';
import { InventoryService } from '../../../../../../src/app/pages/inventory/inventory/service/inventory.service';
import { Circuit } from '../../../masters/model/Circuit';
import { CommonStatusService } from '../../../../services/common_status/common-status.service';
import { CircuitServiceService } from '../../../masters/circuit-details/service/circuit-service.service';
import { TableKeyEnum } from '../../../../core/enum/table-key.enum';

@Component({
  selector: 'app-case-update-popup',
  templateUrl: './case-update-popup.component.html',
  styleUrls: ['./case-update-popup.component.scss']
})
export class CaseUpdatePopupComponent {
  custId: any;
  authTypes:any[]=[];
  connectionTypeflag:boolean=false;
  IpAddressIndex:any
  @ViewChild("actionBtn1", { static: false }) actionBtnReff1: TemplateRef<any>;
  circuitneogridsetting: any = {};
  flagforcircuit:boolean=true;
  DataSources = new MatTableDataSource([]);
  techObj = {
    ipAddress: '',
  };
  allColumns1: any[] = [];
  connectionTypes:any[]=[];
  isUpdate: boolean;
  tablekeyEnum = TableKeyEnum;
  circuitTypeData:any[]=[];
  deviceTypes: any[] = [];
  qosPolicyTypeData:any[]=[];
  ipList: any[] = [];
  connectvityType: any[] = [];
  checkResolved: boolean;
  circuitModalRef: NgbModalRef;
  circuit = new Circuit();
  circuitDetail: FormGroup=  new FormGroup({
    circuitId: new FormControl('', Validators.required),
    circuitidentity: new FormControl(''),
    bandwith_policyName: new FormControl(null),
    static_Ippool: new FormControl(''),
    connectvityType: new FormControl(''),
    conectinvalue:new FormControl(''),
    typeDevice: new FormControl(''),
    otherValue: new FormControl(''),
    status: new FormControl('', Validators.required),
    circuitType:new FormControl('',Validators.required),
    didNos:new FormControl(''),
    deviceId:new FormControl(null),
    pilotNo:new FormControl(''),
    noOfChannels:new FormControl(''),
    connectionType:new FormControl(''),
    authType: new FormControl(''),
    userName:new FormControl(''),
    password:new FormControl(''),
    ipAddress:new FormControl('')

  })
  @Input() entity: CasesModel;
  @Input() updateList: Updatelistmodel;
  @Output() saveData: EventEmitter<any> = new EventEmitter();
  formupdate: FormGroup = new FormGroup({
    case_message_update: new FormControl('', [Validators.required]),
    //resolution_update: new FormControl(''),
    casestatus_update: new FormControl(''),
    case_reason_update: new FormControl(''),
    remarkTemplate: new FormControl(''),
    etr:new FormControl(null),
    is_circuit_update_table:new FormControl(null),
    etrUnit:new FormControl(null),
    case_priority_update: new FormControl(null),
    case_suspendtilldate_update: new FormControl(''),
    notified_enable:new FormControl(false),
    title: new FormControl(''),
    amount: new FormControl(null, [Validators.required]),
    issue: new FormControl(null, [Validators.required]),
    solution: new FormControl(null, [Validators.required]),
    receipt_no: new FormControl(null, [Validators.required]),
    serviceAreaId: new FormControl(null),
    teamType: new FormControl(null),
    teamId: new FormControl(null),
    groupId: new FormControl(null),
    staffId: new FormControl(null),
    allocationType: new FormControl(null),
    algorithmType: new FormControl(null),
    chargableType: new FormControl(null)
    
 
  });
  url: string;
  public subscriberList: string;
  casesData: any;
  default_value: any
  autoField: any = ['name', 'username', 'phone', 'email']
  UtrUnitArray:any[]=[
    {
      text:'Hours',
      value:'Hours',
      
    },
    {
      text:'Days',
      value:'Days',
    }
  ];
  isdisabled: boolean = false;
  AclClassConstants = AclClassConstants;
  AclConstants = AclConstants;
  disableflag: boolean = false;
  public customer: any;
  public staffList: string;
  public staffAsId: any
  caseReasonList=[];
  caseRemarkList=[];
  public caseType: any;
  public caseStatus: any;
  public caseFor: any;
  public priority: any;
  //public resolution: any;
  public loginService: LoginService;
  dynamicdata: any;
  serviceAreaList: any;
  teamTypeList: any = [];
  TeamList: any = [];
  GroupList: any = [];
  serviceAreaId: any;
  algorithmType: any;
  allocationList: any;
  chargableType:any;
  algoTypeList: any;
  enableflag: boolean = false;
  serviceIdPass: any;
  teamName: any;
  groupName:any
  staffListArray: any = [];
  showFlag: boolean;
  submitted: boolean;
  csubmitted: boolean;
  ngbRef: NgbModalRef;
  serviceareaid:number;
  public error_remark: boolean;
  statusList=[];
  selected_change_type_of_device: any;

  constructor(
    public CasesService: CasesService,
    private ngbModalService: NgbModal,
    private circuitService: CircuitServiceService,
    public CommonListService: CommonListServicesService,
    public caseReasonService: CaseReasonService,
    public staffService: StaffService,
    public Subscriber: SubscriberService,
    private activeRoute: ActivatedRoute,
    private router: Router,
    public toastrService: ToastrService,
    private datePipe: DatePipe,
    private genericDataSharingService: DataSharingServiceService,
    loginService: LoginService,
    public CommonStatus: CommonStatusService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<CaseUpdatePopupComponent>,
    private inventoryService: InventoryService
  ) {
    this.loginService = loginService;
  }
  Internalcase: boolean
  ngOnInit() {
    let URL = this.router.url.includes('casesInternal')
    if (URL) {
      this.Internalcase = true
      this.disableflag = true
    } else {
      this.Internalcase = false
      this.disableflag = false
    }
    //this.setValidation();
    this.getcaseReason();
    this.getRemarkList();
    this.commonList();
    this.getTableColumns()
    this.entity.etrUnit = this.entity.etrUnit? this.entity.etrUnit : 'Hr';
    this.updateList.etrUnit = this.entity.etrUnit;
    this.updateList.etr = this.entity.etr
    this.updateList.notified_enable = false
    //this.getresolution();
    //setTimeout(() => {
    this.defaultSelectionAssignment(this.entity.serviceareaid);
    //}, 500);
    //this.getupdateListData();
    // if (this.resolution && this.resolution.length > 0 && this.entity.caseStatus == "Resolved") {
    //   this.formupdate.get('resolution_update').setValue(this.resolution[0].id)
    // }
    // console.log('entity',this.entity)

    // console.log('update list',this.updateList)
    this.CommonStatus.getCommonList().subscribe((res) => {
      this.statusList = res.dataList;
    });
  }

  closePopup() {
    this.ngbRef.close();
  }

  getService(): IBaseService<CasesModel> {
    return this.CasesService;
  }

  @HostListener('window:keydown', ['$event'])
  firstCharacterSpaceNotAllowed(ctrlValue: any) {
    if (ctrlValue.target.selectionStart === 0 && ctrlValue.code === "Space") {
      ctrlValue.preventDefault();
    }
  }


  public getcaseReason() {
    if( this.Internalcase == true){
      this.caseReasonService.getcaseReasonInternal().subscribe((res) => {
        this.caseReasonList = res.dataList;
      });
    }
    else{
      this.caseReasonService.getcaseReasonCustomer().subscribe((res) => {
        this.caseReasonList = res.dataList;
      });
    }
  }

  onRemarkchange(event){
    this.formupdate.get('case_message_update').reset()
    if(event){
      this.formupdate.get('case_message_update').setValue(event.body)
  }

  }

  getRemarkList() {
    this.CasesService.getRemarkTemplateList().subscribe((res) => {
      this.caseRemarkList = res.dataList;
      
    });
  }
  // public getresolution() {
  //   this.CasesService.getresolutionreason().subscribe((res) => {
  //     this.resolution = res.dataList;
  //     if (this.resolution && this.resolution.length > 0 && this.entity && this.entity.caseStatus == "Resolved") {
  //       this.formupdate.get('resolution_update').setValue(this.resolution[0].id)
  //     }
  //   });
  // }

  getstaffByID(id) {
    if (id) {
      this.staffService.getStaffById(id).subscribe((res) => {
        this.staffAsId = res.Staff;
        return this.staffAsId.firstname;
      });
    }
  }
  settime(event) {
    this.entity.nextFollowupTime = event.value
  }

  setStatus() {
    this.formupdate.get('casestatus_update').setValue(this.entity.caseStatus);
  }

  setServiceToTeam(el) {
    this.serviceIdPass = el;
    this.formupdate.get('teamType').reset();
    this.formupdate.get('allocationType').reset();
    this.formupdate.get('teamId').reset();
    this.formupdate.get('groupId').reset();
    this.formupdate.get('algorithmType').reset();
    this.teamTypeList = null;
    this.algoTypeList = null;
    this.allocationList = null;
    //this.entity.teamType = null;
    this.entity.algorithmType = null;
    this.entity.teamId = null;
    this.entity.groupId = null;
     this.entity.currentAssigneeId = null;
     if(el !== null)
    this.getTeamType(el);
  }

  getTeamType(id: any) {
    this.CommonListService.getTeamTypeList(id).subscribe((res) => {
      this.teamTypeList = res.dataList;
      // this.entity.teamType = this.teamTypeList[0].name;
      // this.teamName = this.teamTypeList[0].name;
      const selectedTeamType=this.teamTypeList.find((el=>el.name==this.entity.teamType));
      if(selectedTeamType && selectedTeamType.teamTypeId){
        this.entity.teamTypeId=selectedTeamType.teamTypeId
           this.teamName = selectedTeamType.name;
            this.getGroupList();
      }
      //this.getTeamList();
    });
    //this.getallocationType();
  }

  getallocationType() {
    this.CommonListService.allocationType().subscribe((res) => {
      this.allocationList = res.dataList;
      this.entity.allocationType = this.allocationList[0].text;
    });
  }

  getalgoType() {
    this.CommonListService.algorithmType().subscribe((res) => {
      this.algoTypeList = null;
      if (this.entity.longitude == null && this.entity.latitude == null) {
        this.algoTypeList = res.dataList;
      } else {
        if (this.dynamicdata) {
          if ((this.dynamicdata['Latitude'] !== '-') || (this.dynamicdata['Longitude'] !== '-')) {
            this.algoTypeList = res.dataList;
          }
          else {
            this.algoTypeList = []
            this.algoTypeList = res.dataList.filter(res => res.text == "Least Allocation")
          }
        }
      }
    });
  }

  setTeamAPiCall(e) {
    if (e) {
      this.teamName = e;
      //this.groupName = e;
      this.TeamList = null
      this.staffList = null
      this.allocationList = null
      this.algoTypeList = null
      this.formupdate.get('allocationType').reset()
      this.formupdate.get('algorithmType').reset()
      this.formupdate.get('teamId').reset()
      this.formupdate.get('groupId').reset()
      this.formupdate.get('staffId').reset()
      this.getallocationType()
      this.getGroupList();
    }
    else if(e === null)
    {
      this.formupdate.get('allocationType').reset();
      this.formupdate.get('teamId').reset();
      this.formupdate.get('groupId').reset();
      this.formupdate.get('algorithmType').reset();
      this.algoTypeList = null;
      this.allocationList = null;
      //this.entity.teamType = null;
      this.entity.algorithmType = null;
      this.entity.teamId = null;
      this.entity.groupId = null;
       this.entity.currentAssigneeId = null;  
    }

  }

  allocationType(e) {
    this.formupdate.get('teamId').reset();
    this.formupdate.get('groupId').reset();
    this.formupdate.get('staffId').reset();
    this.formupdate.get('algorithmType').reset();
    if (e.value == 'Auto') {
      this.enableflag = true;
      this.getalgoType();
      delete this.entity.teamId
      delete this.entity.groupId
       delete this.entity.currentAssigneeId
      this.formupdate.get('algorithmType').updateValueAndValidity();
      this.formupdate.get('teamId').clearValidators();
      this.formupdate.get('teamId').updateValueAndValidity();
      this.formupdate.get('groupId').clearValidators();
      this.formupdate.get('groupId').updateValueAndValidity();
      this.formupdate.get('staffId').clearValidators();
      this.formupdate.get('staffId').updateValueAndValidity();
    }
    else if (e.value == 'Manual') {
      this.enableflag = false;
      delete this.entity.algorithmType
      //this.getalgoType();//*refresh
      //this.getTeamList();
    }
  }

  // setnewdata() {
  //   this.entity
  // }

  // getData(): CasesModel {
  //   return this.entity;
  // }

  // getSubscriberByID(id) {
  //   if (id) {
  //     this.Subscriber.getSubscriberById(id).subscribe((res) => {
  //       this.customer = res.data;
  //     });
  //   }

  // }

  public commonList() {

    this.CommonListService.getCommonListcaseFor().subscribe((res) => {
      if (this.Internalcase) {
        this.caseFor = res.dataList.filter(res => res.value === 'Internal')
      }
      else {
        this.caseFor = res.dataList.filter(res => res.value !== 'Internal')
      }
    });
    this.CommonListService.getCommonListcaseType().subscribe((res) => {
      this.caseType = res.dataList

    });
  
    this.CommonListService.getServiceAreabyTeamList().subscribe((res) => {
      this.serviceAreaList = res.dataList
    });

    this.CommonListService.algorithmType().subscribe((res) => {
      this.algorithmType = res.dataList
    });

    this.CommonListService.chargableType().subscribe((res) => {
      this.chargableType = res.dataList;
      });

    this.CommonListService.getCommonListcaseStatus().subscribe((res) => {
      this.caseStatus = res.dataList

    });
    this.CommonListService.getCommonListpriority().subscribe((res) => {
      this.priority = res.dataList

    });
  }

  

  public getActiveRoute(): ActivatedRoute {
    return this.activeRoute;
  }
  // public getNewModal(): CasesModel {
  //   // let abc = new CasesModel();
  //   // delete abc.caseReasonId
  //   return new CasesModel();
  // }


  // public getFormGroup(): FormGroup {
  //   return this.formupdate;
  // }

  // public getId(): number {
  //   return this.entity.caseId;

  // }

  get f() {
    return this.formupdate.controls;
  }
  get fc(){
    return this.circuitDetail.controls;
  }
  calculateDiff(ms, lable: string) {
    let currentDate = new Date();
    ms = new Date(ms);
    if (currentDate && ms) {
      ms = currentDate.getTime() - ms.getTime();
      let seconds = Math.floor(ms / 1000);
      let minutes = Math.floor(seconds / 60);
      let hours = Math.floor(minutes / 60);
      let days = Math.floor(hours / 24);
      if (lable === 'caseHours') {
        return hours;
      }
      if (lable === 'caseDay') {
        return days;
      }
      if (lable === 'caseMinutes') {
        return minutes;
      }
      if (lable === 'caseSecond') {
        return seconds;
      }
    }
  }
  numberOnly(event): boolean {

    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }

    return true;
  }

  setResolution(objValue) {
    // if (objValue === "Resolved") {
    //   this.formupdate.get('resolution_update').setValue(this.resolution[0].id)
    // }
    if (objValue === "Suspend") {
      this.formupdate.get('case_suspendtilldate_update').setValidators(Validators.required);
      this.formupdate.get('case_suspendtilldate_update').updateValueAndValidity();
    }
    else {
      this.formupdate.get('case_suspendtilldate_update').clearValidators()
      this.formupdate.get('case_suspendtilldate_update').updateValueAndValidity();
    }
  }

  updateFormSubmit() {
    
    this.submitted = true;
    if (this.formupdate.controls.case_message_update.value) {
    
      this.saveData.emit(this.entity)
    } else {
      this.toastrService.error('Please fill all details');
    }
  }

  getTeamList() {
    let obj = {
      "groupId": this.groupName,
      "serviceAreaIdList": [],
      "teamType": this.teamName
    }
    obj.serviceAreaIdList.push(this.serviceIdPass)
    this.CommonListService.teamListByManual(obj).subscribe((res) => {
      this.TeamList = res.dataList;
    const selectedTeam=this.TeamList.find((el=>el.name==this.entity.teamName));
    if(selectedTeam && selectedTeam.id){
      this.entity.teamId=selectedTeam.id
      this.selectStaff(selectedTeam)
    }
    });
  }

  getGroupList(){
    let obj={
      "serviceAreaIdList":[],
      "teamType": this.teamName
    }
    obj.serviceAreaIdList.push(this.serviceIdPass)
    this.CommonListService.groupListByManual(obj).subscribe((res) => {
      this.GroupList = res.dataList;
      const selectedGroup=this.GroupList.find((el=>el.groupName==this.entity.groupName));
      if(selectedGroup && selectedGroup.id){
        this.entity.groupId=selectedGroup.id
        this.groupName = selectedGroup.id
        this.getTeamList();
      }
    });
   }

   groupChange(e){
    this.formupdate.get('teamId').reset();
    this.formupdate.get('teamId').updateValueAndValidity();
    this.formupdate.get('staffId').reset();
    this.formupdate.get('staffId').updateValueAndValidity();
    this.TeamList = [];
    this.staffListArray = [];
    this.groupName = e
    this.getTeamList();
  }

  // getupdateListData() {
  //   if (this.entity && this.entity.caseUpdateList && this.entity.caseUpdateList.length > 0) {
  //     this.entity.caseUpdateList.forEach((res) => {
  //       res.updateDetails.forEach((x) => {
  //         if (x.entitytype === "Remarks") {
  //           res.remark = x.newvalue;
  //         }
  //         if (x.remarktype === "Public") {
  //           res.remarkType = x.remarktype;
  //         }
  //         if (x.remarktype === "Private") {
  //           res.remarkType = x.remarktype;
  //         }
  //       });
  //       let newDate = new Date(res.createDateString);
  //       res.calculatedDay = this.calculateDiff(newDate, 'caseDay');
  //       if (res.calculatedDay === 0)
  //         res.calculatedTimeH = this.calculateDiff(newDate, 'caseHours');
  //       if (res.calculatedTimeH === 0)
  //         res.calculatedTimeM = this.calculateDiff(newDate, 'caseMinutes');
  //       if (res.calculatedTimeM === 0) {
  //         res.calculatedTimeS = this.calculateDiff(newDate, 'caseSecond');
  //       }
  //     });
  //   }
  // }
  defaultSelectionAssignment(serviceareaid) {
    if(serviceareaid === null)
    {
      this.entity.serviceAreaId = -1;
      //this.entity.teamType = 'Back Office';
      this.setServiceToTeam(this.entity.serviceAreaId)
    }
    else if(serviceareaid !== null)
    {
      this.entity.serviceAreaId = serviceareaid;
      this.setServiceToTeam(this.entity.serviceAreaId)
    }
    
    setTimeout(() => {
      this.entity.allocationType = 'Manual';
    }, 100);
    // setTimeout(() => {
    //   this.getTeamList();
    // }, 200);
    // setTimeout(() => {
    //   if (this.TeamList.length > 0) {
    //     this.entity.teamId = this.TeamList[0].id
    //     this.selectStaff(this.TeamList[0]);
    //   }
    // }, 800);
  }


  cancelmodal() {
    this.CasesService.getCaseById(this.entity.caseId).subscribe((res) => {
      this.entity.priority = res.data.priority;
      this.entity.caseReasonId = res.data.caseReasonId;
      this.entity.templateId = res.data.templateId;
      this.entity.caseStatus = res.data.caseStatus;
      this.entity.amount = res.data.amount;
      this.entity.issue = res.data.issue;
      this.entity.solution = res.data.solution;
      this.entity.receipt_no = res.data.receipt_no;
      this.entity.chargableType = res.data.chargableType;
      this.entity.suspendDate = res.data.suspendDate;
      this.entity.caseTitle = res.data.caseTitle;
      this.saveData.emit(this.entity);
    });
    this.ngbModalService.dismissAll();
  }


  selectStaff(val: any) {
    //this.formupdate.get('staffId').updateValueAndValidity();
    this.staffListArray = val.teamUserMapping.filter(v => v.isAssignable === true)
    let temp = this.staffListArray.find((st) => st.staffName == this.entity.currentAssigneeName);
    if(temp && temp.staffId)
    this.entity.currentAssigneeId = temp.staffId
    //this.entity.currentAssigneeName = this.staffListArray.find((st) => st.staffName == this.entity.currentAssigneeName).staffName;
    // s = this.entity.staffId;
  }

  onEnterRemark() {
    if (this.updateList.remark) {
      this.error_remark = false;
      return;
    }
  }

  closeDialog() {
    this.dialogRef.close();
  }

  openResovled(resolvedpopup, event) {
    if (this.checkResolved == true)
      this.ngbRef = this.ngbModalService.open(resolvedpopup, { size: 'xl', backdrop: 'static' });
  }

  onchangeETR(){
    if(this.entity && this.entity.etr){
      this.updateList.etr = this.entity.etr;
    }
  }
  onchangeETRUnit(){
    if(this.entity&& this.entity.etrUnit){
      this.updateList.etrUnit=this.entity.etrUnit
    }
  }
  onchangenotify(){
    if(this.entity&&this.entity.notified_enable){
      this.updateList.notified_enable = this.entity.notified_enable;
    }
  }
  onchangecircuit(event,content){
    if(event.target.checked){
      this.formupdate.get('is_circuit_update_table').setValue(true);
      this.circuitService.getCircuitDetail(this.entity.circuitId).subscribe((res)=>{
        this.setUpdateValueInForm(res.data);    
      })

      this.circuitModalRef = this.ngbModalService.open(content, { size: 'xl', backdrop: 'static' });
      this.getQosPOlicyTyps()
      this.getCircuitType()
      this.connectionType()
      this.ConnectvityType()
      this.authType()
      this.deviceType()
    }else{
      this.formupdate.get('is_circuit_update_table').setValue(false);
    }
  }
  CloseModal() {
    if (this.circuitModalRef) {
      this.circuitModalRef.close();
      this.formupdate.get('is_circuit_update_table').setValue(false);
    }
  }
  notified_enable(event){
    if(event.target.checked){
      this.formupdate.get('notified_enable').setValue(true);
    }else{
      this.formupdate.get('notified_enable').setValue(false);
    }
  }
  onReasonchange(event){
    this.formupdate.get('etr').reset()
    this.formupdate.get('etrUnit').reset()
    if(event){
      this.formupdate.get('etr').setValue(event.etr)
      this.formupdate.get('etrUnit').setValue(event.etrUnit)
  }
  }
  connectionType() {
    this.circuitService.getConnectionType().subscribe(response => {
      this.connectionTypes = response.dataList;
    })
  }
  getCircuitType(){
    this.circuitService.getCircuitType().subscribe(response=>{
      this.circuitTypeData=response.dataList;
    })
  }
  getQosPOlicyTyps(){
    this.circuitService.getQosPolicyByType().subscribe(response=>{
      this.qosPolicyTypeData=response.dataList;
    })
}
deviceType() {
  this.circuitService.getDeviceType().subscribe(response => {
    this.deviceTypes = response.dataList;
  })
}

ConnectvityType() {
  this.circuitService.getConnectvityType().subscribe(response => {
    this.connectvityType = response.dataList;
  })
}

addip(){
  if(this.circuit.ipAddress && this.circuit.ipAddress.toString() !== ''){
    this.techObj.ipAddress = this.circuit.ipAddress.toString();
    let temp = Object.assign({}, this.techObj)
    
    if(this.ipList && this.ipList.length>0)
    {  
    
      let tempflag:any
      let sample:any=[]
      this.ipList.forEach((res)=>{
        sample.push(res.ipAddress)
      })
    tempflag = sample.includes(this.circuit.ipAddress)
     if(tempflag){
    this.toastrService.error("IP Already Exists!");
    this.circuit.ipAddress = null
      return
     }
     else{
      this.ipList.push(temp);
     }
    }
    else{
      this.ipList.push(temp);
    }
  
 
    this.refreshDataSources(this.ipList);
    this.circuitDetail.get('ipAddress').reset();
  }
  else{
    this.toastrService.error("IP Address is Empty!")
  }

}
refreshDataSources(data) {
  if (data) {
    this.DataSources = new MatTableDataSource(JSON.parse(JSON.stringify(data)));
  }
}
authType() {
  this.circuitService.getAuthType().subscribe(response => {
    this.authTypes = response.dataList;
  })
}
Updateip(){
this.isUpdate = false;
let tempflag:any
let sample:any=[]
this.ipList.forEach((res)=>{
  sample.push(res.ipAddress)
})
tempflag = sample.includes(this.circuit.ipAddress)
if(tempflag){
  this.toastrService.error("IP Already Exists!");
  this.circuit.ipAddress = null
    return
}
else{
  this.ipList[this.IpAddressIndex].ipAddress = this.circuit.ipAddress;
}

  this.refreshDataSources(this.ipList)
  this.circuitDetail.get('ipAddress').reset();
}
onEditClicks(data) {
  this.isUpdate = true;
  this.IpAddressIndex = this.ipList.findIndex(res => res.ipAddress === data.ipAddress)
  this.circuit.ipAddress = data.ipAddress;
}
onDeleteClicks(data) {
  this.circuit.ipAddress = null
  if(this.isUpdate){
    this.isUpdate =false
  }
  let temp = this.ipList.findIndex(res => res.ipAddress === data.ipAddress)
  this.ipList.splice(temp, 1);
  this.refreshDataSources(this.ipList);
}
getTableColumns() {
  let self = this;

  this.allColumns1 = [
    {
      colKey: 'index',
      default: 'ID',
      rowKey: 'id',
      width: '1%',
      isFilterAllow: false,
      isNumber: true,
    },
    {
      colKey: 'ipAddress',
      default: 'IP Address',
      rowKey: 'ipAddress',
      width: '10%',
      isNumber: false,
      isFilterAllow: true,
      enableEdit: true,
      enableDelete: true,
    },
    {
      colKey: 'actionCol',
      default: 'Action',
      rowKey: 'id',
      enableEdit: false,
      enableDelete: false,
      isNumber: false,
      width: '1%',
      isStickyEnd: true,
    }
  ];
  this.allColumns1.forEach(element => {
    element.isFilterAllow = false;
  });
  this.getTableSettings();
}
getTableSettings() {
  let circuitneogridsetting = {
    showFooter: false,
    showPagination: false,
    showFilter: false,
    tableId: this.tablekeyEnum.IP_LIST,
    enableMultiDelete: true,
    columns: this.allColumns1,

  };
  this.circuitneogridsetting = circuitneogridsetting
  return circuitneogridsetting
}
ngAfterViewInit(){
  this.allColumns1.forEach((col) => {
    if (col.colKey == 'actionCol') {
      col.customControls = [this.actionBtnReff1];
    }
  });
}
onSubmit() {
  this.validateForm();
}
validateForm() {
  this.csubmitted =  true
  if(this.circuit.didNos){
    this.circuitDetail.get('pilotNo').setErrors(null);
    this.circuitDetail.get('pilotNo').setValidators([Validators.required]);
    this.circuitDetail.get('pilotNo').updateValueAndValidity();
  }
  else{
    this.circuitDetail.get('pilotNo').setErrors(null);
    this.circuitDetail.get('pilotNo').clearValidators();
    this.circuitDetail.get('pilotNo').updateValueAndValidity();
  }
  if (!this.circuitDetail.valid) {
    Object.keys(this.circuitDetail.controls).forEach(field => {
      let control = this.circuitDetail.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      }
    })
    this.toastrService.error('Please fill all details');
  } else {
 
      if(this.circuit && (this.circuit.didNos ||  this.circuit.pilotNo )&& this.circuit.circuitType==='SIP'){
        
        let didList
        if(this.circuit.didNos){
          didList = this.circuit.didNos.split(',');
          if(didList  && didList.length>0 && didList.includes(this.circuit.pilotNo)){
            this.update();
          }
          else{
            this.toastrService.error("Pilot No. must be in DID No.");
          }
          
        }else{
          this.toastrService.error("Please enter DID No.");
        }
      
            
        }else{
          this.update();
        }  
  }
}
update() {
  if(this.circuit.authType === 'P2P'){
    if(this.ipList.length != 0){
      this.circuit.circuitIpAddressDetails = this.ipList;
    } else {
      this.toastrService.error("Please Enter IP Address");
      return;
    }
  }
  this.circuit.conectinvalue = this.circuitDetail.get('conectinvalue').value;
  this.circuit.circuitType=this.circuitDetail.get('circuitType').value;
  this.circuit.deviceid=this.circuitDetail.get('deviceId').value
  if(this.circuit.circuitType==="Internet"){
    this.circuit.connectionType=null;
  }
  
  this.circuitService.updateProperty(this.circuit).subscribe(response => {
    if (response.responseCode === 200) {
      this.toastrService.success("Data has been successfully update");
    } else {
      this.toastrService.error(response.responseMessage);
    }
    this.CloseModal();
  })
}
setUpdateValueInForm(rowData) {
  this.circuit=rowData
  if(this.circuit.circuitIpAddressDetails){
    this.ipList = [];
    this.circuit.circuitIpAddressDetails.forEach((res) =>{
      if(res){
        this.techObj.ipAddress = res.ipAddress;
        let temp = Object.assign({}, this.techObj)      
        this.ipList.push(temp);
        this.refreshDataSources(this.ipList);
      }
    })
    
  }
  if(this.circuit.circuitType==='SIP'){
    this.flagforcircuit=true;
  }else{
    this.flagforcircuit=false;
  }

  this.circuit.didNos = rowData.didNos;
  this.circuit.pilotNo = rowData.pilotNo;
  this.circuit.noOfChannels = rowData.noOfChannels;
  this.circuit.connectionType = rowData.connectionType;

  if(this.circuit.connectionType==="Credential Based" && this.connectionTypeflag){
    this.circuitDetail.get('connectionType').patchValue(this.circuit.connectionType);
    this.connectionTypeflag=true;/**New */
    this.circuit.userName = rowData.userName;
    this.circuit.password = rowData.password;
  }else{
    this.circuitDetail.get('connectionType').patchValue(this.circuit.connectionType);
    this.circuit.ipAddress = rowData.ipAddress;
    this.connectionTypeflag=false; /**New */
  }
  this.circuitDetail.patchValue(rowData);
}
newCircuitGenerate(data){
  if(data.value !==null){
    if(data.value!=='SIP'){
      if(this.circuit.authType){
       this.circuit.authType=null
       this.circuit.circuitIpAddressDetails=null
      }
    }
    this.circuitService.generateCircuitid(data.value).subscribe(res=>{ 
      if(data.value==='SIP'){
        this.circuit.circuitid = res.data.circuit_id;
        this.circuit.circuitidentity=res.data.circuit_id;
        this.flagforcircuit=true;
        this.resetBySIP();
      }else{
        this.circuit.circuitid = res.data.circuit_id;
        this.circuit.circuitidentity=res.data.circuit_id;
        this.flagforcircuit=false;
        this.resetByFlagForInternet();
      }
    })
  }
}
resetBySIP(){

  this.circuitDetail.get('bandwith_policyName').setValue(null);
  this.circuitDetail.get('bandwith_policyName').clearValidators();
  this.circuitDetail.get('bandwith_policyName').reset();

  this.circuitDetail.get('static_Ippool').setValue(null);
  this.circuitDetail.get('static_Ippool').clearValidators();
  this.circuitDetail.get('static_Ippool').reset();
  
  this.circuitDetail.get('connectvityType').setValue(null);
  this.circuitDetail.get('connectvityType').clearValidators();
  this.circuitDetail.get('connectvityType').reset();
  

  this.circuitDetail.get('conectinvalue').setValue(null);
  this.circuitDetail.get('conectinvalue').clearValidators();
  this.circuitDetail.get('conectinvalue').reset();
  
  this.circuitDetail.get('typeDevice').setValue(null);
  this.circuitDetail.get('typeDevice').clearValidators();
  this.circuitDetail.get('typeDevice').reset();
  
  this.circuitDetail.get('otherValue').setValue(null);
  this.circuitDetail.get('otherValue').clearValidators();
  this.circuitDetail.get('otherValue').reset();

  this.circuitDetail.updateValueAndValidity();
 }
 resetByFlagForInternet(){
    
  this.circuitDetail.get('didNos').clearValidators();
  this.circuitDetail.get('didNos').updateValueAndValidity();
  this.circuitDetail.get('pilotNo').clearValidators();
  this.circuitDetail.get('pilotNo').updateValueAndValidity();
  this.circuitDetail.get('userName').clearValidators();
  this.circuitDetail.get('userName').updateValueAndValidity();
  this.circuitDetail.get('password').clearValidators();
  this.circuitDetail.get('password').updateValueAndValidity();
  this.circuitDetail.get('ipAddress').clearValidators();
  this.circuitDetail.get('ipAddress').updateValueAndValidity();
  this.circuitDetail.get('noOfChannels').clearValidators();
  this.circuitDetail.get('noOfChannels').updateValueAndValidity();

  // this.circuitDetail.get('didNos').setValue(null);
  this.circuitDetail.get('didNos').reset();
  // this.circuitDetail.get('pilotNo').setValue(null);
  this.circuitDetail.get('pilotNo').reset();

  // this.circuitDetail.get('userName').setValue(null);
  this.circuitDetail.get('userName').reset();
  // this.circuitDetail.get('password').setValue(null);
  this.circuitDetail.get('password').reset();
  // this.circuitDetail.get('ipAddress').setValue(null);
  this.circuitDetail.get('ipAddress').reset();
  // this.circuitDetail.get('noOfChannels').setValue(null);
  this.circuitDetail.get('noOfChannels').reset();

  


  this.circuitDetail.updateValueAndValidity();
}
onchangetypeofdevice(e){
  this.circuitDetail.get('deviceId').reset()
 if(e && e.value){
    let value = e.value;
    this.selected_change_type_of_device = value;   
  }
}
authTypeChange(data){
    
  if(data.value==="P2P"){
    this.ipList = [];
    this.refreshDataSources(this.ipList);
   this.circuitDetail.get('userName').clearValidators();
  // this.circuitDetail.get('userName').setErrors(null);
   this.circuitDetail.get('userName').updateValueAndValidity();
   this.circuitDetail.get('password').clearValidators();
  // this.circuitDetail.get('password').setErrors(null);
   this.circuitDetail.get('password').updateValueAndValidity();
  
   //this.circuitDetail.get('ipAddress').setValidators(Validators.required);
   //,Validators.pattern('(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)'
   //this.circuitDetail.get('ipAddress').updateValueAndValidity();

  }
  else{
  
  this.circuitDetail.get('ipAddress').clearValidators();
 // this.circuitDetail.get('ipAddress').setErrors(null);
  this.circuitDetail.get('ipAddress').updateValueAndValidity();

  this.circuitDetail.get('userName').setValidators(Validators.required);
  this.circuitDetail.get('userName').updateValueAndValidity();

  this.circuitDetail.get('password').setValidators(Validators.required);
  this.circuitDetail.get('password').updateValueAndValidity();
  
  }
  }
  onPress(event){
    if (event.keyCode == 32) {
      event.preventDefault();
    }
  }
}
