import { Injectable } from '@angular/core';
import { RemarkTemplateModel } from '../model/remark-template-model';
import { BaseService } from '../../../../services/base-service';
import { HttpClient } from '@angular/common/http';

const BASE_API_URL = '';

@Injectable({
  providedIn: 'root'
})

export class RemarkTemplateService extends BaseService<RemarkTemplateModel> {
  
  constructor(http: HttpClient) {
    super(http);
  }
  
  getModuleName(): string {
    return 'remarkTemplate';
  }

  validate(): boolean {
    return false;
  }

  getStaff(id) {
    const get_url = BASE_API_URL+'staffuserByRole/' + id;
    return this.http.get<any>(get_url);
  }
}
