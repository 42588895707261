import { Injectable } from '@angular/core';
import { BaseService } from '../../../services/base-service';
import { Team } from './model/Team';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
}
const BASE_API_URL = '';
@Injectable({
  providedIn: 'root'
})

export class TeamServiceService extends BaseService<Team>  {


  constructor(http: HttpClient) {
    super(http);
  }
  validate(): boolean {
    return false;
  }
  getModuleName(): string {
    return "teams";
  }

  getTeamById(id) {
    const get_url = BASE_API_URL + 'teams/'+ id;
    return this.http.get<any>(get_url,
      { headers: httpOptions.headers }).pipe(
        map(res => res),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }

  getcaseReason() {
    const get_url = BASE_API_URL + 'caseReason/getAll';
    return this.http.get<any>(get_url,
      { headers: httpOptions.headers }).pipe(
        map(res => res),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }
  // getGroupList() {
  //   const get_url = BASE_API_URL + 'group';
  //   return this.http.get<any>(get_url,
  //     { headers: httpOptions.headers }).pipe(
  //       map(res => res),
  //       catchError((error: any) => {
  //         return throwError(error);
  //       })
  //     );
  // }

  getGroupList(): Observable<any> {
    const get_url = BASE_API_URL + 'group' ;
    return this.http.post(get_url,httpOptions);
  }


  deleteStaff(team): Observable<any> {
    const delete_url = BASE_API_URL + 'teams/deleteStaffFromTeam' ;
    return this.http.post(delete_url,team,httpOptions);
  }
  deletecaseReason(team): Observable<any> {
    const delete_url = BASE_API_URL + 'teams/deleteCaseReasonFromTeam' ;
    return this.http.post(delete_url,team,httpOptions);
  }
  getStaffByServiceArea(data){
      const post_url = BASE_API_URL + 'getAllStaffByServiceArea';
      return this.http.post<any>(post_url, data, httpOptions)
        .pipe(map(res => res),
        catchError((error: any) => {
          return throwError(error);
        })
        );
  }


}
