export const AclConstants = {
    
    OPERATION_CITY_ALL : 1,
    OPERATION_CITY_VIEW : 2,
    OPERATION_CITY_ADD : 3,
    OPERATION_CITY_EDIT : 4,
    OPERATION_CITY_DELETE : 5,

    OPERATION_STATE_ALL : 6,
    OPERATION_STATE_VIEW : 7,
    OPERATION_STATE_ADD : 8,
    OPERATION_STATE_EDIT : 9,
    OPERATION_STATE_DELETE : 10,

    OPERATION_COUNTRY_ALL : 11,
    OPERATION_COUNTRY_VIEW : 12,
    OPERATION_COUNTRY_ADD : 13,
    OPERATION_COUNTRY_EDIT : 14,
    OPERATION_COUNTRY_DELETE : 15,

    OPERATION_PARTNER_ALL : 16,
    OPERATION_PARTNER_VIEW : 17,
    OPERATION_PARTNER_ADD : 18,
    OPERATION_PARTNER_EDIT : 19,
    OPERATION_PARTNER_DELETE : 20,

    OPERATION_PARTNER_CONFIG_ALL:306,


    OPERATION_BILLING_ALL : 164,
    OPERATION_BILLING_VIEW : 165,
    OPERATION_BILLING_ADD : 166,
    OPERATION_BILLING_EDIT : 167,
    OPERATION_BILLING_DELETE : 168,

    OPERATION_CUSTOMER_ALL : 21,
    OPERATION_CUSTOMER_VIEW : 22,
    OPERATION_CUSTOMER_ADD : 23,
    OPERATION_CUSTOMER_QUICK_ADD:391,
    OPERATION_CUSTOMER_EDIT : 24,
    OPERATION_CUSTOMER_DELETE : 25,

    OPERATION_CUSTOMER_VIEW_QOS : 26,
    OPERATION_CUSTOMER_UPDATE_QOS : 27,
    OPERATION_CUSTOMER_VIEW_QUOTA : 28,
    OPERATION_CUSTOMER_UPDATE_QUOTA : 29,
    OPERATION_CUSTOMER_VIEW_EXPIRY : 30,
    OPERATION_CUSTOMER_UPDATE_EXPIRY : 31,
    OPERATION_CUSTOMER_VIEW_STATUS : 32,
    OPERATION_CUSTOMER_UPDATE_STATUS : 33,
    OPERATION_CUSTOMER_VIEW_VOICE_DETAILS : 34,
    OPERATION_CUSTOMER_UPDATE_VOICE_DETAILS : 35,
    OPERATION_CUSTOMER_APPLY_CHARGE : 36,
    OPERATION_CUSTOMER_ROLLBACK_CHARGE : 37,
    OPERATION_CUSTOMER_PURCHASE_IP : 38,
    OPERATION_CUSTOMER_ALLOCATE_IP : 39,
    OPERATION_CUSTOMER_RENEW_IP : 40,
    OPERATION_CUSTOMER_RELEASE_IP : 41,
    OPERATION_CUSTOMER_MANAGE_IP_PURCHASE : 42,
    OPERATION_CUSTOMER_RECORD_PAYMENT : 43,
    OPERATION_CUSTOMER_ROLLBACK_PAYMENT : 44,
    OPERATION_CUSTOMER_UPDATE_PAYMENT : 45,
    OPERATION_CUSTOMER_ADJUSTMENTS : 46,
    OPERATION_CUSTOMER_UPDATE_CONTACT_DETAILS : 47,
    OPERATION_CUSTOMER_UPDATE_BASIC_DETAILS : 48,
    OPERATION_CUSTOMER_UPDATE_NETWORK_DETAILS : 49,
    OPERATION_CUSTOMER_UPDATE_ADDRESS_DETAILS : 50,
    OPERATION_CUSTOMER_UPDATE_OTHER_DETAILS : 51,
    OPERATION_CUSTOMER_CHANGE_SELFCARE_PASSWORD : 52,
    OPERATION_CUSTOMER_RESET_SELFCARE_PASSWORD : 53,
    OPERATION_CUSTOMER_CHANGE_CPE_PASSWORD : 54,
    OPERATION_CUSTOMER_RESET_CPE_PASSWORD : 55,
    OPERATION_CUSTOMER_RESET_MAC : 56,
    OPERATION_CUSTOMER_MANAGE_MAC : 57,
    OPERATION_CUSTOMER_CREATE_TICKET : 58,
    OPERATION_CUSTOMER_VIEW_PLANS : 174,
    OPERATION_CUSTOMER_CHANGE_PLAN : 175,
    OPERATION_CUSTOMER_PURCHASE_PLAN_ADON:398,
    OPERATION_CUSTOMER_CANCEL_PLAN : 176,
    OPERATION_CUSTOMER_UPDATE_PROFILE : 177,
    OPERATION_CUSTOMER_VIEW_PAYMENT_HISTORY : 178,
    OPERATION_CUSTOMER_VIEW_PURCHASE_HISTORY : 179,
    OPERATION_CUSTOMER_GET_PAYMENT_RECEIPT : 180,
    OPERATION_CUSTOMER_GET_PURCHASE_INVOICE : 181,
    OPERATION_CUSTOMER_GET_DOCUMENT : 182,
    OPERATION_CUSTOMER_REPLACE_IP : 183,
    OPERATION_CUSTOMER_ROLLBACK_IP : 184,
    OPERATION_CUSTOMER_CHANGE_IP_EXPIRY : 185,
    OPERATION_CUSTOMER_STATUS_REPORT:53,
    OPERATION_CUSTOMER_LEDGER: 254,
    OPERATION_CUSTOMER_VIEW_PASSWORD: 256,
    OPERATION_CUSTOMER_LOCATION_DETAILS: 255,
    OPERATION_CUSTOMER_ACTIVATE_PLAN:330,
    OPERATION_AADHAR_VERIFICATION:289,
    OPERATION_CUSTOMER_PURCHASE_VAS:378,
    OPERATION_CUSTOMER_IPTV:379,
    OPERATION_CUSTOMER_EDIT_USERNAME:479,
    OPERATION_CUSTOMER_DATA_CONSUMPTION:497,

    OPERATION_REMARKTEMPLATE_ALL :498,
    OPERATION_REMARKTEMPLATE_VIEW :499,
    OPERATION_REMARKTEMPLATE_ADD :500,
    OPERATION_REMARKTEMPLATE_EDIT :501,
    OPERATION_REMARKTEMPLATE_DELETE :502,

    OPERATION_CLIENTS_ALL : 59,
    OPERATION_CLIENTS_VIEW : 60,
    OPERATION_CLIENTS_ADD : 61,
    OPERATION_CLIENTS_EDIT : 62,
    OPERATION_CLIENTS_DELETE : 63,

    OPERATION_CLIENT_GROUP_ALL : 64,
    OPERATION_CLIENT_GROUP_VIEW : 65,
    OPERATION_CLIENT_GROUP_ADD : 66,
    OPERATION_CLIENT_GROUP_EDIT : 67,
    OPERATION_CLIENT_GROUP_DELETE : 68,

    OPERATION_ACCOUNTING_PROFILE_ALL : 69,
    OPERATION_ACCOUNTING_PROFILE_VIEW : 70,
    OPERATION_ACCOUNTING_PROFILE_ADD : 71,
    OPERATION_ACCOUNTING_PROFILE_EDIT : 72,
    OPERATION_ACCOUNTING_PROFILE_DELETE : 73,

    OPERATION_RADIUS_PROFILE_ALL : 74,
    OPERATION_RADIUS_PROFILE_VIEW : 75,
    OPERATION_RADIUS_PROFILE_ADD : 76,
    OPERATION_RADIUS_PROFILE_EDIT : 77,
    OPERATION_RADIUS_PROFILE_DELETE : 78,

    OPERATION_DYNAAUTH_POLICY_ALL : 331,
    OPERATION_DYNAAUTH_POLICY_VIEW : 332,
    OPERATION_DYNAAUTH_POLICY_ADD : 333,
    OPERATION_DYNAAUTH_POLICY_EDIT : 334,
    OPERATION_DYNAAUTH_POLICY_DELETE : 335,

    OPERATION_AUTH_RESPONSE_ALL : 79,
    OPERATION_AUTH_RESPONSE_VIEW : 80,
    OPERATION_AUTH_RESPONSE_ADD : 81,
    OPERATION_AUTH_RESPONSE_EDIT : 82,
    OPERATION_AUTH_RESPONSE_DELETE : 83,

    OPERATION_DBCDR_PROCESSING_ALL : 84,
    OPERATION_DBCDR_PROCESSING_VIEW : 85,
    OPERATION_DBCDR_PROCESSING_ADD : 86,
    OPERATION_DBCDR_PROCESSING_EDIT : 87,
    OPERATION_DBCDR_PROCESSING_DELETE : 88,

    OPERATION_LIVE_USER_ALL : 89,
    OPERATION_LIVE_USER_VIEW : 90,
    OPERATION_LIVE_USER_ADD : 91,
    OPERATION_LIVE_USER_EDIT : 92,
    OPERATION_LIVE_USER_DELETE : 93,

    OPERATION_POSTPAID_PLAN_ALL : 94,
    OPERATION_POSTPAID_PLAN_VIEW : 95,
    OPERATION_POSTPAID_PLAN_ADD : 96,
    OPERATION_POSTPAID_PLAN_EDIT : 97,
    OPERATION_POSTPAID_PLAN_DELETE : 98,

    OPERATION_TAX_ALL : 99,
    OPERATION_TAX_VIEW : 100,
    OPERATION_TAX_ADD : 101,
    OPERATION_TAX_EDIT : 102,
    OPERATION_TAX_DELETE : 103,

    OPERATION_CHARGE_ALL : 104,
    OPERATION_CHARGE_VIEW : 105,
    OPERATION_CHARGE_ADD : 106,
    OPERATION_CHARGE_EDIT : 107,
    OPERATION_CHARGE_DELETE : 108,

    OPERATION_DISCOUNT_ALL : 109,
    OPERATION_DISCOUNT_VIEW : 110,
    OPERATION_DISCOUNT_ADD : 111,
    OPERATION_DISCOUNT_EDIT : 112,
    OPERATION_DISCOUNT_DELETE : 113,

    OPERATION_PLAN_SERVICE_ALL : 114,
    OPERATION_PLAN_SERVICE_VIEW : 115,
    OPERATION_PLAN_SERVICE_ADD : 116,
    OPERATION_PLAN_SERVICE_EDIT : 117,
    OPERATION_PLAN_SERVICE_DELETE : 118,

    OPERATION_QOS_POLICY_ALL : 119,
    OPERATION_QOS_POLICY_VIEW : 120,
    OPERATION_QOS_POLICY_ADD : 121,
    OPERATION_QOS_POLICY_EDIT : 122,
    OPERATION_QOS_POLICY_DELETE : 123,

    OPERATION_PRICE_BOOK_ALL : 124,
    OPERATION_PRICE_BOOK_VIEW : 125,
    OPERATION_PRICE_BOOK_ADD : 126,
    OPERATION_PRICE_BOOK_EDIT : 127,
    OPERATION_PRICE_BOOK_DELETE : 128,

    OPERATION_SERVICE_AREA_ALL : 129,
    OPERATION_SERVICE_AREA_VIEW : 130,
    OPERATION_SERVICE_AREA_ADD : 131,
    OPERATION_SERVICE_AREA_EDIT : 132,
    OPERATION_SERVICE_AREA_DELETE : 133,

    OPERATION_NETWORK_DEVICES_ALL : 134,
    OPERATION_NETWORK_DEVICES_VIEW : 135,
    OPERATION_NETWORK_DEVICES_ADD : 136,
    OPERATION_NETWORK_DEVICES_EDIT : 137,
    OPERATION_NETWORK_DEVICES_DELETE : 138,

    OPERATION_IP_POOL_ALL : 139,
    OPERATION_IP_POOL_VIEW : 140,
    OPERATION_IP_POOL_ADD : 141,
    OPERATION_IP_POOL_EDIT : 142,
    OPERATION_IP_POOL_DELETE : 143,
    OPERATION_IP_POOL_DETAIL_VIEW : 347,

    OPERATION_CASE_ALL : 144,
    OPERATION_CASE_VIEW : 145,
    OPERATION_CASE_ADD : 146,
    OPERATION_CASE_EDIT : 147,
    OPERATION_CASE_DELETE : 148,
    OPERATION_CASE_BULK_UPDATE:386,

    OPERATION_INTERNAL_CASE_ALL : 310,
    OPERATION_INTERNAL_CASE_VIEW : 311,
    OPERATION_INTERNAL_CASE_ADD : 312,
    OPERATION_INTERNAL_CASE_EDIT : 313,
    OPERATION_INTERNAL_CASE_DELETE :314,
    OPERATION_INTERNAL_CASE_UPDATE_DELETE : 326,
    
    OPERATION_INTERNAL_CASE_BULK_UPDATE:387,

    OPERATION_CASE_REASON_ALL : 149,
    OPERATION_CASE_REASON_VIEW : 150,
    OPERATION_CASE_REASON_ADD : 151,
    OPERATION_CASE_REASON_EDIT : 152,
    OPERATION_CASE_REASON_DELETE : 153,

    OPERATION_STAFF_USER_ALL : 154,
    OPERATION_STAFF_USER_VIEW : 155,
    OPERATION_STAFF_USER_ADD : 156,
    OPERATION_STAFF_USER_EDIT : 157,
    OPERATION_STAFF_USER_DELETE : 158,
    OPERATION_STAFF_USER_CHANGE_PASSWORD :186,
    OPERATION_STAFF_UPDATE_PASSWORD:297,
    OPERATION_STAFF_GET_TRACK:307,
    OPERATION_STAFF_AVAILABLE:308,

    OPERATION_BROAD_CAST_ALL : 159,
    OPERATION_BROAD_CAST_VIEW : 160,
    OPERATION_BROAD_CAST_ADD : 161,
    OPERATION_BROAD_CAST_EDIT : 162,
    OPERATION_BROAD_CAST_DELETE : 163,

    OPERATION_XSLT_MANAGEMENT_ALL : 164,
    OPERATION_XSLT_MANAGEMENT_VIEW : 165,
    OPERATION_XSLT_MANAGEMENT_ADD : 166,
    OPERATION_XSLT_MANAGEMENT_EDIT : 167,
    OPERATION_XSLT_MANAGEMENT_DELETE : 168,

    OPERATION_ROLE_ALL : 169,
    OPERATION_ROLE_VIEW : 170,
    OPERATION_ROLE_ADD : 171,
    OPERATION_ROLE_EDIT : 172,
    OPERATION_ROLE_DELETE : 173,

    OPERATION_TEAMS_ALL : 187,
    OPERATION_TEAMS_VIEW : 188,
    OPERATION_TEAMS_ADD : 189,
    OPERATION_TEAMS_EDIT : 190,
    OPERATION_TEAMS_DELETE : 191,

    OPERATION_NOTIFICATION_ALL :192,
    OPERATION_NOTIFICATION_VIEW :193,
    OPERATION_NOTIFICATION_ADD :194,
    OPERATION_NOTIFICATION_EDIT :195,
    OPERATION_NOTIFICATION_DELETE :196,

    OPERATION_MANAGE_PROFILE_ALL : 199,
    OPERATION_MANAGE_PROFILE_PASSWORD : 200,
    OPERATION_MANAGE_PROFILE_DETAILS : 201,
    OPERATION_MANAGE_PROFILE_TOPUP : 202,
    OPERATION_MANAGE_PROFILE_ASSOCIATE_PLANS : 203,
    OPERATION_MANAGE_PROFILE_COMMISSION : 204,
    OPERATION_MANAGE_PROFILE_PAYMENTS : 205,

    OPERATION_PINCODE_ALL : 212,
    OPERATION_PINCODE_VIEW : 213,
    OPERATION_PINCODE_ADD : 214,
    OPERATION_PINCODE_EDIT : 215,
    OPERATION_PINCODE_DELETE : 216,

    OPERATION_AREA_ALL : 217,
    OPERATION_AREA_VIEW : 218,
    OPERATION_AREA_ADD : 219,
    OPERATION_AREA_EDIT : 220,
    OPERATION_AREA_DELETE : 221,

    OPERATION_RECENT_RENEWAL_REPORT_VIEW :222,
    OPERATION_CHARGE_DETAILS_REPORT_VIEW : 223,
    OPERATION_PURCHASE_HISTORY_REPORT_VIEW : 227,
    OPERATION_COMMUNICATION_REPORT_VIEW: 299,
    OPERATION_PAYMENT_REPORT_VIEW:282,
    OPERATION_CHANGE_EXPIRY_PLAN_VIEW:284,
    OPERATION_SALES_REPORT_VIEW:377,
    OPERATION_FIBER_REPORT_VIEW:381,
    OPERATION_CUSTOMER_STATUS_REPORT_VIEW:285,
    OPERATION_PAYMENT_COLLECTION_REPORT_VIEW:380,
    OPERATION_INVOICE_REPORT_VIEW:388,
    OPERATION_INVOICE_DETAIL_REPORT_VIEW:389,
    OPERATION_CAF_VIEW:390,
    OPERATION_CASE_STATUS_BULK:197,
    OPERATION_NETWORK_BULK:198,
    OPERATION_TICKET_CREATE_BULK:228,
    OPERATION_MAC_RESET_BULK:229,
    OPERATION_ALL_BULK:230,
    
    OPERATION_VAS_ALL:231,
    OPERATION_VAS_VIEW :232,
    OPERATION_VAS_ADD :233,
    OPERATION_VAS_EDIT :234,
    OPERATION_VAS_DELETE :235,

    OPERATION_DUNNING_ALL:316,
    OPERATION_DUNNING_VIEW :317,
    OPERATION_DUNNING_ADD :318,
    OPERATION_DUNNING_EDIT :319,
    OPERATION_DUNNING_DELETE :320,

    OPERATION_SMS_PROVIDER_ALL:242,
    OPERATION_SMS_PROVIDER_VIEW:243,
    OPERATION_SMS_PROVIDER_ADD:244,
    OPERATION_SMS_PROVIDER_EDIT:245,
    OPERATION_SMS_PROVIDER_DELETE:246,
    
    OPERATION_NETWORK_STATUS_VIEW:252,


    OPERATION_NOTIFICATION_GROUP_ALL:247,
    OPERATION_NOTIFICATION_GROUP_VIEW:248,
    OPERATION_NOTIFICATION_GROUP_ADD:249,
    OPERATION_NOTIFICATION_GROUP_EDIT:250,
    OPERATION_NOTIFICATION_GROUP_DELETE:251,

    OPERATION_BASE_SETUP_ALL: 486,
    OPERATION_BASE_SETUP_VIEW: 487,
    OPERATION_BASE_SETUP_ADD: 488,
    OPERATION_BASE_SETUP_EDIT: 489,
    OPERATION_BASE_SETUP_DELETE: 490,
    
    OPERATION_SUBSCRIBER_LEASEDLINE_ALL: 277,
    OPERATION_SUBSCRIBER_LEASEDLINE_VIEW: 278,
    OPERATION_SUBSCRIBER_LEASEDLINE_ADD: 279,
    OPERATION_SUBSCRIBER_LEASEDLINE_EDIT: 280,
    OPERATION_SUBSCRIBER_LEASEDLINE_DELETE: 281,
    OPERATION_SUBSCRIBER_LEASEDLINE_CIRCUIID_EDIT: 480,
    OPERATION_NETWORK_SEARCH_VIEW:283,
    OPERATION_CUSTOMER_UPDATE_PARTNER:286,
    OPERATION_CUSTOMER_CHANGE_SALES_REPRESENTATIVE:300,
    OPERATION_MOBILE_VERIFICATION:288,
    OPERATION_CUSTOMER_DOCUMENT_VERIFICATION:287,
    OPERATION_NEW_REGISTERED_SUBSCRIBER_REPORT_VIEW:290,
    OPERATION_EXPIRED_REPORT_VIEW:291,
    OPERATION_EXPIRED_REPORT_BULK_RENEW:303,
    OPERATION_MTTR_REPORT_VIEW:296,
    OPERATION_INTERNALCASE_REPORT_VIEW:328,

    OPERATION_CUSTOMER_MANAGE_VLAN:293,
    OPERATION_CUSTOMER_MANAGE_AUTO_GENERATED_PASSWORD:341,

    OPERATION_PARTNER_MANAGE_IP_POOL:292,
    OPERATION_CASE_UPDATE_DELETE:298,
    OPERATION_AUDIT_LOG_VIEW:301,
    OPERATION_SUBSCRIBER_REPORT_ALL:305,

    OPERATION_CUSTOMER_DOCUMENT_VIEW:342,
    OPERATION_CUSTOMER_DOCUMENT_ADD:343,
    OPERATION_CUSTOMER_DOCUMENT_EDIT:344,
    OPERATION_CUSTOMER_DOCUMENT_DELETE:345,

    OPERATION_COMMENT_ALL :492,
    OPERATION_COMMENT_VIEW :493,
    OPERATION_COMMENT_ADD :494,
    OPERATION_COMMENT_EDIT :495,
    OPERATION_COMMENT_DELETE :496,

    OPERATION_CUSTOMER_REFUND_OPTION:346,

    
    OPERATION_BULKHISTORY_VIEW:304,
    OPERATION_NETWORK_TREE_VIEW:315,
    OPERATION_FRANCHISE_DASHBOARD_VIEW:327,
    OPERATION_PARNER_PROFILE_UPDATE:353,
    OPERATION_DASHBOARD_COUNT_VIEW:309,
    OPERATION_DASHBOARD_BULK_HISTORY_COUNT:360,
    OPERATION_DASHBOARD_ONLINE_PURCHASE_COUNT:361,
    OPERATION_DASHBOARD_CASE_STATUS_COUNT:362,
    OPERATION_DASHBOARD_CUSTOMER_DATA_COUNT:363,
    OPERATION_LIVE_DASHBOARD_VIEW:329,
    OPERATION_CASE_DASHBOARD_VIEW:384,
    OPERATION_DASHBOARD_All:309,

    OPERATION_ADDRESS_ALL:336,
    OPERATION_ADDRESS_VIEW:337,
    OPERATION_ADDRESS_ADD:338,
    OPERATION_ADDRESS_EDIT:339,
    OPERATION_ADDRESS_DELETE:340,
    
    OPERATION_VAS_PROVIDER_ALL:354,
    OPERATION_VAS_PROVIDER_VIEW:355,
    OPERATION_VAS_PROVIDER_ADD:356,
    OPERATION_VAS_PROVIDER_EDIT:357,
    OPERATION_VAS_PROVIDER_DELETE:359,
    OPERATION_INVENTORY_VIEW: 5000,
    OPERATION_PARTNER_ADD_BALANCE:321,
    OPERATION_CUSTOMER_SUSPENSION_EXTENSION:358,
    OPERATION_CYBER_CRIME_REPORT_VIEW:364,
    OPERATION_RADIUS_AUDIT_ALL: 376,
    OPERATION_LEASE_LINE_REPORT_VIEW:363,
    OPERATION_AADHAR_REPORT_VIEW:385, 
    OPERATION_PROFILE_PARTNER_CONFIGURTION:383,
    OPERATION_PARTNER_ACL_CONFIGURATION:382,
    OPERATION_PLAN_ACTIVATION:397,

    OPERATION_PROPERTY_DOC_ALL:392,
    OPERATION_PROPERTY_DOC_VIEW:393,
    OPERATION_PROPERTY_DOC_ADD:394,
    OPERATION_PROPERTY_DOC_EDIT:395,
    OPERATION_PROPERTY_DOC_DELETE:396,
    OPERATION_BLOCK_STATUS:467,
    OPERATION_PERFORMANCE_REPORT_VIEW :477,
    OPERATION_CASE_COUNT_REPORT_VIEW :491,

    OPERATION_GROUP_ALL:472,
    OPERATION_GROUP_VIEW:473,
    OPERATION_GROUP_ADD:474,
    OPERATION_GROUP_EDIT:475,
    OPERATION_GROUP_DELETE:476,
};
