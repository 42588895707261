export enum TableKeyEnum {
  CITY_LIST = 'City List',
  FRANCHISE_LIST = 'Franchise List',
  AREA_LIST = 'Area List',
  COUNTRY_LIST = 'Country Llist',
  STATE_LIST = 'State List',
  AREA_NEW='Area List New',
  ADDRESS_FOR_MAPPING = 'Address Mapping',
  SELECT_VAS = 'SELECT VAS',
  PLAN_TBL_FOR_SUBSCRIBER = 'Plan tbl for subscriber',
  PLAN_TBL_FOR_SUBSCRIBER_VAS = 'Plan tbl for subscriber VAS tbl',
  TRACK_PAYMENT_LIST = 'Track Payment List',
  RADIUS_CLIENT = 'Radius Client',
  POLICIES_LIST = 'Policies List',
  RADIUS_PROFILE_LIST = 'Redius Profile List',
  ACCOUNTING_PROFILE_LIST = 'Accounting Profile List',
  SUBSCRIBER_CDR_LIST = 'Subscriber CDR List',
  CONNECTED_SUBSCRIBER_LIST = 'Connected Subscriber List',
  AUDIT_LIST = 'Audit List',
  TAX_LIST = 'Tax List',
  DISCOUNT_LIST = 'Discount List',
  BILLINGTEMPLATE_LIST = 'Billing Template List',
  SERVICE_LIST = 'Service List',
  CHARGE_LIST = 'Charge List',
  STAFF_LIST = 'Staff List',
  PACKAGES_LIST = 'Packages List',
  QOS_POLICY = 'QOS Policy',
  NETWORK_DEVICE_LIST = 'Network Devices List',
  CLIENT_GROUP = 'Client Group List',
  SERVICE_AREA_STATUS = 'Service Area Status',
  CASE_REASON = 'Case Reason',
  CASE_REASON_CONFIG = 'Case Reason Config',
  CASES = "Case List",
  IP_POOL_CONFIG = 'IP Pool Config',
  NETWORK_SESSION_LIST = 'Network Session List',
  ALL_CASES_OF_CUSTOMER = 'Case Of Customer',
  ACTIVE_PLAN_LIST = 'Active Plan List',
  FUTURE_PLAN_LIST = 'Future Plan List',
  PLAN_HISTORY_LIST = 'Plan History List',
  PAYMENTS_LIST = 'Payments List',
  PURCHASES_LIST = 'Purchase List',
  TROUBLETICKETSDETAILS = 'Troublet Tickets Details',
  SUBSCRIBER_LIST = 'Subscriber List',
  SERVICE_GRID_LIST = 'Service List',
  PRICE_BOOK_LIST = 'Price Book List',
  PAYMENT_GATEWAY = 'payment gateway',
  ACL_LIST = 'Acl Role List',
  CUSTOMER_DOCUMENT_LIST = 'Customer Document List',
  COMMENT_LIST = 'Comment List',
  REMARKTEMPLATE_LIST = 'RemarkTemplate List',
  CHARGES_LIST = 'Charges List',
  OTHER_UPDATES = 'Other Updates',
  OTHER_COMMUNICATION = 'Other Communication',
  COMMISSION_BY_PARTNER = 'Commission Partner',
  PARTNER_PAYMENT_DETAILS = 'Partner payment Details',
  Teams = 'teams',
  ASSOCIATED_PLAN = 'Associated Plan',
  PURCHASE_HISTORY_REQUEST = 'Purchase History Request',
  COMMUNICATION_REPORT = 'Communication',
  PINCODE = 'pincode',
  RECENT_RENEWAL='Recent Renewal',
  CHARGE_REPORT="charge report",
  VALUE_ADDED_SERVICE="value Added Service",
  VALUE_ADDED_SERVICE_LIST='Value added service List',
  ONU_STAT="onu status",
  PARTNER_CONFIGURE="parter configure",
  QOS_POLICY_DETAIL ="qos policy details",
  QOS_POLICY_DETAILLIST="qos policy detaillist",
  PROVISION_STATUS="provision status",
  SMS_PROVIDER="sms provider",
  GROUP_NOTIFICATION="group notification",
  NOTIFICATION="notification",
  NETWORK_STATUS="network status",
  CUSTOMER_LIST="customer list",
  BASE_SETUP_LIST="base setup list",
  PROPERTY_LIST="property list",
  CIRCUIT_LIST='circuit list',
  PAYMENT_REPORT='payment report',
  PARTNER_PAYMENT_GATEWAY ='partner payment gateway',
  PARTNER_CONFIGURATION ='partner configuration',
  NETWORK_SEARCH='network_search',
  BROADCAST_HISTORY = 'broadcast_history',
  CHANGE_EXPIRY_PLAN_REPORT="change plan expiry report",
  DATA_CONSUMPTION_REPORT="data consumption report",
  CUSTOMER_STATUS_REPORT="customer status report",
  CUSTOMER_STATUS_REPORT_STATUS="customer status report status",
  CUSTOMER_STATUS_REPORT_SERVICE_AREA="customer status report Service Area",
  SALES_REPORT="sales_report",
  FIBER_REPORT="fiber_report",
  ACTIVATE_PLAN ='activate plan',
  ACTIVATE_PLAN_FUTURE = 'activate future plan',
  AllOCATION_IP_ADDRESS="allocation ip address",
  NEW_ACTIVATION_REGISTERED="new Activation registered",
  EXPIRED_REPORT='expired report',
  MTTR_REPORT="Mttr Report",
  IP_POOL_CONFIGURATION='ip pool configuration',
  AUDIT_LOG='audit log',
  LEASE_LINE_CONTACT_DETAIS="lease line contact details",
  IP_LIST="ipaddress",
  BULKHISTORY_REPORT_DETAILS = "bulk history report details",
  GEO_LOCATION_STAFF = "geo location staff",
  STAFF_AVAILABE = "staff available",
  NETWORK_DEVICE_TREE__LIST = 'Network Devices Tree List',
  NETWORK_DEVICE_FRAME__LIST="Network Devices Frame List",
  NETWORK_DEVICE_SLOT__LIST="Network Devices Slot List",
  NETWORK_DEVICE_PORT__LIST="Network Devices port List",
  NETWORK_DEVICE_ONU_LIST="Network Devices ONU List",
  DUNNING_RULE="dunning rule",
  DUNNING_RULE_ACTION="dunning rule action",
  IPPOOL_IPDETAILS_LIST="Ippool Detail List",
  LEASELINE_REPORT="Lease Line Report",
  CYBER_REPORT="Cyber Crimer Report",
  VAS_PROVIDER="Vas Provider",
  VAS_PROVIDER_DETAIL="vas provider detail",
  INVENTORY_STOCK_LISt="inventory_stock_list",
  PAYMENT_COLLECTION_REPORT = "payment collection report",
  PAYMENT_COLLECTION_REPORT_DETAIL = "payment colllection detail",
  AADHAR_LOG_REPORT = "aadhar verification log report",
  PARTNER_ACL_ROLE_CONFIGURATION ='partner acl configuration',
  PARTNER_ACL_ROLE_CONFIGURATION_PROFILE ='partner acl configuration',
  INVOICE_REPORT = 'invoice report',
  INVOICE_DETAIL_REPORT = 'invoice detail report',
  ASSOCIATED_VAS_LIST = 'associated vas list',
  IPTV_LIST = 'iptv list',
  PLAN_ACTIVATION = 'Plan Activation',
  PROPERTY_DOCUMENT_LIST='Property Document Uplload',
  PERFORMANCE_REPORT='performance Report',
  CASE_COUNT_REPORT='case count report',
  GROUP_LIST='Group List',
  NAV_FRANCHISE_LIST='Nav Franchise List',
  CONTENT_DETAILS = 'content details list',
  UPDATE_REPORT='update report'

}
